import { httpsCallable } from "@firebase/functions"
import { functions } from "./firebaseConfig"

export async function requestPasswordReset(email, languageCode) {
    let callable = httpsCallable(functions, "resetPassword-request_new_password")
    return callable({ email, languageCode })
}

export async function verifyResetPasswordToken(token) {
    let callable = httpsCallable(functions, "resetPassword-verify_reset_token")
    return callable({ token })
}

export async function resetPassword(token, newPassword) {
    let callable = httpsCallable(functions, "resetPassword-reset_password")
    return callable({ token, newPassword })
}
import { CloseIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoArrowBackOutline } from "react-icons/io5";

function MobileSnippetsSearch({ onTextChange, onClose, onShow }) {
    const [text, setText] = useState('')
    const [lastTextValue, setLastTextValue] = useState('')
    const inputRef = useRef(null)
    const { t } = useTranslation()

    useEffect(() => {
        let trimmedText = text.trim()
        if(trimmedText !== lastTextValue) {
            setLastTextValue(trimmedText)
            console.log('ontextChange ', text)
            onTextChange?.(trimmedText)
        }
    }, [text, lastTextValue, onTextChange])

    useEffect(() => {
        if (onShow) {
            inputRef?.current?.focus()
        }
    }, [onShow])

    const onCloseClick = () => {
        setText('')
        setLastTextValue('')
        onTextChange?.('')
        onClose?.()
    }

    const onClear = () => {
        setText('')
        setLastTextValue('')
        onTextChange?.('')
        inputRef?.current?.focus()
    }

    return (<Flex w='100%' h='100%'>
        <InputGroup size='md' position="absolute" right="4px" top='2px' w='100%'>
            <IconButton left='4px' colorScheme="clear" icon={<IoArrowBackOutline />} onClick={onCloseClick} />
            <Input
                ref={inputRef}
                color='white'
                borderRadius="3xl"
                value={text}
                placeholder={t('sentences.filter_snippets')}
                onChange={(e) => setText(e.target.value)}
            />
            <InputRightElement width='36px'>
                <IconButton
                    h='1.75rem' size='sm'
                    colorScheme="clear"
                    icon={<CloseIcon color='lightGrey' borderRadius='lg' />}
                    onClick={() => { onClear?.() }}
                />
            </InputRightElement>
        </InputGroup>
    </Flex>
    )
}

export default MobileSnippetsSearch;
import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";
import SEO from "../seo/seo";
import Header from "./header";
import Footer from "./footer";
import { useRecoilState } from "recoil";
import { isPageValidState } from "../state/app-state";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isAuthenticationFinishedState } from "../state/user-state";
import RetinaImage from "./retina-image";

function PageNotFound() {
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);
    const [isAuthenticationFinished, setAuthenticationFinished] = useRecoilState(
        isAuthenticationFinishedState
    );
    const { t, ready } = useTranslation();

    useEffect(() => {
        setIsPageValid(ready && isAuthenticationFinished);
    }, [ready, isAuthenticationFinished]);

    return (
        <Box
            data-test="sentences-page"
            h="100vh"
            flexDirection="column"
            style={{
                backgroundImage: "url('/icons/bg.png')",
                backgroundSize: "cover",
                backgroundRepeat: "repeat-y",
                backgroundPosition: "center center",
            }}
        >
            <SEO pageKey="page_not_found" />

            {/* Header */}
            <Header />
            <Box data-test='main-box' h="calc(100% - 128px)">

                {isPageValid && <Center h="100%" w="100%">
                    <Flex
                        as="main"
                        direction="column"
                        align="center" // Centers items horizontally
                        justify="center" // Centers items vertically
                        w="100%"
                        h="100%"
                        marginTop={-10}
                    >
                        <RetinaImage src="/icons/404-error.png"/>
                        <Heading color="white">{t('page_not_found.title')}</Heading>
                        <Text fontSize='lg' color="gray.200">{t('page_not_found.description')}</Text>             
                    </Flex>
                </Center>
                }
            </Box>
            <Footer />
        </Box>
    );
}

export default PageNotFound;
import { Box, Flex, Heading, Text, Image, useMediaQuery } from "@chakra-ui/react";
import { FaApple } from "react-icons/fa";
import CustomButton from "./common/LandingButton";
import { Constants } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import RetinaImage from "../retina-image";

const LandingReminders = ({ imageSuffix }) => {
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const [suffix, setSuffix] = useState(imageSuffix);
    const { t } = useTranslation();

    useEffect(() => {
        setSuffix(imageSuffix);
    }, [imageSuffix]);

    function renderImage() {
        return <RetinaImage
            mt={isMobile ? 8 : 0}
            src={`/icons/landing/reminders_no_glow.png`}
            alt="Reminders Image"
            width="100%"
            height="auto"
            zIndex="10"
            position="relative"
        />
    }

    return (
        <Box textAlign="left" color="white" pt={8}>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                gap={{ base: 4, md: "5%" }}
                mt="8"
                px={{ base: 4, md: 8 }}
                position="relative"
            >
                {/* Image box with glow overlay */}
                <Box flex={{ base: "1", md: "45%" }} position="relative" pointerEvents="none">
                    {/* Main reminders image */}

                    { !isMobile && renderImage() }

                    {/* Glow image overlay */}
                    <Box
                        position="absolute"
                        top="30%" // Centers glow image over main image
                        left="70%"
                        transform="translate(-50%, -50%)"
                        width="110%" // Scales the glow relative to the main image width
                        height="auto"
                        zIndex="8"
                        overflow="visible"
                    >
                        <RetinaImage src="/icons/landing/reminders_glow.png" alt="Glow effect" />
                    </Box>
                </Box>

                {/* Text and Button Content */}
                <Box flex={{ base: "1", md: "40%" }}>
                    <Text
                        fontSize={Constants.Landing.SECTION_TITLE_HEADER_SIZE}
                        color="#7F5BD4"
                        mb={Constants.Landing.SECTION_TITLE_TOP_MARGIN}
                    >
                        {t('landing.reminders_header')}
                    </Text>
                    <Heading
                        fontSize={Constants.Landing.SECTION_TITLE_SIZE}
                        mb={Constants.Landing.SECTION_TITLE_BOTTOM_MARGIN}
                    >
                        {t('landing.reminders_title')}
                    </Heading>
                    <Text maxW="600px" mx="auto" mb={1}>
                        {t('landing.reminders_description')}
                    </Text>
                    <Text maxW="600px" mx="auto" mb="4">
                        {t('landing.available_ios_android')}
                    </Text>

                
                    <CustomButton
                        icon={() => <FaApple size={30} />}
                        topText={t("landing.download_on_app_store")}
                        mainText="App Store"
                        bgColor="#7F5BD4"
                        iconSize="40px"
                        mx='1'
                    />
                    <CustomButton
                        icon={(props) => <Image src="/icons/landing/android.png" width="30px" />}
                        topText={t("landing.download_on_google_play")}
                        mainText="Google Play"
                        bgColor="#7F5BD4"
                        iconSize="40px"
                        mx='1'
                    />

{ isMobile && renderImage() }
                </Box>
            </Flex>

            
        </Box>
    );
};

export default LandingReminders;

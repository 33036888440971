// src/App.js
import React, { useEffect, useState } from "react";
import { Box, Center, ChakraProvider, Container, Divider, Image, theme, useMediaQuery } from "@chakra-ui/react";
import LandingNavBar from "./LandingNavBar";
import LandingHeader from "./LandingHeader";
import LandingFeatures from "./LandingFeatures";
import LandingSnippets from "./LandingSnippets";
import LandingReminders from "./LandingReminders";
import LandingClassMaterials from "./LandingClassMaterials";
import LandingFooter from "./LandingFooter";
import { useRecoilState } from "recoil";
import { userLanguageState } from "../../state/user-state";
import { imageSuffixState } from "./landing-state";
import { isDefined } from "../../utils/utils";
import { Constants } from "../../utils/constants";
import Footer from "../footer";
import SEO from "../../seo/seo";

const landingBackgroundColor = "#2B1757";

function LandingPage() {
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);

    return (
        <>
            <SEO pageKey='landing' title='Learner.app' />
            {isMobile ? <MobileLandingPage /> : <DesktopLandingPage />}
        </>
    );
}

function MobileLandingPage() {
    const [imageSuffix, setImageSuffix] = useRecoilState(imageSuffixState);
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const [containerWidth, setContainerWidth] = useState('100%');

    useEffect(() => {
        if (isMobile) {
            setContainerWidth('90%');
        } else {
            setContainerWidth('100%');
        }
    }, [isMobile]);

    useEffect(() => {
        if (!userLanguage?.code) {
            setImageSuffix(null)
        } else if (userLanguage.code === 'en') {
            setImageSuffix('')
        } else {
            setImageSuffix(`_${userLanguage.code}`)
        }
    }, [userLanguage]);

    return (
        <Box bg={landingBackgroundColor} h='100%' w='100%'>
            <LandingNavBar />
            {userLanguage && isDefined(imageSuffix) && <Box>

                <Box bg={landingBackgroundColor}>
                    <Container maxW={{ base: containerWidth, md: 'container.lg' }} p={4}>
                        <LandingHeader />
                    </Container>
                </Box>

                <Box bg={landingBackgroundColor} bgAttachment="fixed" bgImage="url(/icons/landing/background_layer.png)">
                    <Container maxW={{ base: containerWidth, md: 'container.lg' }} p={4}>
                        <LandingFeatures />
                    </Container>
                </Box>

                <Box bg={landingBackgroundColor}>
                    <Container maxW={{ base: containerWidth, md: 'container.lg' }} p={4} >
                        <LandingSnippets imageSuffix={imageSuffix} />
                        <LandingReminders imageSuffix={imageSuffix} />
                        <LandingClassMaterials imageSuffix={imageSuffix} />
                        <LandingFooter imageSuffix={imageSuffix} />
                        <Image style={{ userSelect: "none" }} src={"/icons/landing/logo.png"} alt="Learner App Logo" height='36px' mt="20px" />
                    </Container>

                </Box>
                <Footer />
            </Box>}
            
        </Box>
    );
}

function DesktopLandingPage() {
    const [imageSuffix, setImageSuffix] = useRecoilState(imageSuffixState);
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);

    useEffect(() => {
        if (!userLanguage?.code) {
            setImageSuffix(null)
        } else if (userLanguage.code === 'en') {
            setImageSuffix('')
        } else {
            setImageSuffix(`_${userLanguage.code}`)
        }
    }, [userLanguage]);

    return (
        <Box bg={landingBackgroundColor} h='100%' w='100%'>
            <LandingNavBar />
            {userLanguage && isDefined(imageSuffix) && <Box bg={landingBackgroundColor} zIndex={5}>

                <Box bg={landingBackgroundColor} >
                    <Container maxW={{ base: '100%', md: 'container.lg' }} p={4}>
                        <LandingHeader />
                    </Container>
                </Box>

                <Box bg={landingBackgroundColor} bgAttachment="fixed" bgImage="url(/icons/landing/background_layer.png)">
                    <Container maxW={{ base: '100%', md: 'container.lg' }} p={4}>
                        <LandingFeatures />
                    </Container>
                </Box>

                <Box bg={landingBackgroundColor}>
                    <Container maxW={{ base: '100%', md: 'container.lg' }} p={4} >
                        <LandingSnippets imageSuffix={imageSuffix} />
                        <LandingReminders imageSuffix={imageSuffix} />
                        <LandingClassMaterials imageSuffix={imageSuffix} />
                        <LandingFooter imageSuffix={imageSuffix} />
                    </Container>

                </Box>
                <Box h={4} bg={landingBackgroundColor}></Box>
                <Box height={2} bg={landingBackgroundColor}>
                    <Center>
                        <Divider opacity='0.1' />
                    </Center>
                </Box>

                <Footer backgroundColor={landingBackgroundColor} />
            </Box>}

        </Box>
    );
}

export default LandingPage;

// src/components/Header.js
import { Box, Heading, Text, Image, Grid, GridItem, Flex, Container, useMediaQuery } from "@chakra-ui/react";
import RetinaImage from '../retina-image';
import { useTranslation } from "react-i18next";
import { Constants } from "../../utils/constants";
import { useEffect, useState } from "react";

const LandingHeader = () => {
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const [textAlign, setTextAlign] = useState('left');
    const { t } = useTranslation()

    useEffect(() => {
        setTextAlign(isMobile ? 'center' : 'left')
    }, [isMobile])

    function renderTextBox() {
        return <Box flex={{ base: "1", md: "40%" }} textAlign="left">
            <Heading fontSize="4xl" mb='4' textAlign={textAlign}>{t("landing.section_main_title")}</Heading>
            <Text fontSize="lg" textAlign={textAlign}>
                {t("landing.section_main_description")}
            </Text>
            <Text mt={2} fontSize="lg" textAlign={textAlign}>
                {t("landing.section_main_subtitle")}
            </Text>
        </Box>
    }

    return (
        <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="center"
            gap={{ base: 4, md: "5%" }}
            px={{ base: 4, md: 8 }}
            position="relative"
            color='white'
            pb={8}
        >
            {/* Text box */}
            { !isMobile && renderTextBox() }

            {/* Image box with glow overlay */}
            <Box flex={{ base: "1", md: "45%" }} position="relative" pointerEvents="none">
                {/* Main image */}
                <RetinaImage
                    src="/icons/landing/section_1_image.png"
                    alt="Learner App Logo"
                    width="100%"
                    height="auto"
                    zIndex="10"
                    position="relative"
                />

                {/* Glow image overlay */}
                <Box
                    position="absolute"
                    top="60%" // Starts positioning from 60% height of parent box
                    left="50%" // Centers horizontally in parent
                    transform="translate(-50%, -50%)" // Center align using transform
                    width={isMobile ? "130%" : "164%" } // 164% of the parent image box width
                    height={isMobile ? "130%" : "165%" } // of the parent image box height
                    zIndex="5" // Adjusts to ensure it's in the correct layer
                    overflow="hidden"
                >
                    <RetinaImage src="/icons/landing/section_1_glow.png" alt="Glow effect" />
                </Box>
            </Box>

            { isMobile && renderTextBox() }
        </Flex>
    );
};



export default LandingHeader;

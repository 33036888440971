import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  useToast,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SnippetTable from "../snippets/snippet-table";
import { handleEnterKeyPress } from "../utils/helper";
import { useRecoilState } from "recoil";
import {
  isAuthenticationFinishedState,
  learningLanguageState,
  userLanguageState,
} from "../state/user-state";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/utils";
import { isLanguageDictionarySupported } from "../utils/languages";
import { classroomIDState, classroomRoleState } from "../state/classroom-state";
import { ClassroomRoleEnum } from "./classrooms/classroom-role";
import { Constants } from "../utils/constants";
import { dictionarySearch } from "../api/translation.service";
import { AddSnippetModalType } from "../dialogs/add-snippet-modal";
import {
  showAddEditSnippetModal,
  showSnippetsModalState,
} from "../state/snippets-state";
import {
  logEvent_DictionarySearch_Error,
  logEvent_DictionarySearch_Success,
} from "../analytics/analytics";
import { convertDictionarySearchResultToSnippetModels } from "../utils/snippet-utils";
import DictionarySearchResults from "./dictionary-search-result";

function DictionarySearch({ onDataDisplayed, isFullHeight }) {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
  const [classroomID, setClassroomID] = useRecoilState(classroomIDState);
  const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);
  const [newWord, setNewWord] = useState("");
  const [searchBarHeight, setSearchBarHeight] = useState(0);
  const [modelHeaderHeight, setModelHeaderHeight] = useState(0);
  const [isDictionarySupported, setIsDictionarySupported] = useState(false);
  const [searchTermModel, setSearchTermModel] = useState(false); // raw server response
  const [resultSnippets, setResultSnippets] = useState(null);
  const [isFetchingSnippets, setFetchingSnippets] = useState(false);
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState
  );
  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  const [translateWordHeight, setTranslateWordHeight] = useState(0);

  const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
    showSnippetsModalState
  );
  const [authenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState
  );

  const searchBarRef = useRef(null);
  const modelHeaderRef = useRef(null);
  const searchTextareaRef = useRef(null);

  const toast = useToast();

  const { t } = useTranslation();

  useEffect(() => {
    if (!authenticationFinished || !learningLanguage?.code) {
      return;
    }

    // reload dictionary support
    setIsDictionarySupported(
      isLanguageDictionarySupported(learningLanguage.code)
    );
  }, [learningLanguage, authenticationFinished]);

  useLayoutEffect(() => {
    if (searchBarRef.current) {
      setSearchBarHeight(searchBarRef.current.offsetHeight);
    }

    if (modelHeaderRef.current) {
      setModelHeaderHeight(modelHeaderRef.current.offsetHeight);
    }

    const handleResize = () => {
      if (searchBarRef.current) {
        setSearchBarHeight(searchBarRef.current.offsetHeight);
      }

      if (modelHeaderRef.current) {
        setModelHeaderHeight(modelHeaderRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    onDataDisplayed?.();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [searchTermModel]); // searchTermModel added as a dependency to trigger the effect when it changes

  const focusSearchTextArea = () => {
    if (searchTextareaRef.current) {
      searchTextareaRef.current.focus();
    }
  };

  async function onSearchTerm() {
    doSearch(newWord);
  }

  async function doSearch(newWord) {
    setFetchingSnippets(true);

    const startTime = Date.now();
    try {
      // MOCK DATA LOADING
      // load search result from file in public folder called mock_dictionary_search_result.json
      // let searchResultPlainText = await fetch("/mock_data/mock_dictionary_search_result_trabajar.json")
      // let searchResult = await searchResultPlainText.json()
      let searchResult = await dictionarySearch(
        newWord,
        learningLanguage.code,
        userLanguage.code
      );
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      logEvent_DictionarySearch_Success(
        newWord,
        learningLanguage.code,
        userLanguage.code,
        elapsedTime
      );

      let s = await convertDictionarySearchResultToSnippetModels(searchResult, userLanguage.code, learningLanguage.code, true)
      console.log('DictionarySearch results: ', s)
      setResultSnippets(s);

      setSearchTermModel(searchResult);

    } catch (error) {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      console.log("ERROR")
      console.log(error);
      logEvent_DictionarySearch_Error(
        error,
        newWord,
        learningLanguage.code,
        userLanguage.code,
        elapsedTime
      );
      toast({
        description: error.message,
        position: "top",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setFetchingSnippets(false);
      focusSearchTextArea();
    }
  }

  useEffect(() => {
    const translateWord = document.getElementById("translate-word-height-id");
    const translateWordHeight = translateWord ? translateWord.offsetHeight : 0;

    setTranslateWordHeight(translateWordHeight);
    console.log("dobi", translateWordHeight);
  }, [searchTermModel]);

  async function onClickInflectionEntry(inflectionEntry) {
    console.log('onClickInflectionEntry', inflectionEntry)
    let word = inflectionEntry.verb || inflectionEntry.noun
    if(word) {
      setNewWord(word)
      await doSearch(word)
    }
  }

  return (
    <Box
      data-test="dictionary-search"
      w="100%"
      h={
        isFullHeight ? "100%" : `calc(100vh - ${translateWordHeight}px - 150px)`
      }
    >
      {learningLanguage && (
        <Box
          w="100%"
          h="100%"
          overflow="hidden"
          borderWidth={isMobile ? "0px" : "1px"}
          borderRadius={4}
        >
          {/* Header with Input and Search Button */}

          {!isMobile && (
            <Box
              mb={1}
              padding={2}
              borderBottomWidth="1px"
              h="45px"
              backgroundColor="darkPurple"
            >
              <Text ml={3} fontSize="lg" color="white">
                {t("translate.dictionary_search")}
              </Text>
            </Box>
          )}

          <Box ref={searchBarRef}>
            <HStack
              position="sticky"
              top={0}
              zIndex={10}
              pl={2}
              pr={2}
              pb={2}
              pt={0}
            >
              <Input
                data-test="dictionary-search-input"
                ref={searchTextareaRef}
                onKeyDown={(e) => {
                  handleEnterKeyPress(e, onSearchTerm);
                }}
                color="white"
                placeholder={t("sentences.dictionary_search_placeholder")}
                disabled={isFetchingSnippets || !isDictionarySupported}
                value={newWord}
                onChange={(e) => setNewWord(e.target.value)}
              />
              <Button
                colorScheme="blue"
                isDisabled={
                  !isDictionarySupported || (newWord?.trim()?.length ?? 0) === 0
                }
                data-test="dictionary-search-button"
                onClick={onSearchTerm}
                isLoading={isFetchingSnippets}
              >
                {t("sentences.search")}
              </Button>
            </HStack>
          </Box>

          {!isDictionarySupported && (
            <Box
              p={4}
              m={4}
              borderRadius="md"
              textAlign="center"
              backgroundColor="#FFFFFF10"
              h
              color="white"
              data-test="dictionary-search-coming-soon"
            >
              {t("sentences.dictionary_search_coming_soon", {
                lang: capitalize(
                  t(`languages.${learningLanguage.name.toLowerCase()}`)
                ),
              })}
            </Box>
          )}

          {/* Scrollable Table */}
          <Box
            overflowY="auto"
            height={`calc(100% - ${searchBarHeight}px - ${modelHeaderHeight}px - -28px - ${isMobile ? 0 : 85
              }px)`}
          >

            <DictionarySearchResults results={resultSnippets} classroomID={classroomID} showsCreateButton={true} onLinkClick={onClickInflectionEntry} canPinSnippet={false}/>

          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DictionarySearch;

import React, { useEffect, useState } from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.error('Error caught in ErrorBoundary:', error, errorInfo);
      // Log error to a backend or monitoring service
    }
  
    render() {
      if (this.state.hasError) {
        // Custom fallback UI
        // return <h1>Something went wrong. Please try again later.</h1>;
        return <></>
      }
  
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;
  
import { useState, useEffect } from "react";
import {
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Text,
  Box,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Constants } from "../utils/constants";

const Tutorial = ({ steps = [], onComplete, onCompleteStep }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [visibleSteps, setVisibleSteps] = useState([]);
  const [completedSteps, setCompletedSteps] = useState({});
  const [isHidden, setIsHidden] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (steps.length === 0 || isHidden) {
      return;
    }
    setVisibleSteps(steps);

    const firstIncompleteStepIndex = steps?.findIndex(
      (step, index) => !step.completed && !completedSteps[index]
    );

    if (firstIncompleteStepIndex !== -1) {
      setCurrentStepIndex(firstIncompleteStepIndex);
    } else {
      setCurrentStepIndex(null);
      onComplete?.();
    }
  }, [steps, onComplete]);

  const handleDone = () => {
    setIsHidden(true);
    onComplete?.();
    setCurrentStepIndex(null);
  }

  const handleSkip = () => {
    setVisibleSteps((prevSteps) =>
      prevSteps.map((step) => ({ ...step, completed: true }))
    );
    setIsHidden(true);
    onComplete?.();
    setCurrentStepIndex(null);
  };

  const handleNextStep = () => {
    setVisibleSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps[currentStepIndex].completed = true;
      return updatedSteps;
    });

    const nextStepIndex = visibleSteps.findIndex(
      (step, index) => !step.completed && index > currentStepIndex
    );
    if (nextStepIndex !== -1) {
      onCompleteStep?.(currentStepIndex);
      setCurrentStepIndex(nextStepIndex);
      completedSteps[currentStepIndex] = true;
    } else {
      setCurrentStepIndex(null);
      onComplete?.();
    }
  };

  if (currentStepIndex === null || !visibleSteps[currentStepIndex] || isHidden) {
    return null;
  }

  const currentStep = visibleSteps[currentStepIndex];
  let targetElement = null;

  if (currentStep.selectElement) {
    targetElement = currentStep.selectElement(currentStep);
  } else {
    targetElement = document.getElementById(currentStep.id);
  }

  if (!targetElement) {
    console.warn(`Target element with id "${currentStep.id}" not found.`);
    return null;
  }

  const showArrow =
    currentStep.hideArrow === undefined || currentStep.hideArrow === null
      ? true
      : !currentStep.hideArrow;

  const offsetTop = currentStep.offset?.top || 0
  const offsetLeft = currentStep.offset?.left || 0
  const adjustedTop = targetElement.offsetTop + offsetTop
  const adjustedLeft = targetElement.offsetLeft + offsetLeft

  return (
    <Popover
      isOpen
      placement={currentStep.position || "top"}
      closeOnBlur={false}
      closeOnEsc={false}
      modifiers={[
        {
          name: "zIndex",
          enabled: true,
          phase: "write",
          fn: ({ state }) => {
            state.elements.popper.style.zIndex = Constants.zIndex.popover
          },
        },
      ]}
    >
      <PopoverTrigger >
        <Box
          position="absolute"
          top={`${adjustedTop}px`}
          left={`${adjustedLeft}px`}
          width={`${targetElement.offsetWidth}px`}
          height={`${targetElement.offsetHeight}px`}
          opacity="0"
          pointerEvents="none"
        />
      </PopoverTrigger>

      <PopoverContent boxShadow="lg" borderRadius="md" borderWidth={0.5} borderColor="gray.300">
        {showArrow && <PopoverArrow />}

        <PopoverBody
          borderRadius={6}
          style={{
            backgroundImage: "url('/icons/bg.png')",
            backgroundSize: "cover",
            backgroundRepeat: "repeat-y",
            backgroundPosition: "center center",
          }}
        >
          <Text color="gray.200">{currentStep.message}</Text>
          <Box mt={4} display="flex" justifyContent="space-between">
            {currentStep.hasNextButton && !currentStep.showCompleteButton && (
              <Button colorScheme="green" size="sm" onClick={handleNextStep}>
                {t("tutorial.next")}
              </Button>
            )}

            {currentStep.showCompleteButton && (
              <Button colorScheme="green" size="sm" onClick={handleDone}>
                {t("modal.done")}
              </Button>
            )}
            
            {!currentStep.showCompleteButton &&<Button colorScheme="red" size="sm" onClick={handleSkip}>
              {t("tutorial.skip")}
            </Button> }

          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export function setTutorialCompleted(key) {
  localStorage.setItem(key, "true");
}

export function isTutorialCompleted(key) {
  return localStorage.getItem(key) === "true";
}

export default Tutorial;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { AddSnippetModalType } from "../dialogs/add-snippet-modal";
import SnippetModalContentCreate from "./snippet-modal-content-create";
import { hideAddEditSnippetModal, setUserSnippetsNeedUpdate, showSnippetsModalState, snippetsNeedUpdateState } from "../state/snippets-state";
import { convertSnippetToStorySnippet, generateUserSnippetsObject } from "../utils/snippet-utils";
import { learningLanguageState, userState } from "../state/user-state";
import { addEditSnippet, fetchCachedSnippets, prepareSnippetForSaving } from "../api/sentences.service";
import { classroomIDState } from "../state/classroom-state";
import { addEditClassroomSnippet } from "../api/classroom.service";
import { manageStoriesListStoriesState, manageStoriesReviewSnippetModalSelectedMappingState, manageStoriesStoriesToUpdateState } from "../admin/manage-stories/manage-stories-state";
import { copyJSONObject, isDefined, random8ID, randomID } from "../utils/utils";
import { findStoryMapping } from "../admin/story-utils";
import { exampleClassroomState } from "../components/classrooms/example-classroom-state";

export const AddEditSnippetMode = {
  PLAIN_TEXT: "PLAIN_TEXT",
  FROM_DICTIONARY: "FROM_DICTIONARY",
};

export const AddToArrayIndexStrategy = {
  BEGINNING: function (element, arr) {
    return [element, ...arr];
  },
  END: function (element, arr) {
    return [...arr, element];
  },
  REPLACE: function (index) {
    return function (element, arr) {
      let copy = [...arr];
      copy[index] = element;
      return copy;  
    }
  }
}

export const AddEditSnippetModalType = {
  EXAMPLE_CLASSROOM: function (languageCode) {
    return {
      type: "EXAMPLE_CLASSROOM",
      languageCode: languageCode,
    };
  },
  CLASSROOM_FALLBACK_TO_USER: function (classroomID) {
    if (classroomID) {
      return AddEditSnippetModalType.CLASSROOM(classroomID)
    } else {
      return AddEditSnippetModalType.USER_SNIPPET()
    }
  },
  CLASSROOM: function (classroomID) {
    return {
      type: "CLASSROOM",
      classroomID: classroomID,
    };
  },
  USER_SNIPPET: function () {
    return {
      type: "USER_SNIPPET",
    };
  },
  ADD_TO_MAPPING: function (indexStrategy) {
    let type = {
      type: "ADD_TO_MAPPING",
      indexStrategy: indexStrategy,
    };

    return type;
  }

}

function SnippetModal({
  isOpen,
  onClose,
  loading,
  cellModel,
  type,
  languageCode,
  userLanguageCode,
  snippetKey,
  customTitle,
}) {
  const [editedModel, setEditedModel] = useState(cellModel);
  const [modalDialogState, setModalDialogState] = useRecoilState(
    showSnippetsModalState
  );
  const [editModel, setEditModel] = useState(null);

  const [user, setUser] = useRecoilState(userState)

  const [classroomID, setClassroomID] = useState(null)

  const [reviewStories, setReviewStories] = useRecoilState(manageStoriesListStoriesState)
  const [selectedStoryMapping, setSelectedStoryMapping] = useRecoilState(manageStoriesReviewSnippetModalSelectedMappingState)
  const [reviewStoriesToUpdate, setReviewStoriesToUpdate] = useRecoilState(manageStoriesStoriesToUpdateState)

  const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)
  const [editingSnippetID, setEditingSnippetID] = useState(snippetKey);
  const [isLoading, setLoading] = useState(loading);

  const [userSnippetsNeedUpdate, userSnippetsUpdateSetter] = useRecoilState(snippetsNeedUpdateState)
  const [modalTitleHeight, setModalTitleHeight] = useState(0);

  const [modalFooterHeight, setModalFooterHeight] = useState(0);
  const [snippetModels, setSnippetModels] = useState(modalDialogState?.models);

  const [addSnippetType, setAddSnippetType] = useState(type);

  const [addSnippetMode, setAddSnippetMode] = useState(
    AddEditSnippetMode.FROM_DICTIONARY
  );

  const [exampleClassroomData, setExampleClassroomData] = useRecoilState(exampleClassroomState);

  const modalTitleRef = useRef(null);
  const modalFooterRef = useRef(null);

  const { t } = useTranslation();

  const toast = useToast();

  const [maxModalHeight, setMaxModalHeight] = useState("auto");

  useEffect(() => {
    const externalFooter = document.getElementById("external-footer"); // Referencing the footer
    const externalFooterHeight = externalFooter
      ? externalFooter.offsetHeight
      : 0;

    setMaxModalHeight(`calc(100vh - 32px)`);
  }, [loading, cellModel]);

  useEffect(() => {
    console.log('Model state changed ' + JSON.stringify(modalDialogState));

    let editModel = {
      editSnippetID: modalDialogState?.editSnippetID,
      pinned_at: modalDialogState?.pinned_at,
      models: modalDialogState?.models,
      searchText: modalDialogState?.searchTerm,
      plainTextTerm: modalDialogState?.plainTextTerm,
      plainTextTranslation: modalDialogState?.plainTextTranslation,
      selectedModelID: modalDialogState?.selectedModelID,
      definitionsLanguageCode: modalDialogState?.definitionsLanguageCode,
    };

    if (editModel?.plainTextTerm || editModel?.plainTextTranslation) {
      setAddSnippetMode(AddEditSnippetMode.PLAIN_TEXT);
    } else {
      setAddSnippetMode(AddEditSnippetMode.FROM_DICTIONARY);
    }

    setClassroomID(modalDialogState?.classroomID)

    setEditModel(editModel);

    console.log('Setting edit model to ' + JSON.stringify(editModel));
  }, [modalDialogState, addSnippetType, type]);

  useEffect(() => {
    setAddSnippetType(type);
  }, [type]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    setEditingSnippetID(snippetKey);
  }, [snippetKey]);

  useEffect(() => {
    if (modalFooterRef.current) {
      const height = modalFooterRef.current.offsetHeight;
      setModalFooterHeight(height);
    }
    if (modalTitleRef.current) {
      const height = modalTitleRef.current.offsetHeight;
      setModalTitleHeight(height);
    }

    console.log('Snippet model changed ' + JSON.stringify(snippetModels));
  }, [snippetModels]);

  async function onClickCreate() {
    if (!addSnippetType?.type) {
      console.error('Add snippet type is not set ' + JSON.stringify(addSnippetType));
      return;
    }

    let saveObject = generateUserSnippetsObject(editedModel, languageCode)

    try {
      let promise = null
      switch (addSnippetType.type) {
        case 'ADD_TO_MAPPING':
          if(!selectedStoryMapping) {
            console.error('Selected story mapping is not set');
            return;
          }

          const story = selectedStoryMapping.story
          let indexStrategy = addSnippetType.indexStrategy ?? AddToArrayIndexStrategy.BEGINNING

          if (!story) {
            console.error(`Array or index strategy is not set:\nIndex Strategy: ${JSON.stringify(indexStrategy)}\nStory: ${JSON.stringify(story, null, 2)}`);
            return;
          }

          let storySnippet = convertSnippetToStorySnippet(saveObject)

          if (!storySnippet) {
            console.error('Story snippet is null');
            return;
          }

          // update story data
          let reviewStory = copyJSONObject(reviewStories.find(s => s.id === story.id))
          if(!reviewStory) {
            console.error(`Review story is not found:\nStoryID: ${story.id}\nStories: ${JSON.stringify(reviewStories, null, 2)}`);
            return;
          }

          let reviewStoryMapping = findStoryMapping(reviewStory, selectedStoryMapping.mapping.id)
          if(!reviewStoryMapping) {
            console.error(`Review story mapping is not found:\nMappingID: ${selectedStoryMapping.mapping.id}\nStories: ${JSON.stringify(reviewStories, null, 2)}\nReview Story: ${JSON.stringify(reviewStory, null, 2)}`);
            return;
          }
          // add/replace snippet
          let array = reviewStoryMapping.snippets ?? []
          reviewStoryMapping.snippets = indexStrategy(storySnippet, array)

          // select definition
          let selectedDefinitionID = saveObject.selectedDefinition?.id
          let selectedDefinitionLanguageCode = saveObject.selectedDefinition?.languageCode

          if (selectedDefinitionID && selectedDefinitionLanguageCode && storySnippet.id) {
            let selectedDefinitions = reviewStory.selected_definitions ?? {}
            let languageDefinitions = selectedDefinitions[selectedDefinitionLanguageCode] ?? {}
            languageDefinitions[storySnippet.id] = selectedDefinitionID
            selectedDefinitions[selectedDefinitionLanguageCode] = languageDefinitions
            reviewStory.selected_definitions = selectedDefinitions
          }

          let newStories = reviewStories.map(s => {
            if (s.id === reviewStory.id) {
              return reviewStory
            }
            return s
          })
          setReviewStories(newStories)
          setReviewStoriesToUpdate((prev) => {
            return {...prev, [reviewStory.id]: true }
          })

          promise = Promise.resolve()

          break;
        case 'CLASSROOM':
          console.log('Creating classroom snippet');
          let classroomID = addSnippetType.classroomID;
          if (!classroomID) {
            console.error('Classroom ID is not set ' + JSON.stringify(addSnippetType));
            return;
          }

          let snippetID = saveObject.id
          let preparedSnippet = prepareSnippetForSaving(saveObject)
          promise = addEditClassroomSnippet(classroomID, preparedSnippet, snippetID)
          break;
        case 'USER_SNIPPET':
          console.log('Creating user snippet');
          promise = addEditSnippet(saveObject.id, saveObject, user?.uid, learningLanguage.code)
          break;
        case 'EXAMPLE_CLASSROOM':
          const editID = editingSnippetID
          let data = copyJSONObject(exampleClassroomData)
          if(data?.classroomData?.classroomSnippets) {
            let classroomSnippets = data.classroomData.classroomSnippets
            let snippetCopy = copyJSONObject(saveObject)
            
            let id = randomID()
            snippetCopy.id = id
            snippetCopy.key = id
            // snippetCopy.userSnippet = snippetCopy.dictionaryEntry
            // delete snippetCopy.dictionaryEntry
            let arr = await fetchCachedSnippets({ [id]: saveObject}, userLanguageCode, learningLanguage.code)
            if(classroomSnippets) {
              if(editID) {
                // replace
                let index = classroomSnippets.findIndex(s => s.id === editID)
                if(index >= 0 && arr.length > 0) {
                  let addedSnippet = arr[0]
                  addedSnippet.id = editID
                  arr = AddToArrayIndexStrategy.REPLACE(index)(addedSnippet, classroomSnippets)
                } else {
                  console.error('Snippet not found in classroom snippets ' + editID);
                }
              } else {
                // concat
                arr = arr.concat(classroomSnippets)
              }
              
            }
            // classroomSnippets[id] = snippetCopy
            data.classroomData.classroomSnippets = arr
            
            console.log('Setting example classroom data ' + JSON.stringify(classroomSnippets));
            setExampleClassroomData(data)
          }
          
          promise = Promise.resolve()
          break;
        default:
          console.error('Unknown add snippet type ' + addSnippetType.type);
          return
      }

      if (promise) {
        setLoading(true)
        await promise
        setUserSnippetsNeedUpdate(userSnippetsUpdateSetter)
        hideAddEditSnippetModal(setModalDialogState)
      }

    } catch (error) {
      console.log('Error saving snippet ' + error);
      toast({
        title: error.message,
        status: "error",
        duration: 3000,
      })
    } finally {
      setLoading(false);
    }
  }

  function createModelDidChange(model) {
    console.log("Create model did change " + JSON.stringify(model));
    setEditedModel(model);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      blockScrollOnMount={false}
      data-test='snippet-modal'
    >
      <ModalOverlay />
      <ModalContent
        margin="16px"
        bgImage="url('/icons/snippet-modal-background.png')"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center"
        overflowY="auto"
        height={
          snippetModels?.items?.length > 0 &&
            addSnippetMode === AddEditSnippetMode.FROM_DICTIONARY
            ? "calc(100vh - 32px)"
            : null
        }
        maxHeight={maxModalHeight}
        data-test='snippet-modal-content'
      >
        <ModalHeader color="white" ref={modalTitleRef} data-test='snippet-modal-header'>
          {customTitle ?? t("sentences.snippet_preview")}
        </ModalHeader>
        <ModalCloseButton color="closeGrey" data-test='modal-close-button' />
        <ModalBody padding={0} overflow="hidden" data-test='modal-body'>
          <Box pt={0} pb={4} overflowY="auto">
            <SnippetModalContentCreate
              maxModalHeight={maxModalHeight}
              modalFooterHeight={modalFooterHeight}
              modalTitleHeight={modalTitleHeight}
              editModel={editModel}
              setSearchModel={setSnippetModels}
              editTranslation={modalDialogState?.editTranslation}
              onModelChanged={(model) => {
                createModelDidChange(model);
              }}
              onEnterKeyPress={onClickCreate}
              addSnippetMode={addSnippetMode}
              setAddSnippetMode={setAddSnippetMode}
            />
          </Box>
        </ModalBody>
        <ModalFooter ref={modalFooterRef} data-test='snippet-modal-footer'>
          <Button disabled={isLoading} variant="solid" mr={4} onClick={onClose} data-test='cancel-button'>
            {t("modal.cancel")}
          </Button>
          <Button
            data-test='create-button'
            isLoading={isLoading}
            isDisabled={!editedModel}
            colorScheme="blue"
            onClick={() => {
              onClickCreate();
            }}
          >
            {editingSnippetID ? t("modal.edit") : t("modal.create")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SnippetModal;

import { Box, Button, Flex, Heading, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { validateEmail } from "../utils/utils";
import { t } from "i18next";
import { Constants } from "../utils/constants";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../api/firebaseConfig";
import Login from "./login";
import RegisterContent from "./register-content";
import ForgotPassword from "./forgot-password";

function LoginRegisterPopover({ triggerRef }) {
    const [mode, setMode] = useState('register');
    const [updateRef, setUpdateRef] = useState(1); // This is a hack to force the popover to update when the mode changes
    
    useEffect(() => {
        setTimeout(() => { setUpdateRef(updateRef + 1)}, 500)
    }, [mode])

    return (
        <Popover zIndex={Constants.zIndex.popover} onOpen={() => { 
            setMode('login')
            setUpdateRef(updateRef + 1)
        }} onClose={() => { setTimeout(() => { setMode('login')}, 500); }}>
            <PopoverTrigger>
                {triggerRef}
            </PopoverTrigger>
            <PopoverContent zIndex={Constants.zIndex.popover} bgImage="url('/icons/dialog.png')" bgSize="cover" bgRepeat="no-repeat" borderColor='dialogBorder' >
                <PopoverBody zIndex={Constants.zIndex.popover}>
                    {mode === 'login' && <Login onOpen={updateRef} onRegister={() => { setMode('register')}} onForgotPassword={() => { setMode('forgot-password') }}/>}
                    {mode === 'register' && updateRef && <RegisterContent />}
                    {mode === 'forgot-password' && <ForgotPassword /> }
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}

export default LoginRegisterPopover;
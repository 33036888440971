import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { learningLanguageState, userState } from "../../state/user-state";
import { useTranslation } from "react-i18next";
import { useUser } from "../../auth/user-context";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Divider, IconButton, Input, Radio, RadioGroup, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { loadStorySnippets } from "../../api/translation.service";
import DictionarySearchResults from "../../components/dictionary-search-result";
import { selectedStoryMappingState } from "../../state/story-state";
import { copyJSONObject, deepEqual, isDefined, random8ID, randomID } from "../../utils/utils";
import CopyToClipboardButton from "../../components/copy-button";
import ChooseLanguageComponent from "../../components/choose-language-component";
import { getLanguageWithCode, getStoryLanguageWithCode, LANGUAGES, LanguageVariants, StoryLanguages } from "../../utils/languages";
import { uploadStory } from "../../api/story.service";
import StoryDetails from "../../stories/story-details";
import { isPageValidState } from "../../state/app-state";
import ChooseLearningLanguage from "../../components/choose-learning-language";
import { Constants } from "../../utils/constants";
import { addPlainTextStory, generateMappings, generateStoryText, generateStoryTextAndSaveToDB, getGenerateMappingStories, getReviewStories, publishStory, submitGeneratedMappings, updateReviewStory, updateStoryJSON, uploadReviewStoryImage } from "./manage-stories.service";
import { showAddEditSnippetModal, showDefaultAddSnippetModal, showSnippetsModalState } from "../../state/snippets-state";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { AddEditSnippetModalType, AddToArrayIndexStrategy } from "../../snippets/snippet-modal";
import { manageStoriesListStoriesState, manageStoriesReviewSnippetModalSelectedMappingState, manageStoriesSelectedMappingIDState, manageStoriesSelectedStoryIDState, manageStoriesStoriesToUpdateState } from "./manage-stories-state";
import { findStoryMapping, replaceStoryMapping } from "../story-utils";
import DeleteConfirmation from "../../dialogs/delete-confirmation";
import { set } from "@firebase/database";
import ImageUploadComponent from "../../components/image-upload";
import FallbackImage from "../../components/fallback-image";
import UploadImageModal from "../../dialogs/upload-image-modal";
import ArrowKeyListener from "../../components/arrow-key-listener";
const ManageStoryMode = {
    ADD_STORY: { id: "ADD_STORY", title: "Add Story" },
    GENERATE_MAPPINGS: { id: "GENERATE_MAPPINGS", title: "Gen. Mappings" },
    REVIEW: { id: "REVIEW", title: "Review" },
    all: () => [ManageStoryMode.ADD_STORY, ManageStoryMode.GENERATE_MAPPINGS, ManageStoryMode.REVIEW]
}

const AddStoryMode = {
    RAW_JSON: { id: "RAW_JSON", title: "Raw JSON" },
    ADD_TEXT: { id: "ADD_TEXT", title: "Add Text" },
    GENERATE: { id: "GENERATE", title: "Generate" },
    GENERATE_AND_SAVE_TO_DB: { id: "GENERATE_AND_SAVE_TO_DB", title: "Generate and Save" },
    all: () => [AddStoryMode.RAW_JSON, AddStoryMode.ADD_TEXT, AddStoryMode.GENERATE, AddStoryMode.GENERATE_AND_SAVE_TO_DB]
}

const AddStoryLevel = {
    A1: { id: "A1", title: "A1" },
    A2: { id: "A2", title: "A2" },
    B1: { id: "B1", title: "B1" },
    B2: { id: "B2", title: "B2" },
    C1: { id: "C1", title: "C1" },
    C2: { id: "C2", title: "C2" },
    all: () => [AddStoryLevel.A1, AddStoryLevel.A2, AddStoryLevel.B1, AddStoryLevel.B2, AddStoryLevel.C1, AddStoryLevel.C2]
}

const colorSelectedStory = 'rgba(255, 255, 255, 0.25)'
const colorUnselectedStory = 'rgba(255, 255, 255, 0.05)'

function AdminManageStories() {

    // check sentence modal
    // state
    const [mode, setMode] = useState(ManageStoryMode.ADD_STORY.id);

    // Deleting
    const [deletingSnippetID, setDeletingSnippetID] = useState(null);
    const [isDeletingSnippet, setIsDeletingSnippet] = useState(false);

    // Update
    const [storiesToUpdate, setStoriesToUpdate] = useRecoilState(manageStoriesStoriesToUpdateState)
    const [isPublishing, setIsPublishing] = useState(false);
    const [isUpdatingData, setUpdatingData] = useState(false);
    const [canUpdateData, setCanUpdateData] = useState(false);
    // Data
    const [resultSnippets, setResultSnippets] = useState(null);
    // const [selectedMapping, setSelectedMapping] = useRecoilState(manageStoriesReviewSelectedMappingState)
    const [storyLanguage, setStoryLanguage] = useState(null);
    const [dictionaryLanguage, setDictionaryLanguage] = useState(null);

    // *** MODES ***
    // *** ADD STORY MODE ***
    const [addStoryMode, setAddStoryMode] = useState(AddStoryMode.ADD_TEXT.id)
    const [addStoryPlainText, setAddStoryPlainText] = useState('')
    const [addStoryRawJSON, setAddStoryRawJSON] = useState('')
    const [addStoryGeneratedText, setAddStoryGeneratedText] = useState('')
    const [addStoryGeneratedIsSubmitting, setAddStoryGeneratedIsSubmitting] = useState(false)
    const [addStorySelectedLevel, setAddStorySelectedLevel] = useState(AddStoryLevel.A1.id)
    const [addStoryTitle, setAddStoryTitle] = useState('')
    const [addStoryIsGenerating, setAddStoryIsGenerating] = useState(false)
    const [addStoryIsSubmitting, setAddStoryIsSubmitting] = useState(false)
    const [addStoryCanSubmit, setAddStoryCanSubmit] = useState(false)

    // *** GENERATE MAPPINGS MODE ***
    const [genMappingsStories, setGenMappingsStories] = useState(null)
    const [selectedGenMappingStory, setSelectedGenMappingStory] = useState(null)
    const [selectedGenMappingPlainTextValue, setSelectedGenMappingPlainTextValue] = useState(null)
    const [selectedMappingsChanged, setSelectedMappingsChanged] = useState(false)
    const [selectedMappingsError, setSelectedMappingsError] = useState(null)
    const [selectedGenMappingStoryID, setSelectedGenMappingStoryID] = useState(null)
    const [generateMappingsText, setGenerateMappingsText] = useState(null)
    const [isSubmittingGenerateMappings, setIsSubmittingGenerateMappings] = useState(false)
    const [isGeneratingMappings, setIsGeneratingMappings] = useState(false)
    const [generateMappingsError, setGenerateMappingsError] = useState(null)
    const [localMappings, setLocalMappings] = useState({})

    // *** REVIEW MODE ***
    const { isOpen: isUploadReviewImageOpen, onOpen: onUploadReviewImageOpen, onClose: onUploadReviewImageClose } = useDisclosure()
    const { isOpen: isPublishOpen, onOpen: onPublishOpen, onClose: onPublishClose } = useDisclosure()
    const [reviewStories, setReviewStories] = useRecoilState(manageStoriesListStoriesState)
    const [selectedMappingID, setSelectedMappingID] = useRecoilState(manageStoriesSelectedMappingIDState)
    const [selectedReviewStoryID, setSelectedReviewStoryID] = useRecoilState(manageStoriesSelectedStoryIDState)
    const [selectedReviewStoryImageURL, setSelectedReviewStoryImageURL] = useState(null)
    const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);

    // grouping
    const [groupMode, setGroupMode] = useState(false);
    const [selectedGroupMappings, setSelectedGroupMappings] = useState([]);

    // Add/Edit Snippet modal
    const [selectedStoryMapping, setSelectedStoryMapping] = useRecoilState(manageStoriesReviewSnippetModalSelectedMappingState)
    const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
        showSnippetsModalState
    );

    // Page
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);
    const toast = useToast();
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userState);
    const [chooseLanguageHeight, setChooseLanguageHeight] = useState(0);
    const chooseLanguageRef = useRef(null);

    const allModes = ManageStoryMode.all()
    const allAddStoryModes = AddStoryMode.all()

    const {
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser();

    useEffect(() => {
        console.log("Stories to update changed: ", JSON.stringify(storiesToUpdate, null, 2))
        setCanUpdateData(Object.keys(storiesToUpdate).length !== 0)
    }, [storiesToUpdate])

    useEffect(() => {
        let parentLanguage = null
        if (storyLanguage) {
            let code = LanguageVariants.parentLanguageForVariant(storyLanguage.code)
            if (code) {
                parentLanguage = getLanguageWithCode(code)
            }
        }

        setDictionaryLanguage(parentLanguage)
    }, [storyLanguage])

    useEffect(() => {
        let lastLanguageCode = localStorage.getItem('last_manage_story_language')
        if (lastLanguageCode) {
            setStoryLanguage(getStoryLanguageWithCode(lastLanguageCode))
        } else {
            setStoryLanguage(getStoryLanguageWithCode('es-MX'))
        }

        // Update the height when the component mounts or ref changes
        if (chooseLanguageRef.current) {
            setChooseLanguageHeight(chooseLanguageRef.current.offsetHeight);
        }

        // Optional: add a resize observer to handle dynamic resizing
        const resizeObserver = new ResizeObserver(() => {
            if (chooseLanguageRef.current) {
                setChooseLanguageHeight(chooseLanguageRef.current.offsetHeight);
            }
        });

        if (chooseLanguageRef.current) {
            resizeObserver.observe(chooseLanguageRef.current);
        }

        return () => {
            if (chooseLanguageRef.current) {
                resizeObserver.unobserve(chooseLanguageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!storyLanguage?.code) {
            return;
        }
        if (mode === ManageStoryMode.REVIEW.id) {
            if (!reviewStories || reviewStories.length === 0) {
                // Create an async function and call it
                reloadReviewStories()
            }
        } else if (mode === ManageStoryMode.GENERATE_MAPPINGS.id) {
            if (!genMappingsStories || genMappingsStories.length === 0) {

                reloadGenMappingStories(); // Call the async function
            }
        }

    }, [mode, storyLanguage]);

    async function reloadReviewStories() {
        try {
            setSelectedReviewStoryID(null)
            setReviewStories(null)
            setSelectedMappingID(null)
            setSelectedStoryMapping(null)

            const stories = await getReviewStories(storyLanguage.code);
            setReviewStories(stories);
        } catch (error) {
            setReviewStories([]);
            toast({
                title: 'Error loading review stories',
                status: 'error'
            });
        }
    }

    async function reloadGenMappingStories() {
        try {
            setSelectedGenMappingStoryID(null)
            setGenMappingsStories(null)
            const stories = await getGenerateMappingStories(storyLanguage.code);
            setGenMappingsStories(stories);
        } catch (error) {
            setGenMappingsStories([]);
            toast({
                title: 'Error loading gm stories',
                status: 'error'
            });
        }
    }

    function getSelectedStory() {
        if (!selectedReviewStoryID || !reviewStories) {
            return null
        }

        return reviewStories.find((story) => story.id === selectedReviewStoryID)
    }

    function getSelectedMapping() {
        if (!selectedMappingID || !selectedReviewStoryID || !reviewStories) {
            return null
        }

        let selectedStory = reviewStories.find((story) => story.id === selectedReviewStoryID)

        let selectedMapping = null
        for (let sentence of selectedStory.sentences) {
            let mappings = sentence.mappings ?? []
            for (let mapping of mappings) {
                if(!mapping) {
                    // console.log("INVALID MAPPINGS ", JSON.stringify(mappings, null, 2))
                    continue
                }
                if (mapping.id === selectedMappingID) {
                    selectedMapping = mapping
                    break
                }
            }
        }

        return { mapping: selectedMapping, story: selectedStory }
    }

    useEffect(() => {
        setResultSnippets(null)

        // selectedMapping changed

        let mapping = getSelectedMapping()?.mapping

        let selectedStory = getSelectedMapping()?.story
        if (!mapping || !selectedStory) {
            return
        }

        console.log('onSelectMapping', mapping)

        let learningLanguageCode = dictionaryLanguage?.code

        if (!learningLanguageCode) {
            return
        }
        async function load() {
            try {
                setIsLoadingSnippet(true)

                let definitions = selectedStory.selected_definitions?.[userLanguage.code] ?? {}
                let mappingCopy = copyJSONObject(mapping)
                let { snippets, mappingChanged } = await loadStorySnippets(mappingCopy, definitions, learningLanguageCode, userLanguage.code, t)

                if (mappingChanged) {
                    console.log('Mapping changed, setting new mapping ' + JSON.stringify(mappingCopy, null, 2))
                    let newStory = copyJSONObject(selectedStory)
                    newStory = replaceStoryMapping(newStory, mapping.id, mappingCopy)
                    setDataChanged(newStory.id)
                    updateStory(newStory)
                }

                console.log('Result snippets ', JSON.stringify(snippets, null, 2))
                setResultSnippets(snippets)
            } catch (error) {
                console.error(error)
                setResultSnippets(null)
            } finally {
                setIsLoadingSnippet(false)
            }
        }

        load()

    }, [selectedMappingID, selectedReviewStoryID, storyLanguage, reviewStories])

    useEffect(() => {
        reloadReviewStoryImage()
        console.log('reviewStories or selectedReviewStoryID changed')
    }, [selectedReviewStoryID, reviewStories])

    function reloadReviewStoryImage() {
        let imageURL = null
        let story = getSelectedStory()
        if (story && story.imageURLPath) {
            imageURL = Constants.storageURLWithPath(story.imageURLPath)
        }

        console.log('Setting selected review story image URL to ' + imageURL + ' for story ' + story?.id)
        setSelectedReviewStoryImageURL(imageURL)
    }

    function onLanguageChanged(newLanguage) {
        localStorage.setItem('last_manage_story_language', newLanguage.code)
        setStoryLanguage(newLanguage)
        setGenMappingsStories(null)
        setReviewStories(null)
        setSelectedGenMappingStoryID(null)
        setSelectedGenMappingStory(null)
        setSelectedReviewStoryID(null)
        setSelectedMappingID(null)
        setSelectedStoryMapping(null)
    }

    function updateStory(newStory) {
        setSelectedReviewStoryID(newStory.id)
        let updatedStories = [...reviewStories]
        let idx = updatedStories.findIndex((story) => story.id === newStory.id)
        updatedStories[idx] = newStory
        setReviewStories(updatedStories)
    }

    const onSelectMapping = async (mapping) => {
        console.log('onSelectMapping called with ', JSON.stringify(mapping))
        if (groupMode) {
            setSelectedGroupMappings(mapping);
        } else {
            setSelectedMappingID(mapping?.id)
        }
    }

    function setDataChanged(storyID) {
        let stories = { ...storiesToUpdate }
        stories[storyID] = true
        setStoriesToUpdate(stories)
    }

    function onClickAddSnippet() {
        setSelectedStoryMapping(getSelectedMapping())
        showDefaultAddSnippetModal(setShowingSnippetsModal, t, null, null, AddEditSnippetModalType.ADD_TO_MAPPING(AddToArrayIndexStrategy.BEGINNING))
    }

    function onDeleteSnippetClicked(snippetID) {
        for (let idx in resultSnippets) {
            if (resultSnippets[idx].id === snippetID) {
                setDeletingSnippetID(snippetID)
                return
            }
        }
    }

    function doDeleteSelectedSnippet() {
        console.log('doDeleteSelectedSnippet', deletingSnippetID)
        let selectedMapping = getSelectedMapping()
        let mapping = selectedMapping?.mapping
        let selectedStory = selectedMapping?.story
        if (!mapping || !selectedStory || !isDefined(deletingSnippetID)) {
            console.error(`Invalid state to delete snippet ${deletingSnippetID} from mapping ${JSON.stringify(mapping, null, 2)} and story ${JSON.stringify(selectedStory, null, 2)}`)
            return
        }

        setDeletingSnippetID(null)
        setIsDeletingSnippet(false)

        // find index of snippetID in resultSnippets
        let idx = resultSnippets.findIndex((snippet) => snippet.id === deletingSnippetID)

        if (idx < 0) {
            console.error('Snippet not found with id ' + deletingSnippetID)
            return
        }

        let newStory = copyJSONObject(selectedStory)
        let copy = copyJSONObject(mapping)
        // remove snippet from snippets array with index idx
        copy.snippets = copy.snippets.filter((snippet, index) => index !== idx)
        console.log('New snippets ', JSON.stringify(copy.snippets, null, 2))
        // replace mapping
        newStory = replaceStoryMapping(newStory, mapping.id, copy)
        updateStory(newStory)
        onSelectMapping(copy)
        setDataChanged(newStory.id)
    }

    function onEditSnippet(snippetID) {
        // setSelectedMapping({ mapping: snippet.mapping, story: selectedStory })
        // setResultSnippets(null)
        let snippet = null
        let index = null
        for (let i = 0; i < resultSnippets.length; i++) {
            if (resultSnippets[i].id === snippetID) {
                snippet = resultSnippets[i]
                index = i
                break
            }
        }

        if (snippet && index !== null) {
            console.log('showing snippet modal for ' + snippetID)
            let searchModels = [snippet]
            setSelectedStoryMapping(getSelectedMapping())
            showAddEditSnippetModal(
                setShowingSnippetsModal,
                snippetID,
                null,
                t("sentences.edit_snippet_title"),
                null,
                searchModels,
                snippetID,
                null,
                null,
                snippet?.term,
                snippet?.definitionsLanguageCode,
                AddEditSnippetModalType.ADD_TO_MAPPING(AddToArrayIndexStrategy.REPLACE(index))
            )
        } else {
            console.error('Snippet not found with id ' + snippetID)
        }
    }

    async function doPublishStory() {
        if (isPublishing || selectedReviewStoryID === null) {
            return
        }

        if (canUpdateData) {
            toast({
                title: 'Please update stories before publishing',
                status: 'error'
            })
            return
        }

        try {
            setIsPublishing(true)

            let story = reviewStories.find((story) => story.id === selectedReviewStoryID)
            if (!story) {
                console.error('Story not found with id ' + selectedReviewStoryID)
                return
            }

            await publishStory(story.id, story.tempLanguageCode, story.level.toLowerCase())

            toast({
                title: 'Story published',
                status: 'success'
            })
            reloadReviewStories()

        } catch (e) {
            console.error('Error publishing', e)
            toast({
                title: 'Error publishing story: ' + e.message,
                status: 'error'
            })
        } finally {
            setIsPublishing(false)
            onPublishClose()
        }
    }

    async function onClickUpdate() {
        if (Object.keys(storiesToUpdate).length === 0) {
            return
        }

        setUpdatingData(true)

        let storyKeys = Object.keys(storiesToUpdate)
        let promises = storyKeys.map((storyID) => {
            let story = reviewStories.find((story) => story.id === storyID)
            if (!story) {
                console.error('Story not found with id ' + storyID)
                return Promise.resolve()
            }

            return updateReviewStory(story)
        })

        try {
            await Promise.all(promises)
            setStoriesToUpdate({})
            toast({
                title: 'Stories updated',
                status: 'success'
            })
        } catch (error) {
            console.error('Error updating stories', error)
            toast({
                title: 'Error updating stories',
                status: 'error'
            })
        } finally {
            setUpdatingData(false)
        }
    }

    // Add story

    useEffect(() => {
        setAddStoryCanSubmit(addStoryPlainText.trim().length > 10 && addStoryTitle.length > 2)
    }, [addStoryPlainText, addStoryTitle])

    async function onClickAddStoryGenerateAndSave() {
        if (addStoryIsGenerating) {
            return
        }

        setAddStoryIsGenerating(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()
        let languageCode = storyLanguage.code

        try {
            await generateStoryTextAndSaveToDB(languageCode, lowercasedLevel)

            await reloadGenMappingStories()
            toast({
                title: 'Story added. Open mappings tab to see the added story',
                status: 'success'
            })
        } catch (e) {
            console.error('Error generating story', e)
            toast({
                title: 'Error generating story ' + e,
                status: 'error'
            })
        } finally {
            setAddStoryIsGenerating(false)
        }
    }

    async function onClickAddStoryGenerate() {
        if (addStoryIsGenerating) {
            return
        }

        setAddStoryIsGenerating(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()
        let languageCode = storyLanguage.code

        try {
            let storyObject = await generateStoryText(languageCode, lowercasedLevel)
            // sleep for 2 seconds
            await new Promise(r => setTimeout(r, 2000))

            setAddStoryGeneratedText(JSON.stringify(storyObject, null, 2))
        } catch (e) {
            console.error('Error generating story', e)
            toast({
                title: 'Error generating story',
                status: 'error'
            })
        } finally {
            setAddStoryIsGenerating(false)
        }
    }

    async function onSubmitGeneratedStoryRawJSON() {
        if (addStoryGeneratedIsSubmitting) {
            return
        }

        setAddStoryGeneratedIsSubmitting(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()

        try {
            let story = JSON.parse(addStoryRawJSON)
            if (story) {
                await addPlainTextStory(lowercasedLevel, storyLanguage.code, story)
                toast({
                    title: 'Story added',
                    status: 'success'
                })
                setGenMappingsStories(null)
                setAddStoryRawJSON('')
            } else {
                throw new Error('Invalid story object')
            }

        } catch (e) {
            console.error('Error adding story', e)
            toast({
                title: 'Error adding story',
                status: 'error'
            })
        } finally {
            setAddStoryGeneratedIsSubmitting(false)
        }
    }

    async function onSubmitGeneratedStoryText() {
        if (addStoryGeneratedIsSubmitting) {
            return
        }

        setAddStoryGeneratedIsSubmitting(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()

        try {
            let story = JSON.parse(addStoryGeneratedText)
            if (story) {
                await addPlainTextStory(lowercasedLevel, storyLanguage.code, story)
                toast({
                    title: 'Story added',
                    status: 'success'
                })
                setGenMappingsStories(null)
                setAddStoryGeneratedText('')
            } else {
                throw new Error('Invalid story object')
            }

        } catch (e) {
            console.error('Error adding story', e)
            toast({
                title: 'Error adding story',
                status: 'error'
            })
        } finally {
            setAddStoryGeneratedIsSubmitting(false)
        }
    }

    async function onSubmitAddStoryPlainText() {
        if (addStoryIsSubmitting) {
            return
        }

        setAddStoryIsSubmitting(true)

        let lowercasedLevel = addStorySelectedLevel.toLowerCase()

        let story = {
            level: lowercasedLevel,
            title: addStoryTitle,
            text: addStoryPlainText,
        }

        try {
            await addPlainTextStory(lowercasedLevel, storyLanguage.code, story)
            toast({
                title: 'Story added',
                status: 'success'
            })
            setGenMappingsStories(null)
            setAddStoryPlainText('')
            setAddStoryTitle('')
        } catch (e) {
            console.error('Error adding story', e)
            toast({
                title: 'Error adding story',
                status: 'error'
            })
        } finally {
            setAddStoryIsSubmitting(false)
        }
    }

    // *** GENERATE MAPPINGS ***

    useEffect(() => {
        let errorText = validateGeneratedMappingsText(generateMappingsText)
        setGenerateMappingsError(errorText)

        console.log("Error text ", errorText)
    }, [generateMappingsText])

    useEffect(() => {
        let text = null
        if (selectedGenMappingStoryID) {
            console.log("selected id ", selectedGenMappingStoryID)
            let mappings = localMappings[selectedGenMappingStoryID]
            if (mappings) {
                text = JSON.stringify(mappings, null, 2)
            }
        }

        setGenerateMappingsText(text)

        let story = genMappingsStories?.find((story) => story.id === selectedGenMappingStoryID)
        setSelectedGenMappingStory(story)
        setSelectedGenMappingPlainTextValue(JSON.stringify(story, null, 2))
    }, [selectedGenMappingStoryID])

    async function onSelectedStoryMappingsChanged(newText) {
        setSelectedGenMappingPlainTextValue(newText)
    }

    useEffect(() => {
        try{
            JSON.parse(selectedGenMappingPlainTextValue)
            setSelectedMappingsError(null)
        } catch (e) {
            setSelectedMappingsError('Invalid JSON')
        }
    }, [selectedGenMappingPlainTextValue])

    async function onClickEditStory() {
        if (selectedMappingsError) {
            return
        }

        let json = JSON.parse(selectedGenMappingPlainTextValue)
        if (!json) {
            console.error('Invalid JSON')
            return
        }

        if (!selectedGenMappingStoryID) {
            console.error('No story selected to generate mappings')
            return
        }

        let story = genMappingsStories?.find((story) => story.id === selectedGenMappingStoryID)

        if (!story) {
            console.error('Invalid state to generate mappings')
            return
        }

        try {
            setIsGeneratingMappings(true)
            await updateStoryJSON(story.id, storyLanguage.code, story.level, json)
            toast({
                title: 'Story updated',
                status: 'success'
            })
        } catch (e) {
            console.error('Error generating mappings ', e.message)
            toast({
                title: e.message,
                status: 'error'
            })
        } finally {
            setIsGeneratingMappings(false)
        }
    }

    async function onClickGenerateMappings() {
        if (isSubmittingGenerateMappings) {
            return
        }

        if (!selectedGenMappingStoryID) {
            console.error('No story selected to generate mappings')
            return
        }

        let story = genMappingsStories?.find((story) => story.id === selectedGenMappingStoryID)

        if (!story) {
            console.error('Invalid state to generate mappings')
            return
        }

        try {
            setIsGeneratingMappings(true)

            const mappings = await generateMappings(storyLanguage.code, story.level, story.id)
            setLocalMappings({ ...localMappings, [story.id]: mappings })

            // update genMappingsStories with updated story
            let updatedStories = [...genMappingsStories]
            let idx = updatedStories.findIndex((s) => s.id === story.id)
            updatedStories[idx] = story
            setGenMappingsStories(updatedStories)

            setGenerateMappingsText(JSON.stringify(mappings, null, 2))

            setReviewStories(null)
            toast({
                title: 'Mappings generated',
                status: 'success'
            })
        } catch (e) {
            console.error('Error generating mappings', e)
            toast({
                title: 'Error generating mappings' + e.message,
                status: 'error'
            })
        } finally {
            setIsGeneratingMappings(false)
        }
    }

    async function onSubmitGenerateMappings() {
        if (isSubmittingGenerateMappings) {
            return
        }


        let story = genMappingsStories.find((story) => story.id === selectedGenMappingStoryID)

        if (!story || !generateMappingsText) {
            console.error('Invalid state to submit generate mappings')
            return
        }

        try {
            setIsSubmittingGenerateMappings(true)
            let mappingDict = JSON.parse(generateMappingsText)
            if (!mappingDict) {
                throw new Error('Invalid JSON')
            }
            await submitGeneratedMappings(storyLanguage.code, story.level, story.id, mappingDict)
            // success
            await reloadGenMappingStories()
        } catch (e) {
            console.error('Error submitting generate mappings', e)
            toast({
                title: 'Error submitting generate mappings',
                status: 'error'
            })
        } finally {
            setIsSubmittingGenerateMappings(false)
        }

    }

    // returns error string
    function validateGeneratedMappingsText(text) {
        try {
            let json = JSON.parse(text)
            if (json) {
                return validateGeneratedMappings(json)
            } else {
                return "Can't parse JSON"
            }
        } catch (e) {
            return "Can't parse JSON"
        }

    }

    function validateGeneratedMappings(json) {
        const validLevels = ["A1", "A2", "B1", "B2", "C1", "C2"];
        const errors = [];

        // Validate root-level properties
        if (!json.languageCode) {
            errors.push("Missing languageCode.");
        }

        if (!validLevels.includes(json.level)) {
            errors.push(`Invalid level: ${json.level}. Allowed values: A1, A2, B1, B2, C1, C2.`);
        }

        if (!json.sentences || !Array.isArray(json.sentences)) {
            errors.push("Missing or invalid sentences array.");
        }

        if (!json.text || typeof json.text !== "string" || json.text.trim() === "") {
            errors.push("Invalid or empty root text property.");
        }

        if (!json.title || typeof json.title !== "string" || json.title.trim() === "") {
            errors.push("Invalid or empty root title property.");
        }

        // Validate sentences
        json.sentences.forEach((sentence, sentenceIndex) => {
            if (sentence.text) {
                if (typeof sentence.text !== "string" || sentence.text.trim() === "") {
                    errors.push(`Sentence ${sentenceIndex}: Invalid or empty text.`);
                }

                if (sentence.mappings && Array.isArray(sentence.mappings)) {
                    sentence.mappings.forEach((mapping, mappingIndex) => {
                        // Validate mapping object
                        if (!mapping.range || typeof mapping.range.length !== "number" || mapping.range.length < 0 ||
                            typeof mapping.range.location !== "number" || mapping.range.location < 0) {
                            errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}: Invalid range (length or location).`);
                        }

                        if (!mapping.snippets || !Array.isArray(mapping.snippets)) {
                            errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}: Missing or invalid snippets array.`);
                        } else {
                            mapping.snippets.forEach((snippet, snippetIndex) => {
                                // Validate snippet variants
                                if (snippet.text && snippet.word_type) {
                                    if (typeof snippet.text !== "string" || snippet.text.trim() === "" ||
                                        typeof snippet.word_type !== "string" || snippet.word_type.trim() === "") {
                                        errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid or empty text or word_type.`);
                                    }
                                } else if (snippet.infinitive) {
                                    // const requiredFields = ["count", "infinitive", "person", "tense", "text", "word_type"];
                                    // for (const field of requiredFields) {
                                    //     if (!snippet[field] || (typeof snippet[field] === "string" && snippet[field].trim() === "")) {
                                    //         errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Missing or invalid field '${field}' in snippet ${JSON.stringify(snippet, null, 2)}.`);
                                    //     }
                                    // }
                                    // if (typeof snippet.person !== "number") {
                                    //     errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid 'person' value.`);
                                    // }
                                } else {
                                    errors.push(`Sentence ${sentenceIndex}, Mapping ${mappingIndex}, Snippet ${snippetIndex}: Invalid snippet (must have either text/word_type or inflection fields).`);
                                }
                            });
                        }
                    });
                } else {
                    errors.push(`Sentence ${sentenceIndex}: Missing or invalid mappings array.`);
                }
            } else if (!sentence.new_line) {
                errors.push(`Sentence ${sentenceIndex}: Missing text or new_line property.`);
            }
        });

        return errors.length ? errors.join("\n\n") : null;
    }

    function onUploadReviewImage(base64Image) {
        let story = getSelectedStory()

        if (!story?.level) {
            throw new Error('Story not found')
        }

        return uploadReviewStoryImage(base64Image, selectedReviewStoryID, storyLanguage.code, story.level)
    }

    function onClickStoryImage(story) {
        setSelectedReviewStoryID(story.id)
        onUploadReviewImageOpen()
    }

    // grouping

    function onCombineIntoGroup() {
        let minIndex = -1
        let maxIndex = -1

        let keys = []

        let story = getSelectedStory()

        if (!story) {
            console.error('Story not found')
            return
        }

        let modelCopy = copyJSONObject(story)
        console.log("Iterating through mappings " + JSON.stringify(selectedGroupMappings))
        for (let mapping of selectedGroupMappings) {
            let firstCharacterIndex = mapping.range.location
            let lastCharacterIndex = mapping.range.location + mapping.range.length

            if (minIndex === -1 || firstCharacterIndex < minIndex) {
                console.log('minIndex is now ' + firstCharacterIndex)
                minIndex = firstCharacterIndex
            }

            if (maxIndex === -1 || lastCharacterIndex > maxIndex) {
                console.log('maxIndex is now ' + lastCharacterIndex)
                maxIndex = lastCharacterIndex
            }

            keys.push(mapping.id)
        }

        if (minIndex !== -1 && maxIndex !== -1) {
            let sentenceToFix = null
            console.log('keys to remove: ' + JSON.stringify(keys, null, 2))
            // for(let key in keys) {
            // remove from mappings array where .key property === key
            for (let sentence of modelCopy.sentences) {
                console.log('Pre filter mappings ' + JSON.stringify(sentence.mappings) + ' for sentence ' + JSON.stringify(sentence))
                if (sentence.mappings) {
                    let newMappings = sentence.mappings.filter((mapping) => !keys.includes(mapping.id))
                    console.log('Filtered new mappings ' + JSON.stringify(newMappings))
                    if (!sentenceToFix && newMappings.length !== sentence.mappings.length) {
                        console.log('sentenceToFix is ' + JSON.stringify(sentence, null, 2))
                        sentenceToFix = sentence
                        console.log('Assigning newMappings ' + JSON.stringify(newMappings) + ' instead of ' + JSON.stringify(sentence.mappings))
                        sentence.mappings = newMappings
                    }
                }
            }
            // }

            let combinedSnippets = []
            for (let mapping of selectedGroupMappings) {

                for (let snippet of mapping.snippets) {
                    let copy = copyJSONObject(snippet)
                    combinedSnippets.push(copy)
                }
                // delete copy.template
                // delete copy.range
                // delete copy.key
                // delete copy.other_snippets

                // let mappingOtherSnippets = mapping.other_snippets ?? {}
                // for (let key in mappingOtherSnippets) {
                //     otherSnippets[key] = copyJSONObject(mappingOtherSnippets[key])
                // }

                // otherSnippets[random8ID()] = copy
            }

            let combinedMapping = {
                id: random8ID(),
                text: sentenceToFix.text.slice(minIndex, maxIndex),
                // word_type: "proper_noun", // hardcoded for now 
                range: {
                    location: minIndex,
                    length: maxIndex - minIndex
                },
                snippets: combinedSnippets
            }

            sentenceToFix.mappings.push(combinedMapping)
            sentenceToFix.mappings = sentenceToFix.mappings.sort((a, b) => a.range.location - b.range.location)
            console.log('fixed model after combining: ' + JSON.stringify(modelCopy, null, 2))

            updateStory(modelCopy)
            // setFixedModel(copyJSONObject(modelCopy))
        } else {
            console.log('Failed, indices are ' + minIndex + ' ' + maxIndex)
        }

        setSelectedGroupMappings([])
        setGroupMode(false)
    }

    function onClickEnterGroupMode() {
        if (!groupMode) {
            setGroupMode(true)
        } else {
            // already in group mode, check count
            if (selectedGroupMappings.length === 0) {
                // leave group mode
                setGroupMode(false)
            } else {
                // combine
                onCombineIntoGroup()
            }
        }
    }

    // const onLeftKey = () => {
    //     console.log('onLefty')
    //     if(!getSelectedMapping()?.mapping) {
    //         console.log('onLeftKey No selected mapping')
    //         return 
    //     }
        
    //     if (!selectedMappingID || !selectedReviewStoryID || !reviewStories) {
    //         console.log('Something is missing')
    //         return null
    //     }

    //     let selectedStory = reviewStories.find((story) => story.id === selectedReviewStoryID)

    //     let mappingToSelect = null

    //     let previousSentence = null
    //     for (let sentence of selectedStory.sentences) {
    //         if(mappingToSelect) {
    //             break
    //         }
    //         let mappings = sentence.mappings ?? []
    //         let previousMapping = null
    //         for (let mapping of mappings) {
    //             if (mapping.id === selectedMappingID) {
    //                 if(previousMapping) {
    //                     mappingToSelect = previousMapping
    //                     break
    //                 } else if(previousSentence){
    //                     let mappings = previousSentence.mappings ?? []
    //                     if(mappings.length > 0) {
    //                         mappingToSelect = mappings[mappings.length - 1]
    //                         break
    //                     }
    //                 } else {
    //                     console.log('else')
    //                 }
    //             }

    //             previousMapping = mapping
    //         }
    //         previousSentence = sentence
    //     }

    //     if(mappingToSelect) {
    //         console.log('Selecting mapping')
    //         onSelectMapping(mappingToSelect)
    //     } else {
    //         console.log('no mapping to select')
    //     }
        
    // }

    // const onRightKey = () => {

    // }

    return (
        <>
            <Box
                data-test="admin-manage-stories-page"
                h="100vh"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                {/* <ArrowKeyListener onLeftKey={onLeftKey} /> */}
                <Header />
                <Box w='100%' h="calc(100% - 128px)">
                    <Box mt={1} mb={1} w='100%' display='flex' direction='column' alignItems='space-between'>
                        <ChooseLanguageComponent ref={chooseLanguageRef} languages={StoryLanguages.asArray()} preSelectedLanguage={storyLanguage} onChangeLanguage={(code) => {
                            onLanguageChanged(getStoryLanguageWithCode(code))
                        }} />
                        {mode === "REVIEW" && selectedReviewStoryID && <Box w='100%' display='flex' justifyContent='space-between'>
                            <Box></Box> {/* Empty Box to push the button to the right */}
                            <Box>
                                <Button mr={4} colorScheme="green" onClick={onClickEnterGroupMode} isDisabled={isUpdatingData}>{groupMode ? (selectedGroupMappings.length === 0 ? "Leave Group Mode" : "Group Selected") : "Enter group mode"}</Button>
                                <Button mr={4} isLoading={isUpdatingData} colorScheme="blue" onClick={onClickUpdate} isDisabled={!canUpdateData || groupMode}>Update Review Stories</Button>
                                <Button mr={4} isLoading={isUpdatingData} colorScheme="red" onClick={onPublishOpen} isDisabled={canUpdateData || !selectedReviewStoryID || groupMode}>Publish</Button>
                            </Box>
                        </Box>
                        }
                    </Box>
                    <Box display='flex' h={`calc(100% - 40px)`}>

                        <Box h='100%' flex={5}>
                            <Tabs color='white' onChange={(index) => {
                                setMode(allModes[index].id)
                            }}>
                                <TabList>
                                    {allModes.map((mode) => {
                                        return <Tab key={mode.id}>{mode.title}</Tab>
                                    })}
                                </TabList>

                                <TabPanels>
                                    {allModes.map((mode) => {
                                        return <TabPanel key={mode.id}>
                                            {/* Tab Content */}

                                            {mode.id === "REVIEW" && <Box>
                                                {!reviewStories && <Spinner />}
                                                {reviewStories && reviewStories.length === 0 && <Center><Text>No stories to review</Text></Center>}
                                                {reviewStories && reviewStories.length > 0 && <Box>
                                                    {reviewStories.map((story) => {
                                                        let isSelected = selectedReviewStoryID === story.id
                                                        return <Box key={story.id} p={2} my={2} display='flex' flexDirection='row' borderRadius='md' cursor="pointer" backgroundColor={isSelected ? colorSelectedStory : colorUnselectedStory} onClick={() => setSelectedReviewStoryID(story.id)}>

                                                            <FallbackImage onClick={(e) => { onClickStoryImage(story) }} boxSize='80px' src={Constants.storageURLWithPath(story.imageURLPath)} fallbackSrc="/icons/story-placeholder-blue.png" />
                                                            <Box ml={2}>
                                                                <Text>Title: <b>{story.title}</b></Text>
                                                                <Text>Level: <b>{story.level}</b></Text>
                                                            </Box>
                                                        </Box>
                                                    }
                                                    )}
                                                </Box>}
                                            </Box>}

                                            {mode.id === "GENERATE_MAPPINGS" && <Box>
                                                {!genMappingsStories && <Spinner />}
                                                {genMappingsStories && genMappingsStories.length === 0 && <Center><Text>No stories</Text></Center>}
                                                {genMappingsStories && genMappingsStories.length > 0 && <Box>
                                                    {genMappingsStories.map((story) => {
                                                        let isSelected = selectedGenMappingStoryID === story.id
                                                        return <Box key={story.id} p={2} my={2} borderRadius='md' cursor="pointer" backgroundColor={isSelected ? colorSelectedStory : colorUnselectedStory} onClick={() => setSelectedGenMappingStoryID(story.id)}>
                                                            <Text color={story.reviewStory ? 'green' : 'yellow'}>Title: {story.title}</Text>
                                                            <Text>Level: {story.level}</Text>
                                                        </Box>
                                                    }
                                                    )}
                                                </Box>}
                                            </Box>}

                                            {mode.id === "ADD_STORY" && <Box>
                                                <Text>Level</Text>
                                                <Select mt={2} onChange={(e) => setAddStorySelectedLevel(e.target.value)} value={addStorySelectedLevel}>
                                                    {AddStoryLevel.all().map((level) => {
                                                        return <option key={level.id} value={level.id}>{level.title}</option>
                                                    }
                                                    )}
                                                </Select>
                                                <Text mt={2}>Title</Text>
                                                <Input mt={2} placeholder={"Title in " + storyLanguage?.name} value={addStoryTitle} onChange={(e) => setAddStoryTitle(e.target.value)} />
                                            </Box>}

                                        </TabPanel>
                                    })}
                                </TabPanels>
                            </Tabs>
                        </Box>
                        <Divider h='100%' orientation="vertical" />
                        <Box flex="20" >
                            {/* ADD STORY */}
                            {mode === ManageStoryMode.ADD_STORY.id && <Box>
                                <Box ml={4} color='white' display='flex' flexDirection='column' w='95%'>
                                    <RadioGroup value={addStoryMode} onChange={(value) => setAddStoryMode(value)}>
                                        {allAddStoryModes.map((mode, index) => {
                                            return <Radio ml={index !== 0 ? 4 : 0} value={mode.id}>
                                                <Text color="white">{mode.title}</Text>
                                            </Radio>
                                        })}
                                    </RadioGroup>
                                    {addStoryMode === AddStoryMode.RAW_JSON.id && <Box mt={4}>
                                        <Text>JSON:</Text>
                                        <Textarea mt={2} rows={16} value={addStoryRawJSON} onChange={(e) => setAddStoryRawJSON(e.target.value)} />
                                        <Button mt={4} colorScheme="blue" isLoading={addStoryGeneratedIsSubmitting} onClick={() => { onSubmitGeneratedStoryRawJSON() }}>Submit</Button>
                                    </Box>}
                                    {addStoryMode === AddStoryMode.ADD_TEXT.id && <Box mt={4}>
                                        <Text>Plain text:</Text>
                                        <Textarea mt={2} rows={16} value={addStoryPlainText} onChange={(e) => setAddStoryPlainText(e.target.value)} />

                                        <Button mt={4} colorScheme="blue" isLoading={addStoryIsSubmitting} isDisabled={!addStoryCanSubmit} onClick={() => {
                                            onSubmitAddStoryPlainText()
                                        }
                                        }>Submit</Button>
                                    </Box>}
                                    {addStoryMode === AddStoryMode.GENERATE.id && <Box mt={4}>
                                        <Button isLoading={addStoryIsGenerating} colorScheme="blue" onClick={onClickAddStoryGenerate}>{addStoryGeneratedText ? "Regenerate" : "Generate"}</Button>
                                        {addStoryGeneratedText && <Box>
                                            <Textarea isDisabled={addStoryIsGenerating} mt={2} rows={16} value={addStoryGeneratedText} onChange={(e) => setAddStoryGeneratedText(e.target.value)} />
                                            <Button mt={4} colorScheme="blue" isLoading={addStoryGeneratedIsSubmitting} onClick={() => { onSubmitGeneratedStoryText() }}>
                                                Submit
                                            </Button>
                                        </Box>
                                        }

                                    </Box>}
                                    {addStoryMode === AddStoryMode.GENERATE_AND_SAVE_TO_DB.id && <Box mt={4}>
                                        <Button isLoading={addStoryIsGenerating} colorScheme="blue" onClick={onClickAddStoryGenerateAndSave}>Generate and Save</Button>
                                    </Box>}
                                </Box>
                            </Box>}
                            {/* GENERATE MAPPINGS */}
                            {mode === ManageStoryMode.GENERATE_MAPPINGS.id && selectedGenMappingStoryID && genMappingsStories && <Box data-test='main-box' h='100%' py={2}>
                                <Box ml={4} color='white' display='flex' flexDirection='column' w='95%'>
                                    {generateMappingsText && <Box>
                                        <Text>Mappings JSON:</Text>
                                        <Textarea mt={2} rows={16} value={generateMappingsText} onChange={(e) => setGenerateMappingsText(e.target.value)} />

                                        <Button mt={4} colorScheme="blue" isLoading={isSubmittingGenerateMappings} isDisabled={isDefined(generateMappingsError)} onClick={() => {
                                            onSubmitGenerateMappings()
                                        }
                                        }>Submit</Button>
                                        {generateMappingsError && <Text color='red'>{generateMappingsError}</Text>}
                                    </Box>}

                                    {!generateMappingsText && <Box>
                                        <Textarea mt={2} rows={16} value={selectedGenMappingPlainTextValue} onChange={(e) => {
                                            onSelectedStoryMappingsChanged(e.target.value)

                                        } 
                                        }/>
                                        <Box display="flex" width='100%' direction='column' justifyContent='space-between' mt={4}>
                                            <Button isDisabled={selectedMappingsError} isLoading={isGeneratingMappings} colorScheme="green" onClick={onClickEditStory}>Edit Story</Button>
                                            <Button isDisabled={selectedMappingsError} isLoading={isGeneratingMappings} colorScheme="blue" onClick={onClickGenerateMappings}>Generate Mappings</Button>
                                        </Box>
                                        { selectedMappingsError && <Text color='red'>{selectedMappingsError}</Text>}
                                    </Box>}
                                </Box>
                            </Box>
                            }
                            {/* REVIEW  */}
                            {mode === ManageStoryMode.REVIEW.id && selectedReviewStoryID && reviewStories && <Box data-test='main-box' h='100%' py={2}>
                                <Box display="flex" height="100%" >
                                    <Box flex="7" px="4" h='100%' overflow='scroll'>
                                        <Box w='100%' >
                                            <StoryDetails adminMode={groupMode} story={getSelectedStory()} onSelectMapping={onSelectMapping} hideBackToStories={true} />
                                        </Box>
                                    </Box>
                                    <Divider h='100%' opacity={0.2} orientation="vertical" />
                                    <Box flex="3" h='100%'>
                                        {getSelectedMapping()?.mapping?.text && <Box
                                            h='40px'
                                            direction='row'
                                            alignContent='center'
                                            borderRadius='md'
                                            backgroundColor='rgba(255, 255, 255, 0.1)'
                                            mx={2}
                                            px={2}
                                        >

                                            <Center>
                                                <Text fontSize='xl' fontWeight='bold' color='white'>{getSelectedMapping().mapping.text}</Text>
                                                {isLoadingSnippet && <Box ml={2}>
                                                    <Spinner color='gray' />

                                                </Box>}
                                                <IconButton
                                                    icon={<PlusSquareIcon />}
                                                    color='#42F57B'
                                                    backgroundColor="none"
                                                    colorScheme="none"
                                                    zIndex="1"
                                                    top={0}
                                                    height="100%"
                                                    _hover={{ bg: "lightPurple" }}
                                                    data-test='add-snippet-button'
                                                    onClick={onClickAddSnippet}
                                                ></IconButton>
                                            </Center>
                                        </Box>}
                                        {/* Content for the 30% width panel */}
                                        <Box overflowY="scroll" h='calc(100% - 36px)'>
                                            {resultSnippets && <DictionarySearchResults
                                                onEditSnippet={(snippetID) => { onEditSnippet(snippetID) }}
                                                onDeleteSnippet={(snippetID) => { onDeleteSnippetClicked(snippetID) }}
                                                canPinSnippet={false}
                                                highlightedText={getSelectedMapping()?.mapping?.text?.toLowerCase()}
                                                results={resultSnippets}
                                                classroomID={null}
                                                showsCreateButton={false}
                                            />}
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                <Footer />

            </Box>
            <DeleteConfirmation
                isOpen={deletingSnippetID}
                isDeleting={isDeletingSnippet}
                onClose={() => {
                    setDeletingSnippetID(null);
                }}
                onConfirm={() => {
                    doDeleteSelectedSnippet()
                }}
                title="Delete Snippet"
                text="Are you sure you want to delete this snippet? This action cannot be undone."
            />

            <UploadImageModal
                isOpen={isUploadReviewImageOpen}
                onClose={onUploadReviewImageClose}
                onUploadImage={(base64Image) => {
                    return onUploadReviewImage(base64Image)
                }}
                onImageUploaded={(imagePath) => {
                    console.log('Image uploaded! ', imagePath)
                    let story = getSelectedStory()
                    if (story) {
                        let newStory = copyJSONObject(story)
                        newStory.imageURLPath = imagePath
                        updateStory(newStory)

                        console.log('Setting new story image URL to ' + imagePath)
                    } else {
                        console.error('Story not found when image uploaded')
                    }
                }}
                imageURL={selectedReviewStoryImageURL}
            />

            <AlertDialog
                isOpen={isPublishOpen}
                onClose={onPublishClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Publish Story
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button isDisabled={isPublishing} onClick={onPublishClose}>
                                Cancel
                            </Button>
                            <Button isLoading={isPublishing} colorScheme='red' onClick={doPublishStory} ml={3}>
                                Publish
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default AdminManageStories;


import { Box, Text, Image } from "@chakra-ui/react";
import { FaApple } from "react-icons/fa";
import CustomButton from "./common/LandingButton";
import RetinaImage from "../retina-image";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const LandingFooter = ({ imageSuffix }) => {
  const [suffix, setSuffix] = useState(imageSuffix);

  const { t } = useTranslation()

  useEffect(() => {
    setSuffix(imageSuffix);
  }, [imageSuffix]);

 
  return (<Box
    py={8}
    textAlign="center"
    color="white"
    bgAttachment="fixed"
    bgImage="url(/icons/landing/background_layer.png)"
    fontFamily="Quicksand, sans-serif"
    position="relative"
  >
    {/* Logo and Glow Wrapper */}
    <Box
      position="absolute"
      top="-32%" // Position glow partially inside the footer
      left="50%" // Center horizontally in the footer
      transform="translate(-50%, 0)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="280px"
      height="280px"
      zIndex="10"
      pointerEvents="none"
    >
      {/* Glow Image */}
      <RetinaImage
        src="/icons/landing/footer_logo_glow.png"
        alt="Logo Glow"
        position="absolute"
        height="100%"
        width="100%"
        pointerEvents="none"
        zIndex="1"
      />

      {/* Logo Image */}
      <RetinaImage
        // src={`/icons/landing/footer_logo_without_glow${imageSuffix}.png`}
        src={`/icons/landing/footer_logo_without_glow.png`}
        alt="Footer Logo"
        top="50%"
        left="50%"
        width="50%"
        zIndex="5"
      />
    </Box>

    {/* Spacer to create space between logo and text */}
    <Box height="70px" /> {/* Adjust this height as needed to add space below logo */}
    {/* Footer Content */}
    <Text fontSize="4xl" fontWeight="bold" mb="4">
      {t("landing.footer_title")}
    </Text>
    <CustomButton
      mainText={t("landing.footer_cta")}
      bgColor="#7F5BD4"
      iconSize="40px"
    />
    <Box mt="4">
      <CustomButton
        icon={() => <FaApple size={30} />}
        topText={t("landing.download_on_app_store")}
        mainText="App Store"
        bgColor="transparent"
        iconSize="40px"
        mx="1"
        onClick={(e) => {
          
        }}
      />
      <CustomButton
        icon={() => <Image src="/icons/landing/android.png" />}
        topText={t("landing.download_on_google_play")}
        mainText="Google Play"
        bgColor="transparent"
        iconSize="40px"
        mx="1"
      />
    </Box>
  </Box>);
};

export default LandingFooter;

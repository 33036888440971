import {
  HStack,
  Select,
  Text,
  Button,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Image,
  Icon,
  useMediaQuery,
  Center,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { userLanguageState } from "../state/user-state";
import { LANGUAGES, getLanguageWithCode } from "../utils/languages";
import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";
import { isDefined } from "../utils/utils";
import { Constants } from "../utils/constants";
import RetinaImage from "./retina-image";

function ChooseYourLanguage({ isVertical, title, ...props }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [customTitle, setCustomTitle] = useState(title);
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);

  const {
    userLanguage,
    setUserLanguage,
    authenticationFinished,
    isInitiallyLoggedIn,
  } = useUser()

  const [alignVertically, setAlignVertically] = useState(isVertical ?? false);

  const { t } = useTranslation();

  useEffect(() => {
    setAlignVertically(isVertical);
  }, [isVertical]);

  useEffect(() => {
    setCustomTitle(title)
  }, [title])

  const handleChangeLanguage = (languageCode) => {
    setUserLanguage(getLanguageWithCode(languageCode));
  };

  const renderTitle = () => {
    if (isMobile) {
      return null;
    }

    let title = isDefined(customTitle) ? customTitle : t("sentences.your_language");
    return (
      <Text color="white" whiteSpace="nowrap">
        {title}
      </Text>
    );
  };

  const renderSelect = () => {
    return (
      <Menu
        data-test="language-menu"
        isOpen={isMenuOpen}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuButton
          data-test="language-menu-button"
          as={Button}
          variant= {isMobile ? 'unstyled' : 'outline'}
          _hover={{ bg: "blue.800" }}
          _active={{ bg: "transparent" }}
          color="white"
        >
          {isMobile && <Center>
            <RetinaImage
              data-test='flag-image'
              src={`/icons/m-flag-${userLanguage?.code}.png`}
              alt={userLanguage?.code}
              width="36px"
              height="36px"
            />
          </Center>
          }
          {!isMobile && <Box as="span" display="flex" alignItems="center" data-test='content-box'>
            <Image
              data-test='flag-image'
              src={`/icons/flag-${userLanguage?.code}.png`}
              alt={userLanguage?.code}
              width="20px"
              height="20px"
            />
            <Text data-test='lang-text' ml={2}>{userLanguage?.name || "Select Language"}</Text>
            <Icon data-test='angle-icon' as={isMenuOpen ? FaAngleUp : FaAngleDown} ml={2} />
          </Box>}
        </MenuButton>
        <MenuList data-test='menu-list'>
          {LANGUAGES.map((lang) => (
            <MenuItem
              data-test={`menu-item_${lang.code}`}
              key={lang.code}
              onClick={() => handleChangeLanguage(lang.code)}
              color="black"
            >
              <Box as="span" display="flex" alignItems="center">
                <Image
                  src={`/icons/flag-${lang.code}.png`}
                  alt={lang.code}
                  width="20px"
                  height="20px"
                />
                <Text data_test={`menu-item-text_${lang.code}`} ml={2}>{lang.name}</Text>
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };
  return (
    <Box {...props} >
      {t && authenticationFinished && userLanguage && <> {alignVertically ? (
        <VStack alignItems="flex-start" justifyItems="flex-start">
          {renderTitle()}
          {renderSelect()}
        </VStack>
      ) : (
        <HStack spacing={2} align="center">
          {" "}
          {/* Align items to the center of the HStack */}
          {renderTitle()}
          {renderSelect()}
        </HStack>
      )}
      </>}
    </Box>
  );
}

export default ChooseYourLanguage;

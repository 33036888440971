import React from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

export const userLanguageState = atom({
  key: 'userLanguageState',
  default: null,
});

export const learningLanguageState = atom({
  key: 'learningLanguageState',
  default: null,
});

export const userState = atom({ // firebase user state
  key: 'userState',
  default: null,
});

export const isCreatingAccountState = atom({
  key: 'isCreatingAccountState',
  default: false,
});

export const isAuthenticationFinishedState = atom({
  key: 'isAuthenticationFinishedState',
  default: false,
});
import { Box, SimpleGrid, Text, Image, Flex, Heading, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../utils/constants";

const features = [
    { src: "/icons/landing/write.png", key: "write" },
    { src: "/icons/landing/read.png", key: "read" },
    { src: "/icons/landing/listen.png", key: "listen" }
];

const textColor = "#EBE0FF"

const LandingFeatures = () => {
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const { t } = useTranslation();
    
    return <Box py="6" width={"100%"} textAlign="center" color="white" mx='0' fontFamily="Quicksand, sans-serif">
        <Heading fontWeight="bold" mb="10" >
            {t("landing.features_title")}
        </Heading>
        { !isMobile && <SimpleGrid columns={[1, 2, 3]} spacing="8" mx='0'>
            {features.map((feature, index) => (
                <Flex key={index} direction="column" align="center" textAlign="center">
                    <Image style={{ userSelect: "none" }} src={feature.src} alt={t(`landing.features_${feature.key}_title`)} boxSize="12" mb="4" />
                    <Text color={textColor} fontSize="3xl" fontWeight="bold">{t(`landing.features_${feature.key}_title`)}</Text>
                    <Text mt="2">{t(`landing.features_${feature.key}_description`)}</Text>
                </Flex>
            ))}
        </SimpleGrid> }
        { isMobile && <Box w='85%' ml='7.5%'>
            {features.map((feature, index) => (
                <Flex key={index} direction="column" align="center" textAlign="center" mb={index !== 2 ? 12 : 4}>
                    <Image style={{ userSelect: "none" }} src={feature.src} alt={t(`landing.features_${feature.key}_title`)} boxSize="12" mb="2" />
                    <Text color={textColor} fontSize="3xl" fontWeight="bold">{t(`landing.features_${feature.key}_title`)}</Text>
                    <Text mt="2">{t(`landing.features_${feature.key}_description`)}</Text>
                </Flex>
            ))}
        </Box> }
    </Box>
};

export default LandingFeatures;

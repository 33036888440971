import React from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { Constants } from '../../utils/constants';
import { randomID } from '../../utils/utils';

export const manageStoriesReviewSelectedStoryState = atom({
    key: 'manageStoriesReviewSelectedStoryState',
    default: null,
});

export const manageStoriesReviewSnippetModalSelectedMappingState = atom({
    key: 'manageStoriesReviewSnippetModalSelectedMappingState',
    default: null,
});

export const manageStoriesListStoriesState = atom({
  key: 'manageStoriesListStoriesState',
  default: null
});

export const manageStoriesSelectedMappingIDState = atom({
    key: 'manageStoriesSelectedMappingIDState',
    default: false,
});

export const manageStoriesSelectedStoryIDState = atom({
    key: 'manageStoriesSelectedStoryIDState',
    default: null,
});

export const manageStoriesStoriesToUpdateState = atom({
    key: 'manageStoriesStoriesToUpdateState',
    default: false,
});
import React, { useEffect, useState } from "react";
import {
  HStack,
  Text,
  Select,
  Box,
  Button,
  Textarea,
  Spacer,
  IconButton,
  Image,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import { DeleteIcon, RepeatIcon } from "@chakra-ui/icons";
import { getLanguageWithCode, LANGUAGES } from "../../utils/languages"; // Assuming these utility functions are in the specified directory
import { randomID } from "../../utils/utils";
import { useRecoilState } from "recoil";

import {
  classroomIDState,
  classroomLanguageState,
  classroomRoleState,
} from "../../state/classroom-state";
import { useTranslation } from "react-i18next";
import {
  learningLanguageState,
  userLanguageState,
  userState,
} from "../../state/user-state";
import CopyToClipboardButton from "../copy-button";
import SpeechButton from "../speech-button";
import { translateText } from "../../api/translation.service";
import { ClassroomRoleEnum } from "../classrooms/classroom-role";
import { Constants } from "../../utils/constants";
import { createSnippetDictionary } from "../../utils/snippet-utils";
import {
  showAddEditSnippetModal,
  showSnippetsModalState,
} from "../../state/snippets-state";
import { AddEditSnippetModalType } from "../../snippets/snippet-modal";

function TranslateWord({ propsId }) {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);

  const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
  
  const [learningLanguage, setLearningLanguage] = useRecoilState(
    learningLanguageState
  );
  const [classroomLanguage, setClassroomLanguage] = useRecoilState(
    classroomLanguageState
  );

  const [sourceLang, setSourceLang] = useState("en");
  const [targetLang, setTargetLang] = useState("es");
  const [sourceText, setSourceText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [isTranslating, setTranslating] = useState(false);
  
  const [classroomID, setClassroomID] = useRecoilState(classroomIDState);
  const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);
  const [isShowingSnippetsModal, setShowingSnippetsModal] = useRecoilState(
    showSnippetsModalState
  );
  const [user, setUser] = useRecoilState(userState);
  const translateRowsCount = 3;

  const { t } = useTranslation();

  function swapLanguages() {
    const prevSourceLang = sourceLang;
    setSourceText(translatedText);
    setTranslatedText(sourceText);

    setSourceLang(targetLang);
    setTargetLang(prevSourceLang);
  }

  function clearTranslations() {
    setSourceText("");
    setTranslatedText("");
  }

  function onSelectSourceLanguage(language) {
    if(language === targetLang) {
      swapLanguages()
    } else {
      setSourceLang(language)
    }
  }

  function onSelectTargetLanguage(language) {
    if(language === sourceLang) {
      swapLanguages()
    } else {
      setTargetLang(language)
    }
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        setTranslating(true);
        const translation = await translateText(
          sourceText,
          sourceLang,
          targetLang
        );
        setTranslatedText(translation);
      } catch (error) {
        console.error("Error translating text:", error);
      } finally {
        setTranslating(false);
      }
    }, 500);

    // Clear the timer when the effect is cleaned up
    return () => clearTimeout(timer);
  }, [sourceText, sourceLang, targetLang]);

  function getLanguage(code) {
    for (let lang of LANGUAGES) {
      if (lang.code === code) {
        return lang;
      }
    }
    return null;
  }

  function createSnippetFromTranslation() {
    let term = null;
    let translation = null;
    if (userLanguage.code === sourceLang) {
      term = translatedText;
      translation = sourceText;
    } else if (userLanguage.code === targetLang) {
      term = sourceText;
      translation = translatedText;
    } else {
      if (classroomLanguage) {
        if (classroomLanguage.code === sourceLang) {
          term = sourceText;
          translation = translatedText;
        } else if (classroomLanguage.code === targetLang) {
          term = translatedText;
          translation = sourceText;
        }
      }

      if ((!term || !translation) && learningLanguage) {
        if (learningLanguage.code === sourceLang) {
          term = sourceText;
          translation = translatedText;
        } else if (learningLanguage.code === targetLang) {
          term = translatedText;
          translation = sourceText;
        }
      }
    }

    if (!term || !translation) {
      term = translatedText;
      translation = sourceText;
    }

    let cid = null
    if (classroomRole !== ClassroomRoleEnum.STUDENT) {
      cid = classroomID
    }

    showAddEditSnippetModal(
      setShowingSnippetsModal, 
      null,
      null,
      t("sentences.edit_snippet_title"),
      cid,
      null,
      null,
      term,
      translation,
      null,
      null,
      AddEditSnippetModalType.CLASSROOM_FALLBACK_TO_USER(cid)
    )
  }

  return (
    <Box
      w="100%"
      mt={isMobile ? 2 : 0}
      borderWidth={isMobile ? "0px" : "1px"}
      borderRadius={4}
      id={propsId}
      data-test="translate-word-panel"
    >
      {!isMobile && (
        <Box
          mb={1}
          padding={2}
          borderBottomWidth="1px"
          h="45px"
          backgroundColor="darkPurple"
          borderTopRadius="md"
        >
          <Text data-test='translate-title' ml={3} fontSize="lg" color="white">
            {t("translate.translate_title")}
          </Text>
        </Box>
      )}
      <Box ml={1} mr={1}>
        <HStack mb={2}>
          <Select
            data-test='select-source-lang'
            color="white"
            value={sourceLang}
            onChange={(e) => onSelectSourceLanguage(e.target.value)}
          >
            {LANGUAGES.map((lang) => (
              <option data-test={`source_option_${lang.code}`} key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </Select>
          <Button data-test='button-swap-languages' onClick={swapLanguages}>
            <RepeatIcon />
          </Button>

          <Select
            data-test='select-target-lang'
            color="white"
            value={targetLang}
            onChange={(e) => onSelectTargetLanguage(e.target.value)}
          >
            {LANGUAGES.map((lang) => (
              <option data-test={`target_option_${lang.code}`} key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </Select>
        </HStack>
        <Textarea
          rows={translateRowsCount}
          data-test='source-textarea'
          color="white"
          mb={2}
          placeholder={getLanguage(sourceLang).name}
          value={sourceText}
          onChange={(e) => setSourceText(e.target.value)}
          resize="none"
        />
        <Box overflow="hidden">
          <Textarea
            rows={translateRowsCount}
            color="white"
            data-test='translation-textarea'
            mb={0} // remove margin
            placeholder={getLanguage(targetLang).name}
            value={translatedText}
            resize="none"
          />

          {isTranslating && (
            <Spinner
              data-test='translate-spinner'
              color="white"
              position="absolute"
              bottom="8px"
              right="6px"
            />
          )}
        </Box>
        <HStack
          spacing={2}
          px={2}
          py={1}
          bg="darkPurple" // You can adjust this color according to your needs
        >
          <CopyToClipboardButton text={translatedText} />
          <Spacer />
          <SpeechButton text={translatedText} languageCode={targetLang} />

          <Button
            colorScheme="green"
            variant="solid"
            onClick={createSnippetFromTranslation}
            data-test='create-snippet-button'
            isDisabled={translatedText.trim().length === 0}
          >
            {t("sentences.create_snippet")}
          </Button>
          <IconButton
            opacity={0.01}
            width="20px"
            height="20px"
            colorScheme="clear"
            icon={
              <Image
                width="20px"
                height="20px"
                style={{ filter: "invert(100%)" }}
                colorScheme="white"
                src="/icons/speak.png"
              />
            }
          ></IconButton>
          <Spacer />
          <IconButton
            data-test='clear-button'
            icon={<DeleteIcon color="customRed" opacity="0.7" />}
            onClick={clearTranslations}
            isDisabled={translatedText.trim().length === 0}
            variant="ghost"
            aria-label="Clear"
            _hover={{ bg: "darkPurpleHover" }} // Adjust the hover color accordingly
            zIndex="1"
          />
        </HStack>
      </Box>
    </Box>
  );
}

export default TranslateWord;

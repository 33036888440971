import {
  Box,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function MenuComboBox({
  preSelectedItem,
  value,
  onChangeItem,
  backgroundColor,
  children,
  icon,
  ...props
}) {
  const [backgroundColorState, setBackgroundColorState] = useState("midPurple");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(preSelectedItem);
  const [text, setText] = useState("");
  const buttonRef = useRef(null); // Reference to the button to determine its position

  useEffect(() => {
    setText(value);
  }, [value]);

  useEffect(() => {
    setBackgroundColorState(backgroundColor || "midPurple");
  }, [backgroundColor]);

  const handleItemClick = (index, text) => {
    onChangeItem?.(index, text);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <Box {...props}>
      <Button
        ref={buttonRef}
        variant="outline"
        onClick={toggleMenu}
        _hover={{ bg: "blue.800" }}
        _active={{ bg: "transparent" }}
        color="white"
        textAlign="left"
        justifyContent="flex-start"
      >
        <Box display="flex" alignItems="center">
          {icon && <Icon as={icon} mr={2} />}
          <Text ml={2}>{text || "Select Item"}</Text>
          <Icon as={isMenuOpen ? FaAngleUp : FaAngleDown} ml={2} />
        </Box>
      </Button>

      <Modal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} isCentered={false}>
        <ModalOverlay bg="transparent" />
        <ModalContent
          maxWidth="300px"
          borderRadius="md"
          borderColor='white'
          borderWidth={0.1}
          overflow="hidden"
          position="absolute"
          top={buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + window.scrollY : 0}
          left={buttonRef.current ? buttonRef.current.getBoundingClientRect().left + window.scrollX : 0}
          mt={1} // Small gap between button and dropdown
          shadow="lg"
        >
          <ModalBody p={0} bg={backgroundColorState}>
            {children && children.length > 0 ? (
              children.map((child, index) =>
                React.cloneElement(child, {
                  onClick: () => handleItemClick(index, child.props.text),
                })
              )
            ) : (
              <Text p={4} color="gray.500" textAlign="center">
                No items available
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MenuComboBox;

import { Button, Stack, Text, VStack } from '@chakra-ui/react';
import { FaApple } from 'react-icons/fa';

const LandingButton = ({ icon, topText, mainText, bgColor, iconSize = "24px", mx, onClick }) => {
    return (
        <Button
            leftIcon={icon && icon({ boxSize: iconSize })}
            paddingX="6"
            paddingY="6"
            fontSize="md"
            borderRadius="full"
            bg={bgColor || "blackAlpha.900"}
            color="white"
            border="1px solid"
            borderColor="#A181EE"
            mx={mx ? mx : '0px'}
            my="2"
            
            _hover={{
                color: 'purple',
                backgroundColor:'white'
            }}
            onClick={onClick}
        >
            <VStack spacing="0" align="start" py="20px">
                {topText && (
                    <Text fontSize="xs" fontWeight="normal">
                        {topText}
                    </Text>
                )}
                <Text fontSize="lg" fontWeight="bold">
                    {mainText}
                </Text>
            </VStack>
        </Button>
    );
};

export default LandingButton;

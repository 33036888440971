import React, { useEffect, useState } from "react";
import { Box, Link, Text, Center, Flex, Grid, GridItem, Image, Divider, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Constants } from "../utils/constants";

function Footer({ backgroundColor, linkSpacing = 3 }) {
  const [customBackgroundColor, setCustomBackgroundColor] = useState(backgroundColor);
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
  
  const links = [
    {
      title: "footer.privacy_policy",
      href: "/privacy-policy",
    },
    {
      title: "footer.terms_of_use",
      href: "/terms",
    },
    {
      title: "footer.contact_us",
      href: "mailto: hello@learner.app",
    },
  ]

  useEffect(() => {
    setCustomBackgroundColor(backgroundColor);
  }, [backgroundColor]);

  return (
    <Box
      as="footer"
      id="external-footer"
      bg={ customBackgroundColor ?? "darkPurple"}
      color="white"
      p={1}
      height="64px"
    >
      <Center>
        <Flex mt={isMobile ? 3 : 1}>
          <Link
            href="https://www.facebook.com/people/Learnerapp/61559237951085/"
            isExternal
            _hover={{ textDecoration: "underline" }}
            mr={4}
          >
            <Flex align="center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                fill="#FFFFFF"
              >
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
              </svg>
              <Text fontSize="sm" ml={2}>
                Facebook
              </Text>
            </Flex>
          </Link>
          <Link
            href="https://www.instagram.com/learner.app/"
            isExternal
            _hover={{ textDecoration: "underline" }}
          >
            <Flex align="center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                fill="#FFFFFF"
              >
                <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
              </svg>
              <Text fontSize="sm" ml={2}>
                Instagram
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Center>
      <Grid
        templateColumns={`repeat(${isMobile ? 1 : 3}, 1fr)`}
        mt={isMobile ? 3 : 1.5}
      >
        <GridItem colSpan={1}>
      
        </GridItem>
        <GridItem colSpan={1}>
          <Center>
            {links.map((link, index) => (
              <Link
                key={index}
                fontSize="sm"
                href={link.href}
                ml={index > 0 ? linkSpacing : 0}
                isExternal
                _hover={{ textDecoration: "underline" }}
              >
                {t(link.title)}
              </Link>
            ))}
           
          </Center>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontSize="xs"  mr={1} my={isMobile ? 2 : 0 } flex="1" textAlign={isMobile ? "center" : "right" }>
            {t("footer.copyright")} © {new Date().getFullYear()} Learner.app. {t("footer.all_rights_reserved")}.
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Footer;

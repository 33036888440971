import { atom } from "recoil";

export const exampleClassroomState = atom({
    key: 'exampleClassroomState',
    default: null,
});

export const exampleClassroomSnippetAddedEvent = atom({
    key: 'exampleClassroomSnippetAddedEvent',
    default: null,
});


import { serverTimestamp } from "@firebase/database"
import { SharedConstants } from "../shared/shared"
import { localizeInflection } from "./snippet-localization"
import { copyJSONObject } from "./utils"
import i18next from "i18next"

function mergeWordTypesIntoOneDictionary(etymologies) {
    let wordTypeMap = {} // merge all wordTypes into one dictionary
    const inflectionsKey = 'inflections'
    for (let etymologyKey in etymologies) {
        let etymology = etymologies[etymologyKey]
        let wordTypes = etymology.wordTypes

        // merge all word types into one dictionary, e.g.
        // { "Noun": [ { wordType: "Noun", definitions: [ { text: "sleeve" }, { text: "set" } ] } ] }
        // { "Verb": [ { wordType: "Verb", definitions: [ { text: "to stir, to mix" }, { text: "to jumble up, to disarrange" } ] } ] }
        // etc
        for (let wordTypeDictionary of wordTypes) {
            let wordType = wordTypeDictionary.wordType
            if (!wordType) {
                continue
            }

            let wordTypeResults = wordTypeMap[wordType] ?? []
            if (wordTypeDictionary.inflections) {
                wordTypeResults = wordTypeMap[inflectionsKey] ?? []
                wordTypeResults.push(wordTypeDictionary)
                wordTypeMap[inflectionsKey] = wordTypeResults
            } else {
                wordTypeResults.push(wordTypeDictionary)
                wordTypeMap[wordType] = wordTypeResults
            }
        }
    }

    return wordTypeMap
}

// export function convertSnippetObjectToUserSnippet(snippet) {
    
//     let userSnippet = {
//         term: snippet.term,
//         wordType: snippet.wordType,
//         customDefinition: snippet.customDefinition,
//         plainTextTranslation: snippet.plainTextTranslation,
//         selectedDefinition: snippet.selectedDefinition,
//         createdAt: snippet.createdAt,
//         lastUpdatedAt: serverTimestamp(),
//         link: snippet.link
//     }

//     return userSnippet
// }

export function convertWiktionaryFetchResultToSnippetsArray(wiktionaryFetchResult, translationObject, languageCode) {
    console.log('convertWiktionaryFetchResultToSnippetsArray ' + JSON.stringify(wiktionaryFetchResult))
    let term = wiktionaryFetchResult.term
    let etymologies = wiktionaryFetchResult.etymologies ?? {}
    let result = []

    let wordTypeMap = mergeWordTypesIntoOneDictionary(etymologies)

    // merge all definitions into one array
    let conjugation = null
    let allInflections = []
    for (let wordType in wordTypeMap) {
        let wordTypeResults = wordTypeMap[wordType]
        let definitions = []

        for (let wordTypeResult of wordTypeResults) {
            let wordTypeDefinitions = wordTypeResult.definitions ?? {}

            if (wordTypeResult.conjugation) {
                conjugation = wordTypeResult.conjugation
            }

            for (let definitionKey in wordTypeDefinitions) {
                let definition = wordTypeDefinitions[definitionKey]
                let definitionObj = { ...definition }
                definitionObj.id = definitionKey
                definitions.push(definitionObj)
            }

            if (wordTypeResult.inflections) {
                allInflections = allInflections.concat(wordTypeResult.inflections)
            }
        }

        result.push({
            wordType: wordType,
            definitions: definitions,
            conjugation: conjugation,
            inflections: allInflections
        })
    }

    let allSnippets = []
    let promises = []
    for (let item of result) {
        // createSnippetsFromWiktionaryResult returns an array of snippets
        let snippetPromise = mergeSnippetAndWiktionaryResultIntoSnippet(randomID(), term, item, languageCode).then(snippets => {
            if (snippets) {
                allSnippets = allSnippets.concat(snippets)
            }
        })

        promises.push(snippetPromise)
    }

    return Promise.all(promises).then(() => {
        return allSnippets
    })
}

// creates a snippet from userSnippet object and wiktionary entry to which it links
export async function mergeSnippetAndWiktionaryResultIntoSnippet(id, userSnippet, wiktionaryEntry, learningLanguageCode) {
    
    console.log(`pppassed id ${id} userSnippet ${JSON.stringify(userSnippet)} wiktionaryEntry ${JSON.stringify(wiktionaryEntry)} languageCode ${learningLanguageCode}`)
    // let snippets = []
    let wordType = wiktionaryEntry?.wordType
    let definitions = wiktionaryEntry?.definitions
    let linkTerm = userSnippet.link?.accentedTerm ?? userSnippet.link?.term
    let term = linkTerm ?? userSnippet.term
    let customDefinition = userSnippet.customDefinition

    // export async function createe() {
    //     let items = []
    //        if(wiktionaryEntry.conjugation) {
    //            console.log('adding conjugation for ' + term)
    //            addSnippetsConjugationTable(items, wiktionaryEntry.conjugation, languageCode)
    //        }
       
    //        let snippet = createSnippet(id, term, wordType, userSnippet.plainTextTranslation, userSnippet.selectedDefinition, definitions, items, userSnippet.createdAt)
       
    //        if(wiktionaryEntry.conjugation) {
    //            snippet.collapsesSnippet = true
    //        } 
       
    //        return snippet
    //    }

   let snippet = createSnippet(id, wordType, definitions, customDefinition, term, wiktionaryEntry.conjugation, wiktionaryEntry.declension, userSnippet.plainTextTranslation, userSnippet.selectedDefinition, userSnippet.createdAt, userSnippet.pinned_at, learningLanguageCode)

   snippet.selectedSections = userSnippet.selectedSections
   console.log(term + ' Snippet selectedSections is ' + JSON.stringify(snippet.selectedSections))
   snippet.userSnippet = copyJSONObject(userSnippet)
   snippet.userSnippet.id = id
   snippet.wiktionaryEntry = copyJSONObject(wiktionaryEntry)
   snippet.definitionsLanguageCode = userSnippet.definitionsLanguageCode
   
   console.log('Created snippet ' + JSON.stringify(snippet))

   return snippet
}

export async function createInflectionSnippet(id, inflectionEntry, userLanguageCode) {
    console.log('createInflectionSnippet ' + JSON.stringify(inflectionEntry))
    let snippet = {
        id,
        inflectionEntry,
        customSnippetText: await localizeInflection(inflectionEntry, userLanguageCode)
    }
    return snippet
}

export function createSnippet(id, wordType, definitions, customDefinition, term, conjugation, declension, plainTextTranslation, selectedDefinition, createdAt, pinned_at, learningLanguageCode, shouldSelectFirstDefinition = false) {
    let items = []
    if(conjugation) {
        console.log('adding conjugation for ' + term)
        addSnippetsConjugationTable(items, conjugation, learningLanguageCode)
    }

    if(declension) {
        console.log('adding declension for ' + term)
        addSnippetsDeclensionTable(items, declension, learningLanguageCode)
        console.log('after add declension ' + JSON.stringify(items))
    }

    let snippet = createSnippetObject(id, term, wordType, plainTextTranslation, selectedDefinition, definitions, customDefinition, items, createdAt, pinned_at)

    if(conjugation || declension) {
        snippet.collapsesSnippet = true
    } 

    if(shouldSelectFirstDefinition) {
        selectFirstDefinition(snippet, learningLanguageCode)
    }

    return snippet
}

function selectFirstDefinition(snippet, languageCode) {
    let definitionsArray = convertDefinitionsDictionaryToArray(snippet.definitions)
    
    if(definitionsArray.length > 0 && definitionsArray[0].id && languageCode) {
        snippet.selectedDefinition = {
            id: definitionsArray[0].id,
            languageCode: languageCode
        }
    }
}

function createSnippetObject(id, term, wordType, plainTextTranslation, selectedDefinition, definitions, customDefinition, items, createdAt, pinned_at) {
    let obj = {
        id,
        key: id,
        wordType,
        pinned_at,
        term,
        plainTextTranslation,
        selectedDefinition,
        definitions,
        customDefinition,
        items,
        createdAt
    }

    console.log('Creating snippet ' + JSON.stringify(obj))

    return obj
}

function createSnippetHeaderWithText(plainText) {
    return {
        id: randomID(),
        type: "text",
        plainText,
        collapsable: false,
        collapsesSnippet: true
    }
}

export function convertDefinitionsDictionaryToArray(definitions) {
    if(!definitions) {
        return []
    }
    for(let definitionKey in definitions) {
        let definition = definitions[definitionKey]
        definition.id = definitionKey
    }

    let definitionsArray = Object.values(definitions)
    definitionsArray.sort((a, b) => {
        return a.index - b.index
    })

    return definitionsArray
}

function createSnippetHeaderWithMultipleDefinitions(term, definitions, plainTextTerm, plainTextTranslation, selectedDefinitionID) {
    console.log('createSnippetHeader ' + JSON.stringify(definitions))
    
    // for(let definitionKey in definitions) {
    //     let definition = definitions[definitionKey]
    //     definition.id = definitionKey
    // }

    // let definitionsArray = Object.values(definitions)
    // definitionsArray.sort((a, b) => {
    //     return a.index - b.index
    // })

    let definitionsArray = convertDefinitionsDictionaryToArray(definitions)

    console.log('definitionsArray ' + definitionsArray)

    let firstDefinitionDictionary = definitionsArray[0]?.text
    let firstDefinition = firstDefinitionDictionary?.text ?? firstDefinitionDictionary?.raw

    let parsedDefinitions = []
    for (let definition of definitionsArray) {
        let textDictionary = definition
        if (!textDictionary) {
            continue
        }
        if (textDictionary.text) {
            parsedDefinitions.push(textDictionary.text)
        } else if (textDictionary.raw) {
            parsedDefinitions.push(textDictionary.raw)
        }
    }
    console.log('edited definitions ' + parsedDefinitions)

    let header = {
        id: randomID(),
        type: "text",
        term,
        translation: firstDefinition,
        definitions: parsedDefinitions,
        collapsable: false,
        collapsesSnippet: true
    }
    return header
}

function addSnippetsTextFieldText(items, text, collapsable) {
    items.push({ id: randomID(), type: 'text', text: text, collapsable })
}

function addSnippetsDeclensionTable(items, declensionTable, languageCode) {
    console.log(`addSnippetsDeclensionTable ${JSON.stringify(declensionTable)} languageCode ${languageCode}`)
    switch (languageCode) {
        case 'ru':
            addRussianSnippetsDeclensionTable(items, declensionTable)
            break;
        default:
            console.log('Declension table not supported for language ' + languageCode)
            break
    }
}

function addRussianSnippetsDeclensionTable(items, declensionTable) {
    if (!declensionTable) {
        return
    }

    let dict = { id: randomID(), type: 'table' }

    let sections = []

    let sectionDict = {}
    sectionDict.id = 'header-singular-plural'
    sectionDict.collapsable = false
    
    sectionDict.rows = []
    let rowCells = []
    rowCells.push({ type: 'empty'})
    rowCells.push({ type: 'header', readonly: true, text: 'singular', localize: true })
    rowCells.push({ type: 'header', readonly: true, text: 'plural', localize: true })
    sectionDict.rows.push(rowCells)
    sections.push(sectionDict)
    // convert sections to desired format
    for (let idx in SharedConstants.Dictionary.NounCase.Russian) {
        let dict = SharedConstants.Dictionary.NounCase.Russian[idx]
        
        let key = dict.key
        
        let sectionName = key

        if(!sectionName) {
            continue
        }

        let section = declensionTable[key]

        if (section) {
            let sectionDict = {}
            sectionDict.id = key

            sectionDict.rows = []

            let rowCells = []
            rowCells.push({ type: 'header', readonly: true, text: `lang.case_${sectionName}`, localize: true })

            let singularValue = section.singular || '/'
            let pluralValue = section.plural || '/'
            rowCells.push({ type: 'text', readonly: true, text: singularValue, localize: false })
            rowCells.push({ type: 'text', readonly: true, text: pluralValue, localize: false })
            sectionDict.rows.push(rowCells)

            sections.push(sectionDict)
        }
    }
    dict.sections = sections

    items.push(dict)
}          

function addSnippetsConjugationTable(items, tableDict, languageCode) {
    switch (languageCode) {
        case 'es':
            addSpanishSnippetsConjugationTable(items, tableDict)
            break;
        case 'en':
            addEnglishSnippetsConjugationTable(items, tableDict)
            break;
        case 'ru':
            addRussianSnippetsConjugationTable(items, tableDict)
            break;
        default:
            console.log('Conjugation table not supported for language ' + languageCode)
            break
    }
}

async function addRussianSnippetsConjugationTable(items, conjugationTable) {
    
    if (!conjugationTable) {
        return
    }

    let dict = { id: randomID(), type: 'table' }

    let sections = []

    // convert sections to desired format
    for (let idx in SharedConstants.Dictionary.Tense.Russian) {
        let val = SharedConstants.Dictionary.Tense.Russian[idx]
        let sectionValue = val.value
        let key = val.key
        
        let sectionName = null

        if(typeof sectionValue === 'string') {
            sectionName = sectionValue
        } else if (typeof sectionValue === 'object') {
            sectionName = sectionValue?.value
        }

        if(!sectionName) {
            continue
        }

        let section = conjugationTable[key]

        if (section) {
            let sectionDict = {}
            sectionDict.id = key
            sectionDict.collapsable = true
            sectionDict.rows = []

            let addSubheader = true
        //     "lang": {
        // "1st-person plural": "We",
        // "1st-person singular": "I",
        // "2nd-person plural": "You",
        // "2nd-person singular": "You",
        // "3rd-person plural": "They",
        // "3rd-person singular": "He, She, It",
            
            if (Array.isArray(section)) {
                // array of strings
                let currentLanguage = i18next.language
                let cells = []
                try {
                    await i18next.changeLanguage('ru')
                    cells = [[1, 'singular', 's1', i18next.t('lang.1st-person singular')], 0, [1, 'plural', 'm1', i18next.t('lang.1st-person plural')], 3, [2, 'singular', 's2', i18next.t('lang.2nd-person singular')], 1, [2, 'plural', 'm2', i18next.t('lang.2nd-person plural')], 4, [3, 'singular', 's3', i18next.t('lang.3rd-person singular')], 2, [3, 'plural', 'm3', i18next.t('lang.3rd-person plural')], 5]
                } catch(e) {
                    console.log('error ' + e)
                    cells = []
                } finally {
                    await i18next.changeLanguage(currentLanguage)
                }

                let rowCells = []
                for (let i = 0; i < cells.length; i++) {
                    let cell = cells[i]
                    if (Array.isArray(cell)) {
                        rowCells.push({ type: 'person', index: cell[0], count: cell[1], text_abbr: cell[2], text: cell[3] })
                    } else {
                        rowCells.push({ type: 'text', readonly: true, text: section[cell] })
                    }

                    if (rowCells.length === 4) {
                        sectionDict.rows.push(rowCells)
                        rowCells = []
                    }
                }

                if (rowCells.length > 0) {
                    sectionDict.rows.push(rowCells)
                }
            } else if (typeof section === 'string') {
                console.log('section is string')
                // infinitive case
                addSubheader = false
                sectionDict.header = { readonly: true, text: sectionName, localize: true }
                sectionDict.value = { type: 'text', readonly: true, text: section }
            } else {
                let rowCells = []
                console.log('Else key ' + idx + ' section ' + JSON.stringify(section))
                // console.log("Comparing " + sectionName + " with " + )
                if (key === 'past') {
                    // past tense
                    rowCells.push({ type: 'empty' })
                    rowCells.push({ type: 'header', readonly: true, text: 'singular', localize: true })
                    rowCells.push({ type: 'header', readonly: true, text: 'plural', localize: true })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'masculine', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.masculine })
                    rowCells.push({ type: 'empty' })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'feminine', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.feminine })
                    rowCells.push({ type: 'text', readonly: true, text: section.plural })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'neuter', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.neuter })
                    rowCells.push({ type: 'empty' })
                    sectionDict.rows.push(rowCells)
                } else if (key === 'participle') {
                    rowCells.push({ type: 'empty' })
                    rowCells.push({ type: 'header', readonly: true, text: 'present', localize: true })
                    rowCells.push({ type: 'header', readonly: true, text: 'past', localize: true })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'active', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.present?.active })
                    rowCells.push({ type: 'text', readonly: true, text: section.past?.active })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'passive', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.present?.passive })
                    rowCells.push({ type: 'text', readonly: true, text: section.past?.passive })
                    sectionDict.rows.push(rowCells)

                    rowCells = []
                    rowCells.push({ type: 'header', readonly: true, text: 'adverbial', localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: convertArrayToNewLineSeparatedString(section.present?.adverbial ?? []) })
                    rowCells.push({ type: 'text', readonly: true, text: convertArrayToNewLineSeparatedString(section.past?.adverbial ?? []) })
                    sectionDict.rows.push(rowCells)
                } else if(key === 'imperative') {
                    
                    rowCells = []
                    // rowCells.push({ type: 'empty' })
                    rowCells.push({ type: 'header', readonly: true, text: 'singular', localize: true })
                    rowCells.push({ type: 'header', readonly: true, text: 'plural', localize: true })
                    sectionDict.rows.push(rowCells)

                    console.log(`imperative dict ${JSON.stringify(section)} singular ${section.singular} plural ${section.plural}`)
                    rowCells = []
                    // rowCells.push({ type: 'empty' })
                    rowCells.push({ type: 'text', readonly: true, text: section.singular, localize: true })
                    rowCells.push({ type: 'text', readonly: true, text: section.plural, localize: true })
                    sectionDict.rows.push(rowCells)


                    // rowCells = []
                    // rowCells.push({ type: 'header', readonly: true, text: 'feminine', localize: true })
                    // rowCells.push({ type: 'text', readonly: true, text: section.feminine })
                    // rowCells.push({ type: 'text', readonly: true, text: section.plural })
                    // sectionDict.rows.push(rowCells)
                } 
                // else {
                //     rowCells.push({ type: 'empty' })
                //     rowCells.push({ type: 'header', readonly: true, text: 'singular', localize: true })
                //     rowCells.push({ type: 'header', readonly: true, text: 'plural', localize: true })
                //     sectionDict.rows.push(rowCells)
    
                //     rowCells = []
    
                //     // let masculine = section.masculine
                //     // let feminine = section.feminine
    
                //     rowCells.push({ type: 'header', readonly: true, text: 'Masculine', localize: true })
                //     rowCells.push({ type: 'text', readonly: true, text: section.masculineSingular })
                //     rowCells.push({ type: 'text', readonly: true, text: section.masculinePlural })
                //     sectionDict.rows.push(rowCells)
    
                //     rowCells = []
    
                //     rowCells.push({ type: 'header', readonly: true, text: 'Feminine', localize: true })
                //     rowCells.push({ type: 'text', readonly: true, text: section.feminineSingular })
                //     rowCells.push({ type: 'text', readonly: true, text: section.femininePlural })
                // }
                // else it's e

                // console.log('section is participle object ' + JSON.stringify(section))
                // console.log('participle object ' + JSON.stringify(section))

                // participle object
                
               
                // sectionDict.rows.push(rowCells)
            }

            if (addSubheader) {
                sectionDict.subheader = { readonly: true, text: sectionName, localize: true }
            }

            sections.push(sectionDict)
        }
    }
    dict.sections = sections

    console.log('pushing dict ' + JSON.stringify(dict))
    items.push(dict)
}

function convertArrayToNewLineSeparatedString(array) {
    if(Array.isArray(array)) {
        let count = array.length ?? 0
        if (count === 0) {
            return ''
        } else if (count === 1) {
            return array[0]
        } else {
            let result = ''
            for (let i = 0; i < array.length; i++) {
                result += array[i]
                if (i < array.length - 1) {
                    result += '\n'
                }
            }
        
            return result
        }
    } else {
        return array
    }
}

function addEnglishSnippetsConjugationTable(items, conjugationTable) {
   
    let dict = { id: randomID(), type: 'table' }

    let sections = []

    function createSection(collapsable = false) {
        let sectionDict = {}
        sectionDict.id = randomID()
        sectionDict.collapsable = collapsable
        sectionDict.rows = []

        return sectionDict
    }

    let sectionDict = createSection(false)
    sectionDict.id = 'main'
    sectionDict.subheader = { readonly: true, text: 'lang.conjugation_const', localize: true, collapsable: false }

    let rowKeys = [['1st-person singular', '1st_person_present', 'past'], ['2nd-person singular', '2nd_person_present', 'past'], ['3rd-person singular', '3rd_person_present', 'past'], ['plural', 'plural', 'past'], ['subjunctive', 'present_subjunctive', 'past_subjunctive'], ['imperative', 'imperative', null], ['participles', 'present_participle', 'past_participle']]
    // create headers row
    let rowCells = []
    rowCells.push({ type: 'empty' })
    rowCells.push({ type: 'header', readonly: true, text: 'lang.present', localize: true })
    rowCells.push({ type: 'header', readonly: true, text: 'lang.past', localize: true })
    sectionDict.rows.push(rowCells)
    // iterate through each row and create a section for each tense

    for(let arr in rowKeys) {
        let row = rowKeys[arr]
        let rowCells = []
        rowCells.push({ type: 'header', readonly: true, text: row[0], localize: true })
        rowCells.push({ type: 'text', readonly: true, text: conjugationTable[row[1]] ?? '-' })
        rowCells.push({ type: 'text', readonly: true, text: conjugationTable[row[2]] ?? '-' })
        sectionDict.rows.push(rowCells)
    }


    // for (let idx in rows) {
    //     let row = rows[idx]
    //     rowCells = []

    //     // let masculine = section.masculine
    //     // let feminine = section.feminine

    //     rowCells.push({ type: 'header', readonly: true, text: row, localize: true })
    //     for (let tense of tenses) {
    //         let text = conjugationTable[tense]?.[row] ?? '-'
    //         rowCells.push({ type: 'text', readonly: true, text: text })

    //     }
    //     sectionDict.rows.push(rowCells)
    // }
    sections.push(sectionDict)
    dict.sections = sections

    items.push(dict)

    console.log("English conjugation object " + JSON.stringify(items))
}

async function addSpanishSnippetsConjugationTable(items, conjugationTable) {

    if (!conjugationTable) {
        console.log('no conjugation table found')
        return
    }

    let dict = { id: randomID(), type: 'table' }

    let sections = []

    // let sectionNames = ['Infinitive', 'Gerund', 'Present', 'Imperfect', 'Preterite', 'Future', 'Past Participle', 'Conditional', 'Subjunctive Present', 'Subjunctive Imperfect(ra)', 'Subjunctive Imperfect(se)', 'Subjunctive Future', 'Imperative Affirmative', 'Imperative Negative']
    const sectionNames = Object.keys(SharedConstants.Dictionary.Tense.Spanish)

    // convert sections to desired format
    for (let idx in sectionNames) {
        let sectionName = sectionNames[idx]
        let section = conjugationTable[sectionName]

        console.log('sectionName ' + sectionName + ' section ' + section)

        if (section) {
            let sectionDict = {}
            sectionDict.id = sectionName
            sectionDict.collapsable = true
            sectionDict.rows = []

            let addSubheader = true

            if (Array.isArray(section)) {
                // array of strings
                
                let currentLanguage = i18next.language
                let cells = []
                try {
                    await i18next.changeLanguage('es')
                    cells = [[1, 'singular', 's1', i18next.t('lang.1st-person singular')], 0, [1, 'plural', 'm1', i18next.t('lang.1st-person plural')], 3, [2, 'singular', 's2', i18next.t('lang.2nd-person singular')], 1, [2, 'plural', 'm2', i18next.t('lang.2nd-person plural')], 4, [3, 'singular', 's3', i18next.t('lang.3rd-person singular')], 2, [3, 'plural', 'm3', i18next.t('lang.3rd-person plural')], 5]
                } catch(e) {
                    console.log('error ' + e)
                    cells = []
                } finally {
                    await i18next.changeLanguage(currentLanguage)
                }

                let rowCells = []
                for (let i = 0; i < cells.length; i++) {
                    let cell = cells[i]
                    if (Array.isArray(cell)) {
                        rowCells.push({ type: 'person', index: cell[0], count: cell[1], text_abbr: cell[2], text: cell[3] })
                    } else {
                        rowCells.push({ type: 'text', readonly: true, text: section[cell] })
                    }

                    if (rowCells.length === 4) {
                        sectionDict.rows.push(rowCells)
                        rowCells = []
                    }
                }

                if (rowCells.length > 0) {
                    sectionDict.rows.push(rowCells)
                }
            } else if (typeof section === 'string') {
                // gerund and infinitive cases
                addSubheader = false
                sectionDict.header = { readonly: true, text: sectionName, localize: true }
                sectionDict.value = { type: 'text', readonly: true, text: section }
            } else {
                console.log('participle object ' + JSON.stringify(section))

                // participle object
                let rowCells = []
                rowCells.push({ type: 'empty' })
                rowCells.push({ type: 'header', readonly: true, text: 'singular', localize: true })
                rowCells.push({ type: 'header', readonly: true, text: 'plural', localize: true })
                sectionDict.rows.push(rowCells)

                rowCells = []

                // let masculine = section.masculine
                // let feminine = section.feminine

                rowCells.push({ type: 'header', readonly: true, text: 'Masculine', localize: true })
                rowCells.push({ type: 'text', readonly: true, text: section.masculineSingular })
                rowCells.push({ type: 'text', readonly: true, text: section.masculinePlural })
                sectionDict.rows.push(rowCells)

                rowCells = []

                rowCells.push({ type: 'header', readonly: true, text: 'Feminine', localize: true })
                rowCells.push({ type: 'text', readonly: true, text: section.feminineSingular })
                rowCells.push({ type: 'text', readonly: true, text: section.femininePlural })
                sectionDict.rows.push(rowCells)
            }

            if (addSubheader) {
                sectionDict.subheader = { readonly: true, text: sectionName, localize: true }
            }

            sections.push(sectionDict)
        }
    }
    dict.sections = sections

    items.push(dict)

    console.log(`Converted conjugation object from ${JSON.stringify(conjugationTable)} to ${JSON.stringify(items)}`)
}

function extractCombinedVerb(s) {
    // The regex matches a verb that comes right after the pattern "{something} of" and before "combined with {something-else}"
    const regex = /.+ of (?<verb>[a-zA-Z]+) combined with .+/i;
    const match = s.match(regex);

    if (match && match.groups && match.groups.verb) {
        return match.groups.verb;
    }

    return null;
}

function extractVerbFromForm(s) {
    // The regex matches a verb that comes right after the pattern "first/second/third-person ... of"
    // It allows for alphabets, spaces, and slashes in between.
    const regex = /(?:first|second|third)-person [\w\s\/]+ of (?<verb>[a-zA-Z]+)/i;
    const match = s.match(regex);

    if (match && match.groups && match.groups.verb) {
        return match.groups.verb;
    }

    return null;
}

function extractInflection(s) {
    // Added the 'i' flag to the regex for case-insensitive matching.
    const regex = /inflection of ([a-zA-Z]+)/i;
    const match = s.match(regex);

    if (match && match[1]) {
        return match[1];
    }

    return null;
}


function randomID() {
    // generate random id string from alphanumeric characters with length 16
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
}

function generateTimestampedKey() {
    let keyLength = 16

    // Get current timestamp in seconds and convert to hex
    const timestampHex = Math.floor(Date.now() / 1000).toString(16);

    // Function to generate a random alphanumeric character
    function getRandomChar() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return chars[Math.floor(Math.random() * chars.length)];
    }

    // Calculate the number of random characters needed
    const randomCharCount = keyLength - timestampHex.length;

    // Generate random alphanumeric characters
    let randomChars = '';
    for (let i = 0; i < randomCharCount; i++) {
        randomChars += getRandomChar();
    }

    // Combine timestamp and random characters
    const key = timestampHex + randomChars;
    return key;
}

// function createDictionaryLookupCacheObject(wiktionaryFetchResult, searchTermWithAccents, learningLanguageCode) {
//     let formsArray = []

//     let etymologies = wiktionaryFetchResult.etymologies ?? []

//     let wordTypeMap = mergeWordTypesIntoOneDictionary(etymologies)

//     for(let wordType in wordTypeMap) {
//         let wordTypeResults = wordTypeMap[wordType]
//         for(let wordTypeResult of wordTypeResults) {
//             if(wordTypeResult.inflections) {
//                 // inflections
//                 formsArray = formsArray.concat(wordTypeResult.inflections)
//             } else if(wordTypeResult.conjugation) {
//                 // conjugation is attached only to infinitive form terms
//                 // we add the verb so it links to the infinitive form
//                 formsArray.push( { term: searchTermWithAccents } )
//             } else {
//                 formsArray.push( { term: searchTermWithAccents } )
//             }
//         }
//     }

//     return {
//         forms: formsArray
//     }
// }

// {
//     "verb": {
//         "term": "trabajar",
//         "definitions": [
//             {
//                 "text": {
//                     "raw": "to work",
//                     "text": "to work"
//                 }
//             },
//             {
//                 "text": {
//                     "raw": "to function",
//                     "text": "to function"
//                 }
//             }
//         ]
//         ]
//     }
// }


function removeAccents(str) {
    const accentMap = {
        a: '[aáàãäâ]',
        e: '[eéèëê]',
        i: '[iíìïî]',
        o: '[oóòöõô]',
        u: '[uúùüû]',
        c: '[cç]',
        n: '[nñ]',
    };

    let result = str;
    for (const [unaccented, accented] of Object.entries(accentMap)) {
        result = result.replace(new RegExp(accented, 'gi'), unaccented);
    }
    return result;
}

// async function translateWiktionaryFetchResult(wiktionaryFetchResult, userLanguage, learningLanguage) {
//     let translatedObject = JSON.parse(JSON.stringify(wiktionaryFetchResult))
//     let etymologies = translatedObject.etymologies ?? {}
//     let promises = []
//     let term = translatedObject.term
 
//     const userLanguageName = userLanguage.name
//     const learningLanguageName = learningLanguage.name
    
//     // remove all key-values except etymologies
//     for(let key in translatedObject) {
//         if(key !== 'etymologies') {
//             delete translatedObject[key]
//         }
//     }

//     for(let etymologyKey in etymologies) {
//         let etymology = etymologies[etymologyKey]
//         if (etymology.wordTypes) {
//             let filteredWordTypes = []
//             for(let wordType of etymology.wordTypes) {
//                 let definitions = wordType.definitions
//                 if(definitions) {
//                     filteredWordTypes.push(wordType)
//                     let alphabetMessage = ''
//                     if(userLanguage.code === 'sr' || userLanguage.code === 'ru') {
//                         alphabetMessage = `Return the definitions in ${userLanguageName} using the native cyrillic alphabet.`
//                     }
//                     let message = `I will provide the JSON containing the definitions in English language for the ${learningLanguageName} word '${term}' of type '${wordType.wordType}'. I will need the definitions in ${userLanguageName}. Note that definitions will most likely not match those in English since languages don't map one-to-one. Return the response as an array of strings like so: { "definitions": ["definition1", "definition2", ...] }. ${alphabetMessage}`            
//                     let promise = callGPT(message).then(result => {
//                         let json = extractJSONFromGPTResponse(result)
//                         console.log("Translated JSON: " + JSON.stringify(json))
//                         let translatedDefinitionsArray = json.definitions
//                         if(translatedDefinitionsArray) {
//                             let translatedDefinitions = {}
//                             for(let i = 0; i < translatedDefinitionsArray.length; i++) {
//                                 let translatedDefinition = translatedDefinitionsArray[i]
//                                 let id = generateTimestampedKey()
//                                 translatedDefinitions[id] = { text: { text: translatedDefinition, raw: translatedDefinition } }
//                             }
//                             wordType.definitions = translatedDefinitions
//                         }
//                     })
//                     promises.push(promise)

//                     // remove all key-values for wordType except definitions
//                     for(let key in wordType) {
//                         if(key !== 'definitions' && key !== 'wordType') {
//                             delete wordType[key]
//                         }
//                     }
//                 }
//             }

//             // for etymology, remove all key-values except wordTypes
//             for(let key in etymology) {
//                 if(key !== 'wordTypes') {
//                     delete etymology[key]
//                 }
//             }

//             etymology.wordTypes = filteredWordTypes
//         }
//     }

//     return Promise.all(promises).then(() => {
//         return translatedObject
//     })
// }

// function createTranslationDictionaryCacheObject(translatedWiktionaryResult, searchTermWithAccents, learningLanguageCode) {
//     let obj = {}

//     return obj
// }

// object to be cached in 
// function createDictionaryCacheObject(wiktionaryFetchResult, searchTermWithAccents, learningLanguageCode) {
//     let forms = {}

//     let etymologies = wiktionaryFetchResult.etymologies ?? {}

//     let wordTypeMap = mergeWordTypesIntoOneDictionary(etymologies)

//     for(let wordType in wordTypeMap) {
//         let wordTypeResults = wordTypeMap[wordType]

//         let lowerCaseWordType = wordType.toLowerCase().trim()
//         let mergedObject = mergeWordTypeArrayIntoSingleObject(wordTypeResults)
//         forms[lowerCaseWordType] = mergedObject
//     }

//     forms.term = wiktionaryFetchResult.term
//     return forms
// }

// function mergeWordTypeArrayIntoSingleObject(objects) {
//     // keep wordType of the first found object;
//     // merge definitions dictionary and inflections
//     let mergedObject = {}
//     for (let obj of objects) {
//         let wordType = obj.wordType
//         let definitions = obj.definitions
//         let inflections = obj.inflections
//         let conjugation = obj.conjugation

//         if(inflections) {
//             mergedObject = { ...mergedObject, ...obj.inflections}
//             continue
//         }

//         if (wordType) {
//             mergedObject.wordType = wordType
//         }

//         if (conjugation) {
//             mergedObject.conjugation = conjugation
//         }

//         if (definitions) {
//             let definitionsCopy = JSON.parse(JSON.stringify(definitions))
//             for (let definitionKey in definitionsCopy) {
//                 let definition = definitionsCopy[definitionKey]
//                 if(obj.gender) {
//                     definition.gender = obj.gender
//                 }
//             }

//             mergedObject.definitions = { ...mergedObject.definitions, ...definitionsCopy }
//         }
        
//     }

//     return mergedObject
// }

import { copyJSONObject } from "../utils/utils"

const exampleClassroomSnippets = {
  en: {
    "1wdx13l1xetbzo68": {
      "createdAt": 1733943822771,
      "definitionsLanguageCode": "ru",
      "link": {
        "accentedTerm": "love",
        "language": "en",
        "term": "love",
        "wordType": "noun"
      },
      "selectedDefinition": {
        "id": "5rIozf0wGNoPHgA5",
        "languageCode": "ru"
      }
    },
    "lymujau8s4e45jkt": {
      "createdAt": 1733943845325,
      "definitionsLanguageCode": "ru",
      "link": {
        "accentedTerm": "study",
        "language": "en",
        "term": "study",
        "wordType": "verb"
      },
      "selectedDefinition": {
        "id": "GJIMRXw2DbLSDqSR",
        "languageCode": "ru"
      },
      "selectedSections": {
        "main": true
      }
    },
    "n0mbjhbfi41icmo8": {
      "createdAt": 1733943863575,
      "definitionsLanguageCode": "ru",
      "link": {
        "accentedTerm": "hello",
        "language": "en",
        "term": "hello",
        "wordType": "interjection"
      },
      "selectedDefinition": {
        "id": "XqNFkyzbnPT6cFLF",
        "languageCode": "ru"
      }
    }
  },
  ru: {
    "6h923u8tn9zsuu3n": {
      "createdAt": 1733934375718,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "любо́вь",
        "language": "ru",
        "term": "любовь",
        "wordType": "noun"
      },
      "selectedDefinition": {
        "id": "6759bcd1NyOqGQR4",
        "languageCode": "en"
      },
      "selectedSections": {
        "accusative": true,
        "dative": true,
        "genitive": true,
        "header-singular-plural": true,
        "instrumental": true,
        "nominative": true,
        "prepositional": true
      }
    },
    "lkzpknhwfnguldco": {
      "createdAt": 1733934282250,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "приве́т",
        "language": "ru",
        "term": "привет",
        "wordType": "interjection"
      },
      "selectedDefinition": {
        "id": "675418c2fe1vfLLs",
        "languageCode": "en"
      }
    },
    "vmrzrbfu0o6ivex5": {
      "createdAt": 1733934357619,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "учи́ть",
        "language": "ru",
        "term": "учить",
        "wordType": "verb"
      },
      "selectedDefinition": {
        "id": "6759bd0dFiXWB2ka",
        "languageCode": "en"
      },
      "selectedSections": {
        "future": true,
        "imperative": true,
        "infinitive": true,
        "participle": true,
        "past": true,
        "present": true
      }
    }
  },
  es: {
    "f1fyowdiyayn987v": {
      "createdAt": 1733310552038,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "hablar",
        "language": "es",
        "term": "hablar",
        "wordType": "verb"
      },
      "selectedDefinition": {
        "id": "674ff63b7BIANqz0",
        "languageCode": "en"
      },
      "selectedSections": {
        "conditional": true,
        "future": true,
        "gerund": true,
        "imperative_affirmative": true,
        "imperative_negative": true,
        "imperfect": true,
        "infinitive": true,
        "past_participle": true,
        "present": true,
        "preterite": true,
        "subjunctive_future": true,
        "subjunctive_imperfect_ra": true,
        "subjunctive_imperfect_se": true,
        "subjunctive_present": true
      }
    },
    "lqn3v29zxwpbu95e": {
      "createdAt": 1733310590739,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "hola",
        "language": "es",
        "term": "hola",
        "wordType": "interjection"
      },
      "selectedDefinition": {
        "id": "67586dcfLmJXzK9V",
        "languageCode": "en"
      }
    },
    "wzrkv9bi4mfvdp7n": {
      "createdAt": 1733310562502,
      "definitionsLanguageCode": "en",
      "link": {
        "accentedTerm": "casa",
        "language": "es",
        "term": "casa",
        "wordType": "noun"
      },
      "selectedDefinition": {
        "id": "66d0b3f91f09oaQV",
        "languageCode": "en"
      }
    }
  }
}

export const exampleClassroomPermissions = {
  "userIDStudent": {
    "isOwner": true,
    "name": "Student",
    "role": "student",
    "state": "accepted"
  },
  "userIDTeacher": {
    "name": "Teacher",
    "role": "teacher",
    "state": "accepted"
  }
}

const exampleClassroomData = {
  "adminUserID": "userIDStudent",
  "createdAt": 1731413411479,
  "createdBy": "userIDStudent",
  "id": "example-classroom-id",
  "languageCode": "es",
  "name": "Example Classroom",
  "notes": "Initial notes",
  "state": "created"
}

export function getExampleClassroomData(languageCode, userLanguageCode) {
  let data = copyJSONObject(exampleClassroomData)
  data.languageCode = languageCode
  let snippets = copyJSONObject(exampleClassroomSnippets[languageCode])
  for(let snippetKey in snippets) {
    let snippet = snippets[snippetKey]
    snippet.definitionsLanguageCode = userLanguageCode
  }
  data.classroomSnippets = snippets
  console.log(`getExampleClassroomData[${languageCode}] = `, data)
  return data
}

export function getExampleClassroomNotes(languageCode) {
  return "Example notes"
}
import { Box } from "@chakra-ui/react";
import ClassNotesWrapper from "../components/classrooms/class-notes-wrapper";
import ColumnSnippets from "../components/column-snippets";
import DictionarySearch from "../components/dictionary-search";
import TranslateWord from "../components/translate/translate-word";
import { ClassroomLayoutComponentType } from "../api/classroom.service";

export function getLayoutComponents(keys, t, props = {}) {
  if (!keys) {
    return null;
  }

  let componentValues = keys;
  let components = [];
  for (let type of componentValues) {
    if (type) {
      switch (type) {
        case ClassroomLayoutComponentType.NOTES:
          components.push({
            component: ClassNotesWrapper,
            title: t("class_notes.title"),
            ...props
          });
          break;
        case ClassroomLayoutComponentType.SENTENCES:
          components.push({
            component: ColumnSnippets,
            title: t("sentences.snippets"),
            ...props
          });
          break;
        case ClassroomLayoutComponentType.TRANSLATE_WORD:
          components.push({
            component: TranslateWord,
            title: t("translate.translate_title"),
            ...props
          });
          break;
        case ClassroomLayoutComponentType.SNIPPETS:
          components.push({
            component: ColumnSnippets,
            title: t("sentences.snippets"),
            ...props
          });
          break;
        case ClassroomLayoutComponentType.DICTIONARY_SEARCH:
          components.push({
            component: DictionarySearch,
            title: t("translate.dictionary_search"),
            ...props
          });
          break;
        default:
          console.log("Unknown component type " + type);
          components.push(Box);
          break;
      }
    }
  }

  console.log("Returning components from getLayoutComponents: ", components);
  return components;
}

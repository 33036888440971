import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  useToast,
} from "@chakra-ui/react";
import { getDatabase, ref, get } from "firebase/database";
import Header from "../header";
import { updateLanguages } from "../../api/user-language.service";
import SEO from "../../seo/seo";
import { isAuthenticationFinishedState, userState } from "../../state/user-state";
import { useRecoilState } from "recoil";
import ChooseLanguageContent from "./choose-language-content";
import { database } from "../../api/firebaseConfig";


function ChooseLanguage() {
  const [isAuthenticationFinished, setAuthenticationFinished] = useRecoilState(
    isAuthenticationFinishedState
  );
  const [user, setUser] = useRecoilState(userState);
  const [isSubmitting, setSubmitting] = useState(false);
  // const db = getDatabase();

  const toast = useToast()

  useEffect(() => {
    if (!isAuthenticationFinished) {
      return
    }
    if (user) {
      const userRef = ref(database, "users/" + user.uid);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const { userLanguage, learningLanguage } = snapshot.val();
          if (userLanguage && learningLanguage) {
            window.location.href = "/documents";
            return;
          }
        }
      })


      // try to load user language from local storage
      // const storedUserLanguage = localStorage.getItem("userLanguage");
      // console.log("user language " + storedUserLanguage);
      // if (storedUserLanguage) {
      //   setUserLanguage(getLanguageWithCode(storedUserLanguage));
      // }

      // // no languages
      // setHasLanguages(false);
    } else {
      // no user
      window.location.href = "/";
    }

  }, [isAuthenticationFinished, user]);

  const onClickUpdate = async (userLanguage, learningLanguage) => {
    setSubmitting(true);
    try {
      await updateLanguages(user?.uid, userLanguage?.code, learningLanguage?.code);
      window.location.href = "/documents";
    } catch (error) {
      console.error("Error setting user languages", error);
      toast({
        description: "Error setting user languages",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Box
      width="100%"
      height="calc(100vh)"
      style={{
        backgroundImage: "url('/icons/bg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center center",
      }}
    >
      <Header hideNavigation={true} />
      <SEO pageKey={"choose_language"} />
      <Center as="main" h="calc(100vh - 65px)">
        <ChooseLanguageContent onUpdate={onClickUpdate} isLoading={isSubmitting} />
      </Center>
    </Box>
  );
}

export default ChooseLanguage;

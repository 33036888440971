import { Box, Center } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import SEO from "../../seo/seo";
import Header from "../header";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState, userState } from "../../state/user-state";
import { useUser } from "../../auth/user-context";
import Footer from "../footer";
import { useNavigate } from "react-router-dom";
import ClassroomContent from "./classroom-content";
import { exampleClassroomSnippetAddedEvent, exampleClassroomState } from "./example-classroom-state";
import { useTranslation } from "react-i18next";
import { exampleClassroomPermissions, getExampleClassroomData } from "../example-classroom-data";
import ChooseLanguage from "../choose-language/chooseLanguage";
import ChooseLanguageContent from "../choose-language/choose-language-content";
import { updateLanguages } from "../../api/user-language.service";
import { classroomLanguageState } from "../../state/classroom-state";
import { fetchCachedSnippets } from "../../api/sentences.service";
import Tutorial, { isTutorialCompleted, setTutorialCompleted } from "../tutorial";
import { snippetsState } from "../../state/snippets-state";
import { Constants } from "../../utils/constants";
import { clearLocalStorageKey } from "../../api/localStorage"
const MotionBox = motion(Box); // Create a motion-enabled Box component

const animationDuration = 0.25

const ExampleClassroomScreenState = {
    CHOOSE_LANGUAGE: "choose-language",
    CLASSROOM: "classroom"
}

function ExampleClassroom() {
    const [user, setUser] = useRecoilState(userState);
    const [screenState, setScreenState] = useState(null);
    const [exampleClassroomData, setExampleClassroomData] = useRecoilState(exampleClassroomState);
    const { authenticationFinished } = useUser();

    const [lastAddedSnippet, setLastAddedSnippet] = useRecoilState(snippetsState);

    const navigate = useNavigate();

    const { t, ready: tReady, i18n } = useTranslation();

    const [currentComponent, setCurrentComponent] = useState(0); // State to track which component is shown
    const [direction, setDirection] = useState(1); // 1 for right, -1 for left
    const [swiped, setSwiped] = useState(false); // State to track if user is swiping
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)
    const [classroomLanguage, setClassroomLanguage] = useRecoilState(
        classroomLanguageState
    );

    const [exampleClassroomSnippetAdded, setExampleClassroomSnippetAdded] = useRecoilState(exampleClassroomSnippetAddedEvent)

    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
    const [tutorialSteps, setTutorialSteps] = useState([])
    const [hasAddedSingleSnippet, setHasAddedSingleSnippet] = useState(false)

    useEffect(() => {
        if (!userLanguage || !tReady || !authenticationFinished || !i18n) {
            return
        }

        i18n.changeLanguage(userLanguage.code).then(() => {
            setIsLanguageLoaded(true)
        }).catch((error) => {
            console.error('Error changing language', error)
        })

    }, [userLanguage, tReady, i18n, authenticationFinished])

    useEffect(() => {
        if (!exampleClassroomSnippetAdded) {
            return
        }

        setHasAddedSingleSnippet(true)

        setExampleClassroomSnippetAdded(null)
    }, [exampleClassroomSnippetAdded])

    useEffect(() => {
        if (!isLanguageLoaded || isTutorialCompleted(Constants.Tutorial.Key.ExampleClassroom)) {
            return
        }


        // "classroom::tutorial_step_screen_explanation": "Classrooms are designed to help teachers and students manage materials, such as new words and notes. First, let’s take a look at the student screen.",
        //     "classroom::tutorial_step_class_snippets_add_lets_try": "A student can click here to add the snippet to their list. Let's try it!",
        //     "classroom::tutorial_step_class_snippets_added_fixed": "Good Job! The snippet has been added to the student's list.",
        //     "classroom::tutorial_step_class_notes_fixed": "Class notes will instantly update on the student's screen as the teacher types.",
        //     "classroom::tutorial_step_see_teacher_screen_fixed": "Now, let's take a look at the teacher screen. Click here.",
        
        //     "classroom::tutorial_step_useful_tools_teacher": "Teacher can use the translate and dictionary widgets to quickly look up words.",
        //     "classroom::tutorial_step_play_create_acc": "Feel free to play around with the example classroom or create an account to create your own classrooms.",
        let steps = [
            {
                id: "classNotesTitle_student",
                message: t('classroom.tutorial_step_screen_explanation'),
                offset: { top: 0 },
                hideArrow: true,
                position: "bottom",
                hasNextButton: true
            },
            {
                hasNextButton: false,
                message: t('classroom.tutorial_step_class_snippets_add_lets_try'),
                position: "top",
                completed: hasAddedSingleSnippet,
                selectElement: (currentStep) => {
                    let maxIndex = 5

                    for(let index = 0; index < maxIndex; index++) {
                        const id = `snippetItem_student_${index}`
                        const element = document.getElementById(id)
                        if(element) {
                            return element
                        }
                    }
                    
                    return null
                }
            },
            {
                id: "columnSnippets_student",
                message: t('classroom.tutorial_step_class_snippets_added_fixed'),
                position: "left",
                hasNextButton: true,
            },
            {
                id: "classNotes_student",
                message: t('classroom.tutorial_step_class_notes_fixed'),
                position: "bottom",
                hasNextButton: true
            },
            {
                id: "classroomSwipeRight",
                message: t('classroom.tutorial_step_see_teacher_screen_wo_click_here'),
                position: "left",
                hasNextButton: false,
                completed: swiped,
            },
            {
                id: "layoutColumn_2",
                message: t('classroom.tutorial_step_useful_tools_teacher'),
                position: "left",
                hasNextButton: true
            },
            {
                id: "classNotesTitle_teacher",
                message: t('classroom.tutorial_step_play_create_acc_fixed'),
                hideArrow: true,
                position: "bottom",
                showCompleteButton: true
            },
        ]

        // delay setting tutorial steps by 1500ms to to allow for elements to be rendered; 
        // this is a workaround for the issue where the element is not found
        const delay = tutorialSteps.length === 0 ? 1500 : 0
        setTimeout(() => {
            setTutorialSteps(steps)
        }, delay)
    }, [isLanguageLoaded, hasAddedSingleSnippet, swiped])

    useEffect(() => {
        // when closing component, set example classroom data to null
        return () => {
            setExampleClassroomData(null);
        };
    }, []);

    useEffect(() => {
        if (authenticationFinished) {
            if (user) {
                navigate("/classrooms", { replace: true });
            } else if (!screenState) { // example data and screen state not set
                if (!learningLanguage) {
                    setScreenState(ExampleClassroomScreenState.CHOOSE_LANGUAGE);
                } else {
                    setScreenState(ExampleClassroomScreenState.CLASSROOM);
                }

            }
        }
    }, [authenticationFinished, user, learningLanguage, userLanguage]);

    useEffect(() => {
        if (screenState === ExampleClassroomScreenState.CLASSROOM && !exampleClassroomData && learningLanguage && userLanguage) {
            const currentLanguageCode = learningLanguage.code
            let currentLanguageClassroomData = getExampleClassroomData(currentLanguageCode, userLanguage.code);

            let data = {
                classroomData: currentLanguageClassroomData,
                permissions: exampleClassroomPermissions,
                notes: `${t('clasrooms.example_notes_text')}`
            }
            if (data.classroomData?.classroomSnippets) {
                fetchCachedSnippets(data.classroomData?.classroomSnippets, userLanguage.code, learningLanguage.code).then((snippets) => {
                    data.classroomData.classroomSnippets = snippets;
                    setExampleClassroomData(data);
                })
            }
            // setExampleClassroomData(data);
        }
    }, [screenState, learningLanguage, userLanguage]);

    const onUpdateLanguages = (userLanguage, learningLanguage) => {
        if (!userLanguage || !learningLanguage) {
            return;
        }

        console.log('onUpdateLanguages', userLanguage, learningLanguage)

        updateLanguages(null, userLanguage, learningLanguage)
        setLearningLanguage(learningLanguage);
        setUserLanguage(userLanguage);
        setClassroomLanguage(learningLanguage);
    }

    useEffect(() => {
        if (userLanguage && learningLanguage && screenState !== ExampleClassroomScreenState.CLASSROOM) {
            setScreenState(ExampleClassroomScreenState.CLASSROOM);
        }
    }, [userLanguage, learningLanguage, screenState]);

    const handleSwipe = (nextComponent) => {
        setDirection(nextComponent > currentComponent ? 1 : -1); // Set swipe direction
        setCurrentComponent(nextComponent);
        setSwiped(true);
    };

    return (
        <>
            <Tutorial steps={tutorialSteps} onCompleteStep={(index) => {
                if(index === 0) {
                    setHasAddedSingleSnippet(false)
                }
            }}
            onComplete={() => {
                console.log('onComplete')
                setTutorialCompleted(Constants.Tutorial.Key.ExampleClassroom)   
            }}
            />
            <Box
                data-test="sentences-page"
                h="100vh"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >

                <SEO pageKey="example-classroom" />
                <Header title="example classroom" />
                <Box data-test="main-box" h="calc(100% - 64px)" position="relative" overflow="hidden">
                    {authenticationFinished && !user && (
                        <Box w='100%' h='100%'>
                            <Center as="main" h="calc(100vh - 65px)">
                                {screenState === ExampleClassroomScreenState.CHOOSE_LANGUAGE && <ChooseLanguageContent learningLanguageSubtitle="" onUpdate={onUpdateLanguages} />}
                            </Center>
                            {screenState === ExampleClassroomScreenState.CLASSROOM && <AnimatePresence>
                                {/* Classroom 1 */}
                                <MotionBox
                                    key="classroom1"
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    initial={{ x: currentComponent === 0 ? 0 : -direction * 100 + "%" }}
                                    animate={{ x: currentComponent === 0 ? 0 : -direction * 100 + "%" }}
                                    exit={{ x: direction * 100 + "%" }}
                                    transition={{ type: "tween", duration: animationDuration }}
                                    style={{ display: currentComponent === 0 || currentComponent === 1 ? "block" : "none" }}
                                >
                                    <ClassroomContent
                                        onClickSwipe={() => handleSwipe(1)}
                                        exampleUserID="userIDStudent"
                                        showSwipeOnLeft={false}
                                    />
                                </MotionBox>

                                {/* Classroom 2 */}
                                <MotionBox
                                    key="classroom2"
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    initial={{ x: swiped ? (currentComponent === 1 ? 0 : direction * 100 + "%") : '100%' }}
                                    animate={{ x: swiped ? (currentComponent === 1 ? 0 : direction * -100 + "%") : '100%' }}
                                    exit={{ x: direction * -100 + "%" }}
                                    transition={{ type: "tween", duration: animationDuration }}
                                    style={{ display: currentComponent === 0 || currentComponent === 1 ? "block" : "none" }}
                                >
                                    <ClassroomContent
                                        onClickSwipe={() => handleSwipe(0)}
                                        exampleUserID="userIDTeacher"
                                        showSwipeOnLeft={true}
                                    />
                                </MotionBox>
                            </AnimatePresence>
                            }
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default ExampleClassroom;

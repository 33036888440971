import { Box, Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, Image, Spacer, Spinner, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import Header from "../components/header";
import SEO from "../seo/seo";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { isAuthenticationFinishedState, userLanguageState, userState } from "../state/user-state";
import { useEffect, useRef, useState } from "react";
import ComboBox from "../components/combobox";
import { Tag } from "./tags";
import { searchStories } from "../api/story.service";
import { useNavigate } from "react-router-dom";
import { isPageValidState } from "../state/app-state";
import FallbackImage from "../components/fallback-image";
import RetinaImage from "../components/retina-image";

const numberOfColumns = 4;
const verticalSpacingHeaderContent = 8
const verticalSpacingContentFooter = 4;

function StoryList() {
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [isSearching, setIsSearching] = useState(false);
    const [storiesListBoxHeight, setStoriesListBoxHeight] = useState("0px");
    const [user, setUser] = useRecoilState(userState);
    const [authenticationFinished, setAuthenticationFinished] = useRecoilState(isAuthenticationFinishedState);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [stories, setStories] = useState(null);
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);

    const { t, ready } = useTranslation();

    const [filterLevels, setFilterLevels] = useState(null);

    const [filterLanguages, setFilterLanguages] = useState(null);

    const [filterCategories, setFilterCategories] = useState([
        Object.keys(Tag).map((key) => {
            return { id: Tag[key].id, title: Tag[key].name, imageName: `tag-${key}.png` }
        })
    ]);

    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // Filters local state
    const [filtersLocalSelectedLevel, setFiltersLocalSelectedLevel] = useState(null);
    const [filtersLocalSelectedLanguage, setFiltersLocalSelectedLanguage] = useState(null);
    const [filtersLocalSelectedCategories, setFiltersLocalSelectedCategories] = useState([]);

    const headerRef = useRef(null);

    const navigate = useNavigate();

    const toast = useToast();

    useEffect(() => {
        setIsPageValid(true);

        reloadStoriesHeight()
    }, []);

    useEffect(() => {
        if (!userLanguage || !ready) {
            return
        }
        // const [filterLevels, setFilterLevels] = useState([
        //     { id: "beginner", title: t('story.difficulty_beginner') },
        //     { id: "intermediate", title: t('story.difficulty_intermediate') },
        //     { id: "advanced", title: t('story.difficulty_advanced') },
        // ]);

        // const [filterLanguages, setFilterLanguages] = useState([
        //     { id: "es-MX", title: t('languages.spanish_mexican'), imageName: "flag-es-MX.png" },
        //     { id: "es", title: t('languages.spanish_spain'), imageName: "flag-es.png" },
        // ]);

        setFilterLevels([
            { id: "beginner", title: t('story.difficulty_beginner') },
            { id: "intermediate", title: t('story.difficulty_intermediate') },
            { id: "advanced", title: t('story.difficulty_advanced') },
        ]);

        setFilterLanguages([
            { id: "es-MX", title: t('languages.spanish_mexican'), imageName: "flag-es-MX.png" },
            { id: "es", title: t('languages.spanish_spain'), imageName: "flag-es.png" },
        ]);

        setFilterCategories([
            Object.keys(Tag).map((key) => {
                return { id: Tag[key].id, title: Tag[key].name, imageName: `tag-${key}.png` }
            })
        ]);
    }, [ready, userLanguage, t]);

    useEffect(() => {
        if(authenticationFinished) {
            if(!user) {
                navigate('/', { replace: true });
            }
        }
    }, [authenticationFinished, user]);

    useEffect(() => {
        if (!userLanguage) {
            return
        }

        if (stories == null) {
            let language = filterLanguages?.[0]
            setSelectedLanguage(language);
            // setSelectedFilters([language])
        }

    }, [userLanguage, filterLanguages]);

    useEffect(() => {
        if (!userLanguage || !selectedLanguage) {
            return
        }

        let filters = []
        if (selectedLanguage) {
            filters.push(selectedLanguage)
        }

        if (selectedLevel) {
            filters.push(selectedLevel)
        }

        if (selectedCategories) {
            filters.push(...selectedCategories)
        }

        setSelectedFilters(filters)

        search()

    }, [selectedLevel, selectedLanguage, selectedCategories, userLanguage]);

    useEffect(() => {
        reloadStoriesHeight()
    }, [user, headerRef?.current])

    function reloadStoriesHeight() {
        // Calculate and set the height of the scrollable box
        if (headerRef.current) {
            const headerHeight = headerRef.current.getBoundingClientRect().height;
            const viewportHeight = window.innerHeight;
            const calculatedHeight = viewportHeight - headerHeight - 128 - verticalSpacingHeaderContent - verticalSpacingContentFooter // 128px is the height of the header
            setStoriesListBoxHeight(`${calculatedHeight}px`);
        }
    }

    const onLanguageChange = (language) => {
        setFiltersLocalSelectedLanguage(language);
    };

    const onLevelChange = (level) => {
        setFiltersLocalSelectedLevel(level);
    };

    const onClickApply = () => {
        setSelectedLanguage(filtersLocalSelectedLanguage);
        setSelectedLevel(filtersLocalSelectedLevel);
        setSelectedCategories(filtersLocalSelectedCategories);
        onClose();
        // setSelectedFilters(getFiltersFromLocalState())
    };

    const onSelectStory = (index) => {
        let story = stories[index];
        let languageCode = story.languageCode;
        let storyId = story.id;

        let url = `/story/${languageCode}/${storyId}`
        console.log('Navigating to story: ', url)
        navigate(url, { replace: true });
    };

    function getSelectedFilters() {
        let filters = [];
        if (selectedLanguage) {
            filters.push(selectedLanguage);
        }

        if (selectedLevel) {
            filters.push(selectedLevel);
        }

        return filters;
    }

    function getFiltersFromLocalState() {
        let filters = [];
        if (selectedLanguage) {
            filters.push(selectedLanguage);
        }

        if (selectedLevel) {
            filters.push(selectedLevel);
        }

        return filters;
    }

    const search = async () => {
        try {
            setIsSearching(true);
            let difficulties = [];
            if (selectedLevel) {
                difficulties.push(selectedLevel.id);
            }
            let stories = await searchStories(null, userLanguage.code, selectedLanguage.id, difficulties, null, null, 'level', 0, 10);
            
            setStories(stories);
        } catch (error) {
            console.error(error);
            toast({
                title: error.message, //t('stories.error_searching'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setStories([]);
        } finally {
            setIsSearching(false);
        }
    };

    const onClickFilter = () => {
        setFiltersLocalSelectedLevel(selectedLevel);
        setFiltersLocalSelectedLanguage(selectedLanguage);
        setFiltersLocalSelectedCategories(selectedCategories);
        onOpen()
    }

    return (
        <>
            <Header />
            <Box
                as="main"
                h="calc(100vh - 128px)"
                data-test="stories-page"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                <SEO pageKey={"stories"} />
                {ready && isPageValid && userLanguage && t && <>
                    <Flex ref={headerRef} data-test='header-data-panel' direction='column' px={8} pt={4}>
                        {/* displayName */}
                        {user?.displayName && <Text fontSize="lg" color='gray.300'>
                            {t("stories.greeting_w_comma", { name: user.displayName })}
                        </Text>
                        }
                        <Text fontSize="3xl" fontWeight="bold" color="white">
                            {t("stories.stories")}
                        </Text>
                        <Box data-test='recommended-panel'>

                        </Box>
                        <Box data-test='filters'>

                        </Box>
                        <Box data-test='stories'>
                            <Flex direction='row' h='44px' spacing={4} alignItems='center' mt={2}>
                                <Button _hover={{ backgroundColor: "purple", color: "white" }} leftIcon={<Image src="/icons/filter-stories.png" boxSize="20px" color='white' />} backgroundColor='lightPurple' borderWidth={1} borderColor='gray.600' color="white" size="lg" data-test='filters-button' onClick={onClickFilter}>
                                    {t("stories.filters")}
                                </Button>
                                {
                                    selectedFilters.map((filter) => {
                                        return <Flex _hover={{ backgroundColor: 'purple' }} userSelect="none" cursor="pointer" ml={2} px={2} py={1} h='100%' backgroundColor='filterPurple' borderRadius='lg' alignItems='center'>
                                            {filter.imageName && <Image h='100%' src={`/icons/${filter.imageName}`} />}
                                            <Text ml={filter.imageName ? 1 : 0} key={filter} fontWeight='medium' color="gray.300">{t(`${filter.title}`)}</Text>
                                        </Flex>
                                    })
                                }
                            </Flex>
                        </Box>
                    </Flex>
                    <Box
                        data-test="stories-list-box"
                        mt={`${verticalSpacingHeaderContent}px`}
                        mx={8}
                        h={storiesListBoxHeight}  // Dynamic height
                        overflowY="auto"  // Enable vertical scrolling if content overflows
                    >
                        {isSearching && <Center><Spinner speed='0.5s' color="white" size="xl" /> </Center>}

                            <Grid

                                templateColumns={`repeat(${numberOfColumns}, 1fr)`}
                                gap={4}
                                
                                w='100%'
                            // w='calc(100% - px)'
                            >
                                {!isSearching && stories && stories.map((story, index) => (
                                    <GridItem key={story.id} w="100%">
                                        <Box
                                            onClick={() => onSelectStory(index)}
                                            _hover={{ backgroundColor: "darkPurple" }}
                                            userSelect="none"
                                            cursor="pointer"
                                            borderWidth={1}
                                            borderRadius="lg"
                                            borderColor="gray.600"
                                            p={2}
                                            mt={4}
                                            data-test="story-card"
                                            style={{
                                                backgroundImage: "url('/icons/bg.png')",
                                                backgroundSize: "cover",
                                                backgroundBlendMode: 'color',
                                                backgroundRepeat: "repeat-y",
                                                backgroundPosition: "center center",
                                            }}
                                        >
                                            <Flex direction="row">
                                                <FallbackImage
                                                    py={1}
                                                    px={1}
                                                    src={story.imageURL ?? ""}
                                                    fallbackSrc="/icons/story-placeholder-blue.png"
                                                    boxSize="128px"
                                                />
                                                <Box ml={4} display="flex" flexDirection="column" justifyContent="space-between">
                                                    <Box>
                                                        <Text fontSize="md" color="gray.300" mt={0}>
                                                            {t(`story.difficulty_${story.difficulty}`)} - {story.level}
                                                        </Text>
                                                        <Text fontSize="lg" fontWeight="bold" color="white">
                                                            {story.title}
                                                        </Text>
                                                    </Box>
                                                    <Spacer />
                                                    {story.time_to_read_minutes && (
                                                        <Flex direction="row" alignItems="center" mt={1}>
                                                            <RetinaImage src="/icons/alarm-clock.png" boxSize="16px" mr={1} />
                                                            <Text color="white">
                                                                {t('story.time_to_read')}: {t('story.time_in_minutes', { time: story.time_to_read_minutes })}
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </Box>
                                            </Flex>

                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        
                    </Box>

                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                    >
                        <DrawerOverlay />
                        <DrawerContent backgroundColor='darkPurple'>
                            <DrawerCloseButton color='white' />
                            <DrawerHeader color='white'>{t('stories.filters')}</DrawerHeader>

                            <DrawerBody>
                                <VStack spacing={1} alignItems='start'>
                                    <Text color='gray.300'>{t('stories.filters_level')}</Text>
                                    <ComboBox
                                        minWidth={'50px'}
                                        items={filterLevels}
                                        preSelectedItem={filtersLocalSelectedLevel}
                                        onItemSelect={onLevelChange}
                                    />
                                    <Text color='gray.300' mt={4}>{t('stories.filters_language')}</Text>
                                    <ComboBox
                                        disableEmpty={true}
                                        items={filterLanguages}
                                        preSelectedItem={filtersLocalSelectedLanguage}
                                        onItemSelect={onLanguageChange}
                                    />
                                    {/* <Text color='gray.300' mt={4}>{t('stories.filters_categories')}</Text> */}
                                </VStack>
                            </DrawerBody>

                            <DrawerFooter>
                                <Button color='white' variant='outline' mr={3} onClick={onClose}>
                                    {t('modal.cancel')}
                                </Button>
                                <Button colorScheme='blue' onClick={onClickApply}>{t('stories.filters_apply')}</Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </>}
            </Box >
            <Footer />
        </>
    );
}


export default StoryList;

// languages.js

export const LANGUAGES = [{ code: "en", name: "English", localizedName: "English" }].concat([
    { code: "es", name: "Spanish", localizedName: "Español"},
    { code: "ru", name: "Russian", localizedName: "Русский" },
    // { code: "fr", name: "French", localizedName: "Français" },
    // { code: "it", name: "Italian", localizedName: "Italiano" },
    // { code: "de", name: "German", localizedName: "Deutsch"},
    // { code: "sr", name: "Serbian", localizedName: "Српски" },
    // { code: "hr", name: "Croatian", localizedName: "Hrvatski" },
    // { code: "pt", name: "Portuguese", localizedName: "Português" },
    // { code: "nl", name: "Dutch", localizedName: "Nederlands" },
    // { code: "el", name: "Greek", localizedName: "Ελληνικά" },
    // { code: "sv", name: "Swedish", localizedName: "Svenska" },
    // { code: "tr", name: "Turkish", localizedName: "Türkçe" },
  ].sort((a, b) => a.name.localeCompare(b.name) ))

  export const LanguageVariants = {
    defaultVariant: function (languageCode) {
        switch (languageCode) {
            case "es": return "es-MX";
            default: return languageCode;
        }
    },
    parentLanguageForVariant: function (variantCode) {
        for (let languageCode in LanguageVariants) {
            let variants = LanguageVariants[languageCode]
            if (variants[variantCode] && typeof variants[variantCode] === 'object') {
                for (let variant in variants) {
                    if (variant === variantCode) {
                        return languageCode
                    }
                }
            }
        }
        return variantCode
    },
    "es": {
        "es-ES": { "name": "Spanish (Spain)", "code": "es-ES", "description": "Spanish as used in Spain(Castilian variant)" },
        "es-MX": { "name": "Spanish (Mexico)", "code": "es-MX", "description": "Neutral Spanish(mexican variant)" },
        "es-AR": { "name": "Spanish (Argentina)", "code": "es-AR", "description": "Spanish from Argentina (Rioplatense Spanish)" }
    }
}

  export const StoryLanguages = {
    asObject: function() {
        let all = {}

        for (let language of LANGUAGES) {
            let languageCode = language.code
            let variants = LanguageVariants[languageCode] ?? {}
            let variantCodes = Object.keys(variants)

            if(variantCodes.length > 0) {
                for (let variantCode of variantCodes) {
                    let variant = variants[variantCode]
                    all[variantCode] = variant
                }
            } else {
                all[languageCode] = language
            }
        
        }

        return all
    },
    asArray: function() {
        return Object.values(StoryLanguages.asObject())
    }
}

const supportedSnippetLanguageCodes = ['es', "ru"]

export function isLanguageDictionarySupported(languageCode) {
    return supportedSnippetLanguageCodes.includes(languageCode)
}

export function getStoryLanguageWithCode(code) {
    return StoryLanguages.asObject()[code]
}
  
export function getLanguageWithCode(code) {
    if(!code) {
        return null
    }
    return LANGUAGES.find((language) => language.code === code);
}

export function getLanguageWithName(name) {
    if(!name) {
        return null
    }
    return LANGUAGES.find((language) => language.name.toLowerCase() === name.toLowerCase());
}

import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    HStack,
    Spinner,
    Text,
    useToast,
} from "@chakra-ui/react";
import Header from "../header";
import SEO from "../../seo/seo";
import {
    createLayout,
    fetchAnonymousUserClassroomLayout,
    fetchClassroomByID,
    fetchUserClassroomLayout,
    getClassroomSessionID,
    observeAdminClassroomPermissions,
    observeClassroomNotes,
    observeClassroomPermissions,
    observeClassroomSentences,
    observeClassroomSnippets,
    saveClassroomSessionID,
} from "../../api/classroom.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useRecoilState } from "recoil";
import {
    classroomDataState,
    classroomIDState,
    classroomLanguageState,
    classroomLayoutDataState,
    classroomNotesState,
    classroomPermissionsState,
    classroomRoleState,
    classroomSentencesState,
    classroomSnippetsState,
} from "../../state/classroom-state";
import {
    isAuthenticationFinishedState,
    learningLanguageState,
    userLanguageState,
    userState,
} from "../../state/user-state";
import {
    dynamicLayoutComponentDataState,
    localUserSnippetsState,
    snippetsNeedUpdateState,
    snippetsState,
} from "../../state/snippets-state";
import {
    fetchCachedSnippets,
    loadSnippets,
    observeSnippets,
    parseSnippetsDict,
} from "../../api/sentences.service";

import { JoinClassroomUserState } from "./classroom-model";
import { getLanguageWithCode } from "../../utils/languages";
import dateFormat from "dateformat";
import { isPageValidState } from "../../state/app-state";
import { getLayoutComponents } from "../../utils/layout-utils";
import { lazy, Suspense } from "react";
import { useCallback, useMemo } from "react";
import { memo } from "react";
import { exampleClassroomData, exampleClassroomPermissions, getExampleClassroomData } from "../example-classroom-data";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ClassroomRoleEnum } from "./classroom-role";
import { Constants } from "../../utils/constants";
import { exampleClassroomState } from "./example-classroom-state";
const ScreenState = {
    CHECKING_PERMISSIONS: "checking_permissions",
    SHOWING_CLASSROOM: "showing_classroom",
    SHOWING_JOIN_MODAL: "showing_join_modal",
    SHOWING_WAIT_MODAL: "showing_wait_modal",
};
const DynamicLayout = lazy(() => import("../layout/layout"));
const WaitingToJoinClassroomModal = lazy(() =>
    import("./waiting-to-join-classroom-modal")
);
const JoinClassroomModal = lazy(() => import("./join-classroom-modal"));

function ClassroomContent({
    includeSEO = false,
    onClickSwipe,
    exampleUserID,
    showSwipeOnLeft = false
}) {
    const [classroomID, setClassroomID] = useRecoilState(classroomIDState);
    const [classroomRole, setClassroomRole] = useRecoilState(classroomRoleState);
    const [classroomLanguage, setClassroomLanguage] = useRecoilState(
        classroomLanguageState
    );
    const [unsubscribeClassroomPermissions, setUnsubscribeClassroomPermissions] = useState(null);

    const [adminClassroomPermissions, setAdminClassroomPermissions] =
        useRecoilState(classroomPermissionsState);
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);

    const [screenTitle, setScreenTitle] = useState("");

    const [screenState, setScreenState] = useState(
        ScreenState.CHECKING_PERMISSIONS
    );
    const [permissionsData, setPermissionsData] = useState(null);

    const [isObservingPermissions, setIsObservingPermissions] = useState(false);
    const [isObservingAdminPermissions, setIsObservingAdminPermissions] =
        useState(false);

    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [learningLanguage, setLearningLanguage] = useRecoilState(
        learningLanguageState
    );
    const [loadedClassroom, setLoadedClassroom] = useState(false);
    const [classroomNotes, setClassroomNotes] =
        useRecoilState(classroomNotesState);
    const [dynamicLayoutData, setDynamicLayoutComponentDataState] =
        useRecoilState(dynamicLayoutComponentDataState);

    const [userLanguageIdentifier, setUserLanguageIdentifier] = useState(null);
    const [layoutString, setLayoutString] = useState(null);
    const [dynamicLayoutComponents, setDynamicLayoutComponents] = useState(null);

    const [userSnippets, setUserSnippets] = useRecoilState(snippetsState);
    const [userSnippetsNeedUpdate, setUserSnippetsNeedUpdate] = useRecoilState(snippetsNeedUpdateState)
    const [localSnippets, setLocalSnippets] = useRecoilState(
        localUserSnippetsState
    );
    const [classroomData, setClassroomData] = useRecoilState(classroomDataState);
    const [classroomSnippets, setClassroomSnippets] = useRecoilState(
        classroomSnippetsState
    );
    const [classroomSnippetsRawData, setClassroomSnippetsRaw] = useState(null);
    const [classroomSentences, setClassroomSentences] = useRecoilState(
        classroomSentencesState
    );
    const [classroomLayoutData, setClassroomLayoutData] = useState(null);
    const [observeClassroomHandle, setObserveClassroomHandle] = useState(null);
    const [errorFetchingClassroom, setErrorFetchingClassroom] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const [urlRole, setUrlRole] = useState(null);
    const location = useLocation()

    const [unauthedUserSession, setUnauthedUserSession] = useState(null);
    const [isAuthenticationFinished, setIsAuthenticationFinished] =
        useRecoilState(isAuthenticationFinishedState);

    // EXAMPLE CLASSROOM FOR NON-AUTHED USERS
    const [exampleClassroomUserID, setExampleClassroomUserID] = useState(null);
    const [exampleClassroomData, setExampleClassroomData] = useRecoilState(exampleClassroomState);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const toast = useToast();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const role = queryParams.get('role');
        if (role) {
            setUrlRole(role);
        } else {
            setUrlRole(null);
        }
    }, [location]);

    // 1. parse classroom ID initially
    // useEffect(() => {
    //     setClassroomNotes(null);
    //     setIsPageValid(true);
    //     // get classroom id from url /classroom/:id
    //     const url = window.location.pathname;
    //     const id = url.split("/").pop();
    //     setClassroomID(id);

    //     return () => {
    //         setClassroomID(null);
    //         setDynamicLayoutComponentDataState({});
    //         setClassroomLayoutData(null);
    //         setClassroomSnippets(null);
    //         setClassroomData(null);
    //     };
    // }, []);

    useEffect(() => {
        setClassroomNotes(null);
        setIsPageValid(true);

        // Get classroom id only if URL format is /classroom/:id
        const url = window.location.pathname;
        const pathSegments = url.split("/");

        // Check if the format is /classroom/{id}
        if (pathSegments.length === 3 && pathSegments[1] === "classroom") {
            const id = pathSegments[2];
            setClassroomID(id);
            console.log('Setting classroom id to ' + id)
        }

        return () => {
            console.log('Setting classroom id to null')
            setClassroomID(null);
            setDynamicLayoutComponentDataState({});
            setClassroomLayoutData(null);
            setClassroomSnippets(null);
            setClassroomData(null);
        };
    }, []);

    // *** Set screen title ***
    useEffect(() => {
        const classroomName = classroomData?.name;
        const createdAt = classroomData?.createdAt;
        if (userLanguage?.code && classroomName && createdAt && t) {
            // Parse createdAt from milliseconds to desired date format
            // FIXME: Import date format from a user settings
            const format = "dd.mm.yyyy";
            const formattedDate = dateFormat(new Date(createdAt), format);

            // Set screen title with formatted date
            let title = `${t(
                "classrooms.class"
            )} ${classroomName} - ${formattedDate}`;
            console.log("Setting screen title to " + title);
            setScreenTitle(title);
        } else {
            setScreenTitle("");
        }
    }, [userLanguage, classroomData, t]);

    // *** Set classroom language and learning language ***
    useEffect(() => {
        if (classroomData) {
            if (classroomData.languageCode) {
                let lang = getLanguageWithCode(classroomData.languageCode);
                console.log("Setting learning language " + JSON.stringify(lang));
                setLearningLanguage(lang);
                setClassroomLanguage(lang);
                setLoadedClassroom(true);
            } else {
                console.log("No language code in classroomData");
            }
        } else {
            console.log("No classroom data");
        }
    }, [classroomData]);

    // 2. authentication check and observing permissions
    useEffect(() => {
        if (exampleClassroomUserID) {
            setScreenState(ScreenState.SHOWING_CLASSROOM);
            setUserLanguageIdentifier("unauthed");
        } else if (isAuthenticationFinished && classroomID) {
            if (user?.uid && setUserSnippets) {
                console.log("authed");
                // authed
                setUserLanguageIdentifier(`${user.uid}`);
                return beginObservingClassroomPermissions(user.uid, classroomID);
            } else {
                console.log("not authed");
                // not authed
                setUserLanguageIdentifier("unauthed");

                // get classroom session
                let sessionID = getClassroomSessionID(classroomID);

                if (sessionID) {
                    // check if session is valid
                    setUnauthedUserSession(sessionID);
                    return beginObservingClassroomPermissions(sessionID, classroomID);
                } else {
                    // no session ID, show join modal
                    setScreenState(ScreenState.SHOWING_JOIN_MODAL);
                    return;
                }
            }
        }
    }, [user, isAuthenticationFinished, classroomID, setUserSnippets, exampleClassroomUserID]);

    // observe user snippets
    useEffect(() => {
        if (isAuthenticationFinished && userLanguageIdentifier && classroomLanguage?.code && setUserSnippets && userLanguage?.code) {
            if (user?.uid) {
                const unsubscribeUserSnippets = observeSnippets(
                    user.uid,
                    classroomLanguage.code,
                    userLanguage.code,
                    (data) => {
                        setUserSnippets(data);
                        console.log(
                            "User snippets updated " +
                            JSON.stringify(data, null, 2) +
                            " " +
                            classroomLanguage?.code +
                            " " +
                            user?.uid
                        );
                    }
                );
                return () => {
                    if (unsubscribeUserSnippets) {
                        unsubscribeUserSnippets();
                    }
                };
            } else {
                reloadLocalSnippets()
            }
        } else {
            console.log(
                `Not observing user snippets: isAuthenticationFinished: ${isAuthenticationFinished}, userLanguageIdentifier: ${userLanguageIdentifier}, classroomLanguage: ${classroomLanguage}, userLanguage: ${userLanguage}`
            );
        }
    }, [
        setUserSnippets,
        localSnippets,
        user,
        classroomLanguage,
        userLanguageIdentifier,
        isAuthenticationFinished,
        userLanguage
    ]);

    useEffect(() => {
        if(userSnippetsNeedUpdate) {
            reloadLocalSnippets()
        }
    }, [userSnippetsNeedUpdate])

    const reloadLocalSnippets = () => {
        if (!user?.uid && classroomLanguage?.code && userLanguage?.code) {
            return loadSnippets(null, classroomLanguage.code, userLanguage.code).then((data) => {
                console.log(
                    "Local snippets updated " +
                    JSON.stringify(data, null, 2) +
                    " " +
                    classroomLanguage?.code
                );
                setUserSnippets(data ?? []);
            });
        } else {
            return null
        }
    }

    const componentValues = useMemo(
        () => classroomLayoutData?.components || {},
        [classroomLayoutData]
    );

    const types = useMemo(
        () => Object.values(componentValues).map((component) => component.type),
        [componentValues]
    );

    useEffect(() => {
        setLayoutString(classroomLayoutData?.layout);
        if (classroomLayoutData?.components && t) {
            let props = null
            let exampleUserRole = exampleClassroomData?.permissions?.[exampleUserID]?.role
            if(exampleUserID && exampleUserRole) {
                props = { exampleUserID, exampleUserRole }
            }
            let components = getLayoutComponents(types, t, props);

            if (components.length === 0) {
                setDynamicLayoutComponents(null);
            } else {
                setDynamicLayoutComponents(components);
            }
        } else {
            setDynamicLayoutComponents(null);
        }
    }, [classroomLayoutData, t, types]);

    useEffect(() => {
        console.log(`pre obs isAuthenticationFinished: ${isAuthenticationFinished}, classroomID: ${classroomID}, classroomRole: ${classroomRole}, userLanguageIdentifier: ${userLanguageIdentifier}, learningLanguage: ${learningLanguage}, screenState: ${screenState}`);
        if (
            isAuthenticationFinished &&
            classroomID &&
            classroomRole &&
            userLanguageIdentifier &&
            screenState === ScreenState.SHOWING_CLASSROOM
        ) {
            fetchClassroomAndBeginObserving();
            if (user?.uid) {
                console.log('fetchUserClassroomLayout')
                fetchUserClassroomLayout(classroomID, user.uid, classroomRole).then(
                    (data) => {
                        console.log(
                            `Setting classroom layout data for user ${user.uid
                            }: ${JSON.stringify(data, null, 2)}`
                        );
                        setClassroomLayoutData(data);
                    }
                );
            } else {
                console.log('fetchAnonymousUserClassroomLayout')
                let layout = fetchAnonymousUserClassroomLayout(classroomRole);
                console.log(
                    `Setting classroom layout data for anonymous user: ${JSON.stringify(
                        layout,
                        null,
                        2
                    )}`
                );
                setClassroomLayoutData(layout);
            }
        }
    }, [
        t,
        setClassroomLayoutData,
        userLanguageIdentifier,
        isAuthenticationFinished,
        screenState,
        classroomID,
        classroomRole,
    ]);

    useEffect(() => {
        let userID = exampleClassroomUserID || user?.uid || 'unauthed';
        if (
            classroomLanguage &&
            userSnippets &&
            classroomSnippets &&
            classroomLayoutData &&
            setDynamicLayoutComponentDataState &&
            t &&
            userID &&
            classroomRole &&
            screenState === ScreenState.SHOWING_CLASSROOM
        ) {
            createLayout(
                userID,
                dynamicLayoutComponentDataState,
                userSnippets,
                classroomSnippets,
                classroomLayoutData,
                classroomRole,
                setDynamicLayoutComponentDataState,
                t
            );
        } else {
            console.log(
                `Missing data for creating layout classroomLanguage: ${classroomLanguage}, userSnippets: ${userSnippets}, classroomSnippets: ${classroomSnippets}, classroomLayoutData: ${classroomLayoutData}, classroomRole: ${classroomRole}, screenState: ${screenState}`
            );
        }
    }, [
        classroomLanguage,
        classroomSnippetsRawData,
        userSnippets,
        classroomSnippets,
        classroomLayoutData,
        t,
        user,
        exampleClassroomUserID,
        setDynamicLayoutComponentDataState,
        screenState,
        classroomRole,
    ]);

    const beginObservingAdminPermissions = useCallback(
        (classroomID) => {
            console.log(`beginObservingAdminPermissions called, is ${isObservingAdminPermissions} cid ${classroomID}`);
            if (isObservingAdminPermissions || !classroomID) {
                return;
            }

            console.log("beginObservingAdminPermissions for classroom " + classroomID);
            setIsObservingAdminPermissions(true);
            const unsubscribeClassroomPermissions = observeAdminClassroomPermissions(
                classroomID,
                (data) => {
                    console.log("Fetched admin permissions data " + JSON.stringify(data));
                    setAdminClassroomPermissions(data);
                }
            );

            return () => {
                if (unsubscribeClassroomPermissions) {
                    unsubscribeClassroomPermissions();
                }
            };
        },
        [isObservingAdminPermissions, setAdminClassroomPermissions]
    );

    const beginObservingClassroomPermissions = useCallback(
        (keyToObserve, classroomID) => {
            console.log("beginObservingClassroomPermissions: Requested observing for classroom " + classroomID + " with key " + keyToObserve);

            if (isObservingPermissions || exampleClassroomUserID) {
                console.log("beginObservingClassroomPermissions: Already observing permissions");
                return;
            }

            console.log("beginObservingClassroomPermissions: Start observing for classroom " + classroomID + " with key " + keyToObserve);
            setIsObservingPermissions(true);

            // Start observing classroom permissions
            const unsubscribe = observeClassroomPermissions(
                classroomID,
                keyToObserve,
                (data) => {
                    console.log('observeClassroomPermissions callback with data ' + JSON.stringify(data));
                    if (data) {
                        let state = data?.state;
                        setUserPermissions(state, data?.role, classroomID);
                    } else {
                        console.log('No data fetched, showing join modal');
                        setScreenState(ScreenState.SHOWING_JOIN_MODAL);
                    }
                }
            );

            setUnsubscribeClassroomPermissions(() => unsubscribe); // Store the unsubscribe function
        },
        [
            isObservingPermissions,
            exampleClassroomUserID,
            toast,
            t,
            beginObservingAdminPermissions,
            setClassroomRole,
            setScreenState,
        ]
    );

    function setUserPermissions(state, role, classroomID) {
        console.log("setting classroom role " + role + " cid " + classroomID);
        setClassroomRole(role);
        beginObservingAdminPermissions(classroomID);

        if (state === JoinClassroomUserState.PENDING) {
            setScreenState(ScreenState.SHOWING_WAIT_MODAL);
        } else if (state === JoinClassroomUserState.ACCEPTED) {
            setScreenState(ScreenState.SHOWING_CLASSROOM);
        } else if (state === JoinClassroomUserState.REJECTED) {
            toast({
                title: t("classrooms.error_joining_classroom_request_rejected"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            saveClassroomSessionID(classroomID, null);
            setIsObservingPermissions(false);
            setScreenState(ScreenState.SHOWING_JOIN_MODAL);
        }
    };


    const onCloseJoinModal = useCallback(
        (keyToObserve, classroomID) => {
            // user successfully submitted request to join the classroom; now we need to observe the classroom permissions
            setScreenState(ScreenState.SHOWING_WAIT_MODAL);
            return beginObservingClassroomPermissions(keyToObserve, classroomID);
        },
        [beginObservingClassroomPermissions, setScreenState]
    );

    useEffect(() => {
        if (errorFetchingClassroom) {
            navigate("/classrooms");
        }
    }, [errorFetchingClassroom, navigate]);

    const fetchClassroomAndBeginObserving = async () => {
        if (exampleClassroomUserID) {
            return
        }
        let id = classroomID;
        if (id) {
            let classroomLanguageCode = null
            try {
                let data = await fetchClassroomByID(id);
                console.log("Fetched classroom data " + JSON.stringify(data, null, 2));
                classroomLanguageCode = data.languageCode
                setClassroomData(data);
            } catch (error) {
                setErrorFetchingClassroom(true);
            }

            const unsubscribeSnippets = observeClassroomSnippets(id, (data) => {
                return fetchCachedSnippets(data, userLanguage.code, classroomLanguageCode).then((snippets) => {
                    setClassroomSnippets(snippets);
                })
            });

            const unsubscribeNotes = observeClassroomNotes(id, (data) => {
                setClassroomNotes(data);
            });

            const unsubscribeSentences = observeClassroomSentences(id, (data) => {
                setClassroomSentences(data);
            });

            // Return a cleanup function that removes the listener when the component is unmounted
            return () => {
                if (unsubscribeSnippets) {
                    unsubscribeSnippets();
                }
                if (unsubscribeNotes) {
                    unsubscribeNotes();
                }

                if (unsubscribeSentences) {
                    unsubscribeSentences();
                }
            };
        } else {
            console.error("No classroom ID");
            navigate("/classrooms");
        }
    };

    // EXAMPLE CLASSROOM
    useEffect(() => {
        setExampleClassroomUserID(exampleUserID);
    }, [exampleUserID]);

    useEffect(() => {
        console.log('onRefresh')
        if (exampleClassroomUserID && exampleClassroomData && userSnippets) {
            let permissions = exampleClassroomPermissions
            const role = permissions[exampleClassroomUserID].role
            const state = permissions[exampleClassroomUserID].state
            console.log(`setting classroom role ${role} for user ${exampleClassroomUserID} with state ${state}`)
            setUserPermissions(state, role, exampleClassroomData.id);
            // load classroom data

            let layout = (role === ClassroomRoleEnum.TEACHER ? Constants.DynamicLayout.DEFAULT_TEACHER_LAYOUT : Constants.DynamicLayout.DEFAULT_STUDENT_LAYOUT)
            console.log("Layout is " + JSON.stringify(layout, null, 2))
            setClassroomLayoutData(layout);

            let userID = exampleClassroomUserID || user?.uid
            if (!exampleClassroomData?.classroomData?.classroomSnippets) {
                console.log('returning, no classroom snippets')
                return;
            }
            createLayout(userID, dynamicLayoutComponentDataState, userSnippets, exampleClassroomData.classroomData.classroomSnippets, layout, role, setDynamicLayoutComponentDataState, t)
        } else {
            // console.log(`Cant create layout: exampleClassroomUserID: ${exampleClassroomUserID}, exampleClassroomData: ${exampleClassroomData}, userSnippets: ${JSON.stringify(userSnippets, null, 2)}`)
        }
    }, [exampleClassroomUserID, classroomLanguage, exampleClassroomData, classroomLayoutData, classroomData, learningLanguage?.code, setClassroomData, userSnippets, setDynamicLayoutComponentDataState, user, t]);

    useEffect(() => {
        if (exampleClassroomUserID && exampleClassroomData && !classroomData) {
            setClassroomData(exampleClassroomData.classroomData);
        }
    }, [exampleClassroomData, exampleClassroomUserID])

    return (
        <>
            {includeSEO && <SEO pageKey='classroom' title={screenTitle} />}

            {screenState === ScreenState.SHOWING_CLASSROOM && (
                <>
                    {layoutString && dynamicLayoutComponents && (
                        <HStack as="main" spacing={4} flexGrow={1} h="calc(100vh - 64px)">
                            <Box mt="8px" mb="8px" h="calc(100% - 16px)" w="100%">
                                <Suspense fallback={<Spinner />}>
                                    <DynamicLayout
                                        layoutPattern={layoutString}
                                        components={dynamicLayoutComponents}
                                        exampleUserID={exampleClassroomUserID}
                                    />
                                </Suspense>
                            </Box>
                        </HStack>
                    )}
                </>
            )}

            {screenState === ScreenState.SHOWING_JOIN_MODAL && (
                <Suspense fallback={<Spinner />}>
                    <JoinClassroomModal
                        isOpen={screenState === ScreenState.SHOWING_JOIN_MODAL}
                        onClose={onCloseJoinModal}
                        preselectedRole={urlRole}
                    />
                </Suspense>
            )}
            {screenState === ScreenState.SHOWING_WAIT_MODAL && (
                <Suspense fallback={<Spinner />}>
                    <WaitingToJoinClassroomModal />
                </Suspense>
            )}

            {exampleClassroomUserID && <Button
                id={`classroomSwipe${showSwipeOnLeft ? 'Left' : 'Right'}`}
                onClick={onClickSwipe}
                position="fixed"
                bottom="8px"
                right={showSwipeOnLeft ? undefined : "0px"}
                left={showSwipeOnLeft ? "0px" : undefined}

                colorScheme={showSwipeOnLeft ? "green" : "red"}
                boxShadow="lg"
            >
                {showSwipeOnLeft && <ChevronLeftIcon boxSize={6} />}
                <Text>{showSwipeOnLeft ? t('clasrooms.example_see_student_screen') : t('clasrooms.example_see_teacher_screen')} </Text>
                {!showSwipeOnLeft && <ChevronRightIcon boxSize={6} />}
            </Button>}
        </>
    );
}


export default memo(ClassroomContent);

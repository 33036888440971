import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState, userState } from "../state/user-state";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";
import { Box, Center, Divider, Spinner, Text, useToast } from "@chakra-ui/react";
import SEO from "../seo/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import SnippetTable from "../snippets/snippet-table";
import { dictionarySearch, loadStorySnippets } from "../api/translation.service";
import { convertDictionarySearchResultToSnippetModels } from "../utils/snippet-utils";
import DictionarySearchResults from "../components/dictionary-search-result";
import { selectedStoryMappingState } from "../state/story-state";
import { Constants } from "../utils/constants";
import { getStory } from "../api/story.service";
import { isPageValidState } from "../state/app-state";
import { getLanguageWithCode, getStoryLanguageWithCode, LANGUAGES, LanguageVariants } from "../utils/languages";
import ChooseLanguageContent from "./choose-language/choose-language-content";
import { updateLanguages } from "../api/user-language.service";
import StoryDetails from "../stories/story-details";
import Tutorial, { isTutorialCompleted, setTutorialCompleted } from "../components/tutorial";
import { clearLocalStorageKey } from "../api/localStorage";
import { copyJSONObject } from "../utils/utils";

const ExampleStoryScreenState = {
    CHOOSE_LANGUAGE: "choose-language",
    STORY: "story"
}

let exampleStoryActiveRequestId = 0; // Tracks the active request ID

function ExampleStory() {
    // check sentence modal
    // state
    const [story, setStory] = useState(null);
    const [resultSnippets, setResultSnippets] = useState(null);
    const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);
    const [storyName, setStoryName] = useState(null);
    const [isPageValid, setIsPageValid] = useRecoilState(isPageValidState);
    const [selectedMapping, setSelectedMapping] = useRecoilState(selectedStoryMappingState)
    const [storyLanguage, setStoryLanguage] = useState(null)
    const [dictionaryLanguage, setDictionaryLanguage] = useState(null)

    const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState)

    // tutorial
    const [isFirstMappingSelected, setIsFirstMappingSelected] = useState(false)
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
    
    const navigate = useNavigate();
    const toast = useToast();
    const { t, i18n, ready: tReady } = useTranslation();

    const {
        user,
        userLanguage,
        setUserLanguage,
        authenticationFinished,
        isInitiallyLoggedIn,
    } = useUser();

    // example story
    const [screenState, setScreenState] = useState(null);
    const [tutorialSteps, setTutorialSteps] = useState([])

    useEffect(() => {
        if(!userLanguage || !tReady || !authenticationFinished || !i18n) {
            return
        }

        // clearLocalStorageKey(Constants.Tutorial.Key.ExampleStory)

        i18n.changeLanguage(userLanguage.code).then(() => {
            setIsLanguageLoaded(true)
        }).catch((error) => {
            console.error('Error changing language', error)
        })
       
    }, [userLanguage, tReady, i18n, authenticationFinished])

    useEffect(() => {
        if(!isLanguageLoaded || isTutorialCompleted(Constants.Tutorial.Key.ExampleStory)) {
            return
        }

        //     "story::tutorial_step_tap_any_word": "This is the story text. Tap any word to see its translation.",
    // "story::tutorial_step_translations": "Here are the translations for the selected word.",
    // "story::tutorial_step_mark_as_completed": "Tap here to mark the story as completed.",
    // "story::tutorial_step_see_options" : "Tap here to see the story options",

        let steps = [
            {
                id: "storyDetails",
                message: t('story.tutorial_step_tap_any_word'),
                offset: { top: -256 },
                position: "bottom",
                completed: isFirstMappingSelected,
            },
            {
                id: "searchResultsExampleAnchor",
                message: t('story.tutorial_step_translations'),
                position: "left",
                showCompleteButton: true
            },
        ]

        const delay = tutorialSteps.length === 0 ? 1500 : 0
        setTimeout(() => {
            setTutorialSteps(steps)
        }, delay)
    }, [isLanguageLoaded, isFirstMappingSelected])

    useEffect(() => {
        if (authenticationFinished) {
            if (user) {
                navigate("/stories", { replace: true });
            } else { // example data
                if (!learningLanguage) {
                    setScreenState(ExampleStoryScreenState.CHOOSE_LANGUAGE);
                } else {
                    setScreenState(ExampleStoryScreenState.STORY);
                    // load story data
                    let code = learningLanguage.code
                    let example = Constants.ExampleStory[code]
                    if (example && example?.languageCode && example?.id) {
                        setupStory(example.languageCode, example.id)
                    } else {
                        // missing example story
                        // navigate('/')
                    }
                }
            }
        }
    }, [authenticationFinished, user, learningLanguage, userLanguage]);


    function setupStory(language, id) {
        const storyLanguage = getStoryLanguageWithCode(language)

        // check if language is valid
        if (!storyLanguage) {
            // invalid language
            navigate('/stories')
            return;
        }

        setStoryLanguage(storyLanguage)

        let parentCode = LanguageVariants.parentLanguageForVariant(storyLanguage.code)

        let parentLanguage = getLanguageWithCode(parentCode)

        console.log(`Dictionary language ${parentCode} ${JSON.stringify(parentLanguage, null, 2)}`)
        setDictionaryLanguage(parentLanguage)

        setSelectedMapping(null)
        console.log('Calling getStory', id, language)
        getStory(id, language).then((story) => {

            if (!story) {
                toast({
                    title: t('story.story_not_found'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                // story not found
                navigate('/stories')
                return;
            }

            setIsPageValid(true)
            console.log('Fetched Story ', JSON.stringify(story, null, 2))
            for (let sentenceKey in story.sentences) {
                let sentence = story.sentences[sentenceKey];
                if (sentence.mappings) {
                    sentence.mappings = sentence.mappings.sort((a, b) => a.range.location - b.range.location);
                }
            }

            if (typeof story.sentences === 'object') {
                story.sentences = Object.values(story.sentences);
            }

            setStory(story);

            setStoryName(`${story.title} ${storyLanguage.name} ${t('story.story')} - ${Constants.PAGE_TITLE_APP_NAME}`);
        }).catch((error) => {
            console.log('cant fetch story', error)
        })
    }

    const onSelectMapping = (() => {
        // Return the actual function that takes `mapping` as a parameter
        return async (mapping) => {
            const currentRequestId = ++exampleStoryActiveRequestId; // Increment and track the current request
            setSelectedMapping({ mapping: mapping, story: story });
            setResultSnippets(null);
    
            if (!mapping) {
                return;
            }
    
            setIsFirstMappingSelected(true);
            console.log("onSelectMapping", mapping);
    
            let learningLanguageCode = dictionaryLanguage?.code;
    
            if (!learningLanguageCode) {
                console.log("return");
                return;
            }
    
            try {
                setIsLoadingSnippet(true);
                
                let definitions =
                    story.selected_definitions
                let snippets = await loadStorySnippets(
                    copyJSONObject(mapping),
                    definitions,
                    learningLanguageCode,
                    userLanguage.code,
                    t
                );
    
                // Only update if the request is still the latest
                if (currentRequestId === exampleStoryActiveRequestId) {
                    setResultSnippets(snippets.snippets);
                }
            } catch (error) {
                console.error(error);
    
                // Only clear if the request is still the latest
                if (currentRequestId === exampleStoryActiveRequestId) {
                    setResultSnippets(null);
                }
            } finally {
                // Only update loading state if the request is still the latest
                if (currentRequestId === exampleStoryActiveRequestId) {
                    setIsLoadingSnippet(false);
                }
            }
        };
    })();
    // const onSelectMapping = async (mapping) => {
    //     setSelectedMapping({ mapping: mapping, story: story })
    //     setResultSnippets(null)

    //     if (!mapping) {
    //         return
    //     }

    //     setIsFirstMappingSelected(true)
    //     console.log('onSelectMapping', mapping)

    //     let learningLanguageCode = dictionaryLanguage?.code

    //     if (!learningLanguageCode) {
    //         console.log('return')
    //         return
    //     }

    //     try {
    //         setIsLoadingSnippet(true)

    //         let definitions = story.selected_definitions?.[userLanguage.code] ?? {}
    //         let snippets = await loadStorySnippets(mapping, definitions, learningLanguageCode, userLanguage.code, t)

    //         setResultSnippets(snippets.snippets)
    //     } catch (error) {
    //         console.error(error)
    //         setResultSnippets(null)
    //     } finally {
    //         setIsLoadingSnippet(false)
    //     }
    // }

    // EXAMPLE STORY
    const onUpdateLanguages = (userLanguage, learningLanguage) => {
        if (!userLanguage || !learningLanguage) {
            return;
        }

        console.log('onUpdateLanguages ' + userLanguage + ' ' + learningLanguage)

        updateLanguages(null, userLanguage, learningLanguage)
        setLearningLanguage(learningLanguage);
        setUserLanguage(userLanguage);
    }

    return (
        <>
            <Header />

            <Box
                data-test="story-page"
                h="calc(100vh - 128px)"
                flexDirection="column"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                <SEO pageKey="story" title={storyName} />

                {screenState === ExampleStoryScreenState.CHOOSE_LANGUAGE && <Center as="main" h="calc(100vh - 65px)">
                    <ChooseLanguageContent learningLanguageSubtitle="" onUpdate={onUpdateLanguages} />
                </Center>}

                {screenState === ExampleStoryScreenState.STORY && story && <Box data-test='main-box' h='100%' py={2}>
                    <Tutorial steps={tutorialSteps} onComplete={() => {
                        setTutorialCompleted(Constants.Tutorial.Key.ExampleStory)   
                    }}/>
                    <Box display="flex" height="100%" >
                        <Box flex="7" px="4" h='100%'>
                            {/* Content for the 70% width panel */}
                            <StoryDetails showExampleSection={true} story={story} onSelectMapping={onSelectMapping} hideBackToStories={true} id='storyDetails' />
                        </Box>
                        <Divider h='100%' opacity={0.2} orientation="vertical" />
                        <Box flex="3" h='100%'>
                            {selectedMapping?.mapping?.text && <Box
                                h='36px'
                                direction='column'
                                alignContent='center'
                                borderRadius='md'
                                alignItems='center'
                                backgroundColor='rgba(255, 255, 255, 0.1)'
                                mx={2}
                                px={2}
                            >
                                <Center>
                                    <Text fontSize='xl' fontWeight='bold' color='white'>{selectedMapping.mapping.text}</Text>
                                    {isLoadingSnippet && <Box ml={2}>
                                        <Spinner color='gray' />

                                    </Box>}
                                </Center>
                            </Box>}
                            {/* Content for the 30% width panel */}
                            <Box overflowY="auto" h='calc(100% - 36px)'>
                            <Box id='searchResultsExampleAnchor' position="absolute" w='32px' h='240px' />
                                {resultSnippets && <DictionarySearchResults highlightedText={selectedMapping?.mapping?.text?.toLowerCase()} results={resultSnippets} classroomID={null} showsCreateButton={false}  canPinSnippet={false}/>}
                            </Box>

                        </Box>
                    </Box>
                </Box>
                }

            </Box>

            <Footer />
        </>
    );
}

export default ExampleStory;

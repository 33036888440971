import { useEffect, useState } from "react";
import {
    Box,
    VStack,
    Heading,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button,
    Text,
    useToast,
    Center,
} from "@chakra-ui/react";
import Header from "./header";
import SEO from "../seo/seo";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../state/user-state";
import { Constants } from "../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword, verifyResetPasswordToken } from "../api/user.service";

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [isVerifyingToken, setIsVerifyingToken] = useState(false);
    const [isTokenVerified, setIsTokenVerified] = useState(false);

    const toast = useToast();

    const { t, ready } = useTranslation();

    const token = searchParams.get("token");

    useEffect(() => {
        if(!ready || isVerifyingToken) {
            return
        }
        
        function redirectToHomeAndShowError(customError) {
            toast({
                title: t('reset_password.invalid_link'),
                description: customError ?? t('reset_password.invalid_link_description'),
                status: "error",
                duration: 8000,
                isClosable: true,
            });
            navigate("/"); // Redirect to home
        }

        // Redirect to home if token is missing
        if (!token) {
            redirectToHomeAndShowError();
        }

        setIsVerifyingToken(true);
        verifyResetPasswordToken(token).then((response) => {
            setIsTokenVerified(true);
        }).catch((error) => {
            redirectToHomeAndShowError(error.message);
        })


    }, [token, navigate, toast, t]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        // Validate passwords match
        if (password !== confirmPassword) {
            setError(t('register.password_mismatch'));
            return;
        } else {
            let minLength = Constants.PASSWORD_MIN_LENGTH;
            if (password.length < minLength) {
                setError(t("register.password_length_error", { length: minLength }));
                return;
            }
        }

        // Simulate API request for password reset
        setLoading(true);
        try {
            // Replace with actual API call, e.g., fetch or axios
            await resetPassword(token, password);

            toast({
                title: t('reset_password.success'),
                description: t('reset_password.login_instructions'),
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setSuccess(true);
        } catch (error) {
            setError("Failed to reset password. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Box
                as="main"
                h="calc(100vh - 128px)"
                data-test="reset-password-page"
                style={{
                    backgroundImage: "url('/icons/bg.png')",
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "center center",
                }}
            >
                <SEO pageKey={"reset_password"} />
                {userLanguage && <Box w='100%' h='100%'>
                    <Box
                        maxW="400px"
                        mx="auto"
                        color='white'
                        pt={16}
                        p="8"
                        borderRadius="lg"
                        boxShadow="lg"
                    >
                        <Heading as="h2" size="lg" mb="6" textAlign="center">
                            {t("reset_password.page_title")}
                        </Heading>
                        <form onSubmit={handleSubmit}>
                            <VStack spacing="4">
                                <FormControl isRequired isInvalid={!!error}>
                                    <FormLabel>{t('reset_password.new_password')}</FormLabel>
                                    <Input
                                        isDisabled={success}
                                        type="password"
                                        placeholder={t('reset_password.new_password')}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl isRequired isInvalid={!!error}>
                                    <FormLabel>{t('reset_password.confirm_password')}</FormLabel>
                                    <Input
                                        isDisabled={success}
                                        type="password"
                                        placeholder={t('reset_password.confirm_password')}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    {error && <FormErrorMessage>{error}</FormErrorMessage>}
                                </FormControl>
                                <Button
                                    isDisabled={success}
                                    type="submit"
                                    colorScheme="blue"
                                    isLoading={loading}
                                    loadingText={t('reset_password.loading_title')}
                                    w="full"
                                >
                                    {t('reset_password.submit')}
                                </Button>
                            </VStack>
                        </form>
                    </Box>
                </Box>}
            </Box>
            <Footer />
        </>
    );
}

export default ResetPassword;

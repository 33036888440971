import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, Grid, GridItem, IconButton, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { random8ID } from '../utils/utils';
import RetinaImage from '../components/retina-image';

let colorText = 'white'
let colorSelected = 'purple.500'

const AdminClickableText = ({ sentences, onSelectMappings }) => {
  const [selectedWords, setSelectedWords] = useState([]);

  function nonClickableText(text, sentenceIndex, wordIndex) {
    return (
      <Box
        as="span"
        key={random8ID()}
        bg={colorSelected}
        color={colorText}
        borderRadius="md"
      >
        {text}
      </Box>
    )
  }

  const handleClick = (sentenceIndex, wordIndex) => {
    const selectedWord = { sentenceIndex, wordIndex };
    const isAlreadySelected = selectedWords.some(
      (word) =>
        word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex
    );

    let updatedSelections;

    if (isAlreadySelected) {
      // Deselect the word
      updatedSelections = selectedWords.filter(
        (word) =>
          !(word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex)
      );
    } else {
      // Ensure selection is limited to one sentence
      if (selectedWords.length === 0 || selectedWords[0].sentenceIndex === sentenceIndex) {
        updatedSelections = [...selectedWords, selectedWord];
      } else {
        return; // Ignore the selection if it's from a different sentence
      }
    }

    setSelectedWords(updatedSelections);

    const selectedMappings = updatedSelections.map(({ sentenceIndex, wordIndex }) =>
      sentences[sentenceIndex].mappings[wordIndex]
    );

    onSelectMappings?.(selectedMappings);
  };

  const renderText = () => {
    let renderedText = [];

    sentences.forEach((sentence, sentenceIndex) => {
      if (sentence.text) {
        let currentIndex = 0;
        const { text, mappings } = sentence;

        mappings.forEach((mapping, wordIndex) => {
          const { text: word, range } = mapping;

          if (currentIndex < range.location) {
            const nonMappedText = text.slice(currentIndex, range.location);
            renderedText.push(nonClickableText(nonMappedText, sentenceIndex, wordIndex));
          }

          const isSelected = selectedWords.some(
            (word) =>
              word.sentenceIndex === sentenceIndex && word.wordIndex === wordIndex
          );

          renderedText.push(
            <Box
              as="span"
              key={random8ID()}
              onClick={() => handleClick(sentenceIndex, wordIndex)}
              cursor="pointer"
              bg={isSelected ? "white" : "purple.500"}
              color={isSelected ? "purple.500" : "white"}
              borderRadius="md"
            >
              {word}
            </Box>
          );

          currentIndex = range.location + range.length;
        });

        if (currentIndex < text.length) {
          const remainingText = text.slice(currentIndex);
          renderedText.push(nonClickableText(remainingText, sentenceIndex, 0));
        }
      }

      if (sentence.new_line) {
        renderedText.push(<br key={random8ID()} />);
      }

      renderedText.push(' ');
    });

    return renderedText;
  };


  return (
    <Box>
      {renderText()}
    </Box>
  );
};


const ClickableText = ({ sentences, onSelectMapping, ...props }) => {
  const [selectedWordIndex, setSelectedWordIndex] = useState(null);

  const handleClick = (sentenceIndex, wordIndex) => {
    const isSelected = selectedWordIndex?.sentenceIndex === sentenceIndex && selectedWordIndex?.wordIndex === wordIndex;
    const newSelection = isSelected ? null : { sentenceIndex, wordIndex };
    setSelectedWordIndex(newSelection);
    const mapping = newSelection ? sentences[sentenceIndex].mappings[wordIndex] : null;
    onSelectMapping?.(mapping);
  };

  const renderText = () => {
    let renderedText = [];

    function rng(index, length) {
      return `(${index}, ${length})`;
    }

    function nonClickableText(text, sentenceIndex, wordIndex) {
      // console.log(`nonClickableText '${text}'`);
      return (
        <Box
          as="span"
          key={random8ID()}
          bg={colorSelected}
          color={colorText}
          borderRadius="md"
        >
          {text}
        </Box>
      )
    }

    sentences.forEach((sentence, sentenceIndex) => {
      if (sentence.text) {
        // console.log('sentence: ', sentence.text);
        let currentIndex = 0;
        const { text, mappings } = sentence;

        mappings.forEach((mapping, wordIndex) => {
          const { text: word, range } = mapping;

          if (currentIndex < range.location) {
            const nonMappedText = text.slice(currentIndex, range.location);
            // console.log(`nonMappedText ${nonMappedText} ${rng(currentIndex, range.length)}`);
            renderedText.push(nonClickableText(nonMappedText, sentenceIndex, wordIndex));
          }

          const isSelected = selectedWordIndex?.sentenceIndex === sentenceIndex && selectedWordIndex?.wordIndex === wordIndex;
          
          renderedText.push(
            <Box
              as="span"
              key={random8ID()}
              onClick={() => handleClick(sentenceIndex, wordIndex)}
              cursor="pointer"
              bg={isSelected ? colorText : colorSelected}
              color={isSelected ? colorSelected : colorText}
              borderRadius="md"
            >
              {word}
            </Box>
          );
          // console.log(`word ${word} ${rng(range.location, range.length)}`);

          currentIndex = range.location + range.length;
          // console.log('new index is ', currentIndex);
        });


        if (currentIndex < text.length) {
          const remainingText = text.slice(currentIndex);
          // console.log(`remainingText ${remainingText} ${rng(currentIndex, text.length - currentIndex)}`);
          renderedText.push(nonClickableText(remainingText, 10000, 10000));
        } 

        // console.log('add space');
        // add space
        renderedText.push(' ');
      }

      if (sentence.new_line) {
        renderedText.push(<br key={random8ID()} />);
      }
    });

    return renderedText;
  };

  return (
    <Box {...props} >
      {renderText()}
    </Box>
  );
};


function StoryDetails({ story, showExampleSection = false, onSelectMapping, adminMode, hideBackToStories, ...props }) {
  const [storyLevel, setStoryLevel] = useState(null);
  const [canGoBackToStories, setCanGoBackToStories] = useState(!hideBackToStories);
  const [headerHeight, setHeaderHeight] = useState(0); // Track header height
  const headerRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (story) {
      setStoryLevel(`${story.level} ${t('story.level').toLowerCase()}`);
    } else {
      setStoryLevel(null);
    }
  }, [story]);

  const onBackToStoriesClicked = () => {
    navigate('/stories');
  };

  useEffect(() => {
    const headerElement = headerRef.current;

    if (headerElement) {
      const resizeObserver = new ResizeObserver(() => {
        setHeaderHeight(headerElement.offsetHeight); // Update header height
      });

      resizeObserver.observe(headerElement);

      return () => {
        resizeObserver.disconnect(); // Cleanup observer on unmount
      };
    }
  }, []);

  return (
    <Box data-test="story-details" w="100%" h="100%" {...props}>
      {story && (
        <Box
          borderRadius="md"
          backgroundColor="rgba(255, 255, 255, 0.1)"
          h="100%"
        >
          <Grid
            templateColumns="repeat(10, 1fr)"
            alignItems="center"
            p={1}
            ref={headerRef}
            id="story-header"
            borderRadius="md"
            backgroundColor="rgba(255, 255, 255, 0.1)"
          >
            <GridItem colSpan={3} area="backButton">
              {canGoBackToStories && (
                <Button
                  ml={2}
                  _hover={{ backgroundColor: "purple", color: "white" }}
                  leftIcon={<IoMdArrowRoundBack />}
                  backgroundColor="transparent"
                  borderWidth={1}
                  borderColor="gray.600"
                  color="white"
                  size="lg"
                  data-test="filters-button"
                  onClick={onBackToStoriesClicked}
                >
                  {t("story.back_to_stories")}
                </Button>
              )}
            </GridItem>

            <GridItem colSpan={4} area="titlePanel" textAlign="center">
              <Box>
                <Text fontWeight="semibold" fontSize="xl" color="gray.100">
                  {story.title}
                </Text>
                <Text fontSize="sm" color="gray.300">
                  {storyLevel}
                </Text>
              </Box>
            </GridItem>

            <GridItem colSpan={3} area="exampleSection">
              {showExampleSection && (
                <Flex
                  h="100%"
                  borderRadius={8}
                  borderWidth={1}
                  borderColor="gray.500"
                  mr={1}
                  p={1}
                  fontWeight="semibold"
                  backgroundColor="lightPurple"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RetinaImage mx={1} width="44px" height="44px" src="icons/books.png" />
                  <Text fontSize="md" ml={2} color="gray.200">
                    {t('story.example_story_description')}
                  </Text>
                </Flex>
              )}
            </GridItem>
          </Grid>

          <Box px={6} pt={4} h={`calc(100% - ${headerHeight}px)`} overflowY="auto">
            {adminMode ? (
              <AdminClickableText
                sentences={story.sentences}
                onSelectMappings={(mappings) => {
                  onSelectMapping?.(mappings);
                }}
              />
            ) : (
              <ClickableText
                id="story-clickable-text"
                sentences={story.sentences}
                onSelectMapping={(mapping) => {
                  onSelectMapping?.(mapping);
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default StoryDetails;

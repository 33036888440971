import {
    Box,
    Button,
    Center,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
  } from "@chakra-ui/react";
  import { Constants } from "../../utils/constants";
  import { useTranslation } from "react-i18next";
  import CopyToClipboardButton from "../copy-button";
  import { FaShareSquare } from "react-icons/fa";
  import { useState } from "react";
  
  function ClassroomSharePopover({ elementID }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(true); // Show popover initially
  
    let targetElement = document.getElementById(elementID);
  
    if (!targetElement) {
      return null;
    }
  
    const adjustedTop = targetElement.offsetTop;
    const adjustedLeft = targetElement.offsetLeft;
  
    const handleClose = () => {
      setIsOpen(false); // Close the popover
    };
  
    return (
      <Popover
        isOpen={isOpen}
        onClose={handleClose} // Close on user interaction
        placement="bottom"
        closeOnBlur={true}
        closeOnEsc={true}
        modifiers={[
          {
            name: "zIndex",
            enabled: true,
            phase: "write",
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = Constants.zIndex.popover;
            },
          },
        ]}
      >
        <PopoverTrigger>
          <Box
            position="absolute"
            top={`${adjustedTop}px`}
            left={`${adjustedLeft}px`}
            width={`${targetElement.offsetWidth}px`}
            height={`${targetElement.offsetHeight}px`}
            opacity="0"
            pointerEvents="none"
          />
        </PopoverTrigger>
        <PopoverContent borderRadius="md" borderWidth={0.5} borderColor="black">
          <PopoverArrow />
          <PopoverHeader
            color="white"
            fontWeight="bold"
            style={{
              backgroundImage: "url('/icons/bg.png')",
              backgroundSize: "cover",
              backgroundRepeat: "repeat-y",
              backgroundPosition: "center center",
            }}
          >
            <Center>
              <Flex direction="row" justifyContent="start" alignItems="center">
                <FaShareSquare color="white" w="36px" h="36px" />
                <Text ml={1}>{t('classroom.info_share_classroom_title')}</Text>
              </Flex>
            </Center>
          </PopoverHeader>
          <PopoverBody
            color="white"
            style={{
              backgroundImage: "url('/icons/bg.png')",
              backgroundSize: "cover",
              backgroundRepeat: "repeat-y",
              backgroundPosition: "center center",
            }}
          >
            {/* Use the separate component for popover body content */}
            <ShareClassroomPanel
              t={t}
              onCopy={handleClose} // Close on copy
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
  
  // Popover Body Content as a Separate Component
  export function ShareClassroomPanel({ t, onCopy, ...props }) {
    return (
      <Box {...props}>
        <Text>{t("classroom.info_share_classroom")}</Text>
        <Flex direction="row" justifyContent="start" alignItems="center" mt={2}>
          <CopyToClipboardButton text={window.location.href} onClick={onCopy} />
          <Text ml={2}>{t("classroom.info_share_classroom_copy")}</Text>
        </Flex>
      </Box>
    );
  }
  
  export default ClassroomSharePopover;
  
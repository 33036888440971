import { Box, Image, Text } from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools"; // Chakra's darken function
import { useState } from "react";

const defaultHoverColor = "purple";

function MenuComboBoxItem({
  text,
  onClick,
  icon,
  backgroundColor = "purple",
  textColor = "white",
  imageUrl,
}) {
  const [hoverColor, setHoverColor] = useState('filterPurple');
  const [backgroundColorState, setBackgroundColorState] = useState(defaultHoverColor);

  useState(() => {
    setBackgroundColorState(backgroundColor || defaultHoverColor);
  }, [backgroundColor]);

  return (
    <Box
      onClick={onClick}
      bg={backgroundColorState}
      _hover={{ bg: hoverColor }}
      color={textColor}
      px={4} // Horizontal padding
      py={2} // Reduced vertical padding for compact height
      cursor="pointer"
      display="flex"
      alignItems="center"
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
    >
      {icon && <Box as={icon} mr={2} />}
      {imageUrl && <Image src={imageUrl} alt={text} width="20px" height="20px" mr={2} />}
      <Text color={textColor}>{text}</Text>
    </Box>
  );
}

export default MenuComboBoxItem;

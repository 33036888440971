import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../../state/user-state";
import { get, getDatabase, ref } from "@firebase/database";
import { Box, Button, Center, Icon, Image, Menu, MenuButton, MenuItem, MenuList, Text, useToast, VStack } from "@chakra-ui/react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getLanguageWithCode, LANGUAGES } from "../../utils/languages";
import { updateLanguages } from "../../api/user-language.service";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { isDefined } from "../../utils/utils";

function ChooseLanguageContent({
    onUpdate,
    yourLanguageTitle,
    yourLanguageSubtitle,
    learningLanguageTitle,
    learningLanguageSubtitle,
    userLanguagePlaceholder,
    learningLanguagePlaceholder,
    doneButtonText,
    isLoading,
    buttonColorScheme = "green",
    menuHoverBg = "blue.800",
    menuActiveBg = "transparent",
    textColor = "white",
    descriptionTextColor = "lightGrey",
}) {
    const { t, i18n } = useTranslation();

    // Fallback to localized defaults
    function or(value, fallback) {
        return isDefined(value) ? value : fallback;
    }

    const localizedYourLanguageTitle = or(yourLanguageTitle, t("choose_language.user_language"));
    const localizedYourLanguageSubtitle = or(yourLanguageSubtitle, t("choose_language.user_language_description"))
    const localizedLearningLanguageTitle = or(learningLanguageTitle, t("choose_language.learning_language"))
    const localizedLearningLanguageSubtitle = or(learningLanguageSubtitle, t("choose_language.learning_language_description"))
    const localizedUserLanguagePlaceholder = or(userLanguagePlaceholder, '-')
    const localizedLearningLanguagePlaceholder = or(learningLanguagePlaceholder, '-')
    const localizedDoneButtonText = or(doneButtonText, t("modal.done"))

    const [userLanguage, setUserLanguage] = useState(null);
    const [learningLanguage, setLearningLanguage] = useState(null);
    const [userID, setUserID] = useState(null);
    const [finishedAuthenticatingUser, setFinishedAuthenticatingUser] = useState(false);
    const [isSubmitting, setSubmitting] = useState(isLoading);
    const [hasLanguages, setHasLanguages] = useState(true);
    const [isUserLanguageMenuOpen, setIsUserLanguageMenuOpen] = useState(false);
    const [isLearningLanguageMenuOpen, setIsLearningLanguageMenuOpen] = useState(false);

    const [localStorageLearningLanguage, setLocalStorageLearningLanguage] = useRecoilState(learningLanguageState);
    const [localStorageUserLanguage, setLocalStorageUserLanguage] = useRecoilState(userLanguageState);

    const db = getDatabase();
    const toast = useToast();

    useState(() => {
        console.log("ChooseLanguageContent: user language " + localStorageUserLanguage);
        console.log("ChooseLanguageContent: learning language " + localStorageLearningLanguage);
        
        if (localStorageLearningLanguage && !learningLanguage) {
            setLearningLanguage(localStorageLearningLanguage);
        }
        
        if (localStorageUserLanguage && !userLanguage) {
            setUserLanguage(localStorageUserLanguage);
        }
    }, [localStorageLearningLanguage, localStorageUserLanguage]);

    useState(() => {
        setSubmitting(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setFinishedAuthenticatingUser(true);
            if (user) {
                setUserID(user.uid);
                const userRef = ref(db, "users/" + user.uid);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    const { userLanguage, learningLanguage } = snapshot.val();
                    if (userLanguage && learningLanguage) {
                        window.location.href = "/documents";
                        return;
                    }
                }

                const storedUserLanguage = localStorage.getItem("userLanguage");
                if (storedUserLanguage) {
                    setUserLanguage(getLanguageWithCode(storedUserLanguage));
                }

                setHasLanguages(false);
            } else {
                setHasLanguages(false);
            }
        });

        return () => unsubscribe();
    }, [db]);

    useEffect(() => {
        if (userLanguage) {
            i18n.changeLanguage(userLanguage.code);
        }
    }, [userLanguage, i18n]);

    const handleDone = async () => {
        onUpdate?.(userLanguage, learningLanguage);
    };

    const renderLanguageMenu = (
        selectedLanguage,
        setSelectedLanguage,
        isOpen,
        setIsOpen,
        label,
        description,
        placeholder
    ) => (
        <>
            <Text color={textColor}>{label}</Text>
            <Menu
                isOpen={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            >
                <MenuButton
                    as={Button}
                    variant="outline"
                    _hover={{ bg: menuHoverBg }}
                    _active={{ bg: menuActiveBg }}
                    color={textColor}
                    width="100%"
                >
                    <Box
                        as="span"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box as="span" display="flex" alignItems="center">
                            {selectedLanguage ? (
                                <>
                                    <Image
                                        src={`/icons/flag-${selectedLanguage.code}.png`}
                                        alt={selectedLanguage.code}
                                        width="20px"
                                        height="20px"
                                    />
                                    <Text ml={2}>{selectedLanguage.name}</Text>
                                </>
                            ) : (
                                <Text ml={2}>{placeholder}</Text>
                            )}
                        </Box>
                        <Icon as={isOpen ? FaAngleUp : FaAngleDown} />
                    </Box>
                </MenuButton>
                <MenuList>
                    <MenuItem
                        onClick={() => setSelectedLanguage(null)}
                        disabled={!selectedLanguage}
                    >
                        <Text>{placeholder}</Text>
                    </MenuItem>
                    {LANGUAGES.map((lang) => (
                        <MenuItem key={lang.code} onClick={() => setSelectedLanguage(lang)}>
                            <Box as="span" display="flex" alignItems="center">
                                <Image
                                    src={`/icons/flag-${lang.code}.png`}
                                    alt={lang.code}
                                    width="20px"
                                    height="20px"
                                />
                                <Text ml={2}>{lang.name}</Text>
                            </Box>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <Text color={descriptionTextColor}>{description}</Text>
        </>
    );

    return (
        <VStack spacing={2} alignItems="start">
            {renderLanguageMenu(
                userLanguage,
                setUserLanguage,
                isUserLanguageMenuOpen,
                setIsUserLanguageMenuOpen,
                localizedYourLanguageTitle,
                localizedYourLanguageSubtitle,
                localizedUserLanguagePlaceholder
            )}
            {renderLanguageMenu(
                learningLanguage,
                setLearningLanguage,
                isLearningLanguageMenuOpen,
                setIsLearningLanguageMenuOpen,
                localizedLearningLanguageTitle,
                localizedLearningLanguageSubtitle,
                localizedLearningLanguagePlaceholder
            )}
            <Center width="100%">
                <Button
                    mt={4}
                    isLoading={isSubmitting}
                    colorScheme={buttonColorScheme}
                    isDisabled={!userLanguage || !learningLanguage}
                    onClick={handleDone}
                >
                    {localizedDoneButtonText}
                </Button>
            </Center>
        </VStack>
    );
}

export default ChooseLanguageContent;

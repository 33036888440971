import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
  
      "@font-face": {
        fontFamily: "Quicksand",
        src: `
          url('/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
        `,
        // src: `
        //   url('/fonts/Quicksand-VariableFont_wght.woff2') format('woff2'),
        //   url('/fonts/Quicksand-VariableFont_wght.woff') format('woff'),
        //   url('/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
        // `,
        fontWeight: "normal",
        fontStyle: "normal",
      },

      body: {
        backgroundColor: "#2B1757", // Set your desired background color here
      },
    },
  },
  initialColorMode: "light",
  useSystemColorMode: false,
  components: {
    Button: {
      baseStyle: {
        borderRadius: "md",
        shadow: "md",
        whiteSpace: "normal",
        textAlign: "center",
        fontSize: ["2xl", null, "xl", "md"],
        height: "unset",
      },
    },
  },
  colors: {
    lightGrey: "#C0C0C0",
    closeGrey: "#A9A9A9",
    customRed: "#FF5A54",
    purple: "#3B216A",
    dialogBorder: "#2B1A5E",
    lightPurple: "#2B1A5EA0",
    midPurple: "#2B1A5E",
    darkPurple: "#1F164E",
    filterPurple: "#6441F1",
  },
  fonts: {
    body: "'Quicksand', sans-serif",
    heading: "'Quicksand', sans-serif",
  },
});

export default theme;

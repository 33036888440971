import { useRecoilState } from "recoil"
import { isAuthenticationFinishedState, userState } from "../../state/user-state"
import { classroomIDState, classroomNotesState, classroomSentencesState } from "../../state/classroom-state"
import { useTranslation } from "react-i18next"
import { updateNotes } from "../../api/classroom.service"
import { Box, VStack, useMediaQuery } from "@chakra-ui/react"
import ChooseYourLanguage from "../choose-your-language"
import ClassNotes from "../class-notes"
import { Constants } from "../../utils/constants"
import { useEffect, useState } from "react"
import { exampleClassroomState } from "./example-classroom-state"
import { copyJSONObject } from "../../utils/utils"

function ClassNotesWrapper({ error, exampleUserRole }) {
    const [isAuthenticationFinished, setIsAuthenticationFinished] = useRecoilState(isAuthenticationFinishedState)
    const [user, setUser] = useRecoilState(userState)
    const [classroomNotes, setClassroomNotes] = useRecoilState(classroomNotesState)
    const [classroomSentences, setClassroomSentences] = useRecoilState(classroomSentencesState)
    const [classroomID, setClassroomID] = useRecoilState(classroomIDState)
    const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_MOBILE);
    const [exampleClassroomData, setExampleClassroomData] = useRecoilState(exampleClassroomState);
    const [id, setId] = useState(null)
    const [titleID, setTitleID] = useState(null)
    const { t } = useTranslation()

    let lastUpdateTime = Date.now();
    let timeoutId = null;

    useEffect(() => {
        if(exampleUserRole) {
            setId(`classNotes_${exampleUserRole}`)
            setTitleID(`classNotesTitle_${exampleUserRole}`)
        } else {
            setId('classNotes')
            setTitleID('classNotesTitle')
        }
    }, [exampleUserRole])
    

    function updateNoteText(text) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastUpdateTime;

        if (elapsedTime >= Constants.NOTES_TYPING_DELAY_MS) {
            updateNotesText(text)
            lastUpdateTime = currentTime;
        } else {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                updateNotesText(text)
                lastUpdateTime = Date.now();
            }, Constants.NOTES_TYPING_DELAY_MS - elapsedTime + 1);
        }
    }

    function updateNotesText(text) {
        if(classroomID) {
            updateNotes(classroomID, text)
        } else if (exampleClassroomData) {
            let data = copyJSONObject(exampleClassroomData)
            data.notes = text
            console.log('data after setting notes', JSON.stringify(data, null, 2))
            setExampleClassroomData(data)
        }
    }

    useEffect(() => {
        if (exampleClassroomData) {
            setClassroomNotes(exampleClassroomData.notes ?? '')
        }
    }, [exampleClassroomData])

    return (<Box w={'100%'} h={'100%'}>
        {!error && (
            <VStack as="section" spacing={4} pl={4} pr={4} pb={4} h="100%" alignItems="start">
                {/* Align items to the start of the VStack */}
                <ClassNotes id={id} titleID={titleID} showTitle={!isMobile} flex="1" notes={classroomNotes} onEditNotes={updateNoteText} />
                {/* {isAuthenticationFinished && !user && <ChooseYourLanguage />} */}
            </VStack>
        )}
    </Box>
    )
}

export default ClassNotesWrapper;
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import CustomButton from "./common/LandingButton";
import RetinaImage from "../retina-image";
import { Constants } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { userLanguageState } from "../../state/user-state";
import { useState } from "react";
import { imageSuffixState } from "./landing-state";

const LandingClassMaterials = ({ imageSuffix }) => {
    const { t } = useTranslation();
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [suffix, setSuffix] = useState(imageSuffix);

    useState(() => {
        setSuffix(imageSuffix);
    }, [imageSuffix]);

    return (
        <Box textAlign="center" color="white" pb={32} pt={16}>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                gap={{ base: 4, md: "5%" }}
                mt="8"
                px={{ base: 4, md: 8 }}
                position="relative"
            >
                {/* Text box */}
                <Box flex={{ base: "1", md: "40%" }} textAlign="left">
                    <Text
                        fontSize={Constants.Landing.SECTION_TITLE_HEADER_SIZE}
                        color="#7F5BD4"
                        mb={Constants.Landing.SECTION_TITLE_TOP_MARGIN}
                    >
                        {t("landing.classrooms_header")}
                    </Text>
                    <Heading 
                        fontSize={Constants.Landing.SECTION_TITLE_SIZE} 
                        mb={Constants.Landing.SECTION_TITLE_BOTTOM_MARGIN}
                    >
                        {t("landing.classrooms_title")}
                    </Heading>
                    <Text fontSize="lg" maxW="600px" mb="4">
                        {t("landing.classrooms_description")}
                    </Text>
                    <CustomButton
                        mainText={t("landing.classrooms_cta")}
                        bgColor="#7F5BD4"
                        iconSize="40px"
                    />
                </Box>

                {/* Image box with glow overlay */}
                <Box flex={{ base: "1", md: "45%" }} position="relative" pointerEvents="none">
                    {/* Main classroom image */}
                    <RetinaImage
                        src={`/icons/landing/classroom_no_glow${imageSuffix}.png`}
                        alt="Classroom Image"
                        width="100%"
                        height="auto"
                        zIndex="10"
                        position="relative"
                    />

                    {/* Glow image overlay */}
                    <Box
                        position="absolute"
                        top="30%" // Adjusts vertical position based on Figma
                        left="15%" // Centers horizontally over main image
                        transform="translate(-50%, -50%)"
                        width="110%" // Scales to match Figma proportions
                        height="auto"
                        zIndex="5"
                        overflow="visible"
                    >
                        <RetinaImage src="/icons/landing/classroom_glow.png" alt="Glow effect" />
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default LandingClassMaterials;

import { atom } from "recoil";

export const documentIDState = atom({
    key: 'documentIDState',
    default: { loaded: false, id: null },
});

export const documentDataState = atom({
    key: 'documentDataState',
    default: null,
});
import {
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Text,
    Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ChooseYourLanguage from "./choose-your-language";
import ChooseLearningLanguage from "./choose-learning-language";
import { useRecoilState } from "recoil";
import { learningLanguageState, userLanguageState } from "../state/user-state";
import RetinaImage from "./retina-image";
import { useTranslation } from "react-i18next";
import { useUser } from "../auth/user-context";

function ChooseLanguageHeaderWidget({ ...props }) {
    const [userLanguage, setUserLanguage] = useRecoilState(userLanguageState);
    const [learningLanguage, setLearningLanguage] = useRecoilState(learningLanguageState);
    const [userLanguageImage, setUserLanguageImage] = useState("/icons/m-flag-empty.png");
    const [learningLanguageImage, setLearningLanguageImage] = useState("/icons/m-flag-empty.png");
    const { t } = useTranslation();

    const { user } = useUser();

    useEffect(() => {
        let learningCode = learningLanguage?.code ?? "empty";
        let learningLanguageFlag = `/icons/m-flag-${learningCode}.png`;

        let userCode = userLanguage?.code ?? "empty";
        let userLanguageFlag = `/icons/m-flag-${userCode}.png`;

        console.log(
            "rendering ChooseLanguageHeaderWidget with learningCode:",
            learningCode,
            "userCode:",
            userCode
        );

        setUserLanguageImage(userLanguageFlag);
        setLearningLanguageImage(learningLanguageFlag);
    }, [learningLanguage, userLanguage]);

    return (
        <Box height="30px" {...props}>
            {/* Circle Icons */}

            {/* Popover */}
            <Popover placement="bottom">
                <PopoverTrigger>
                    <Box>
                        <Box position="relative" width="48px" height="30px">
                            <Box
                                as="button"
                                position="absolute"
                                left={0}
                                top={0}
                                width="30px"
                                height="30px"
                                boxShadow="md"
                                cursor="pointer"
                                zIndex={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <RetinaImage src={learningLanguageImage} alt="User Language" boxSize="full" borderRadius="full" />
                            </Box>
                            <Box
                                as="button"
                                position="absolute"
                                right={0}
                                top={0}
                                width="30px"
                                height="30px"
                                boxShadow="md"
                                cursor="pointer"
                                zIndex={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <RetinaImage src={userLanguageImage} alt="Learning Language" boxSize="full" borderRadius="full" />
                            </Box>
                        </Box>

                        {/* Trigger must wrap an interactive element */}
                        <Box w="100%" h="100%" display="inline-block" cursor="pointer" />
                    </Box>
                </PopoverTrigger>
                <PopoverContent width="auto" borderColor={"gray.500"} borderRadius={3}>
                    <PopoverArrow />
                    <PopoverBody
                        borderColor={"red"}
                        style={{
                            backgroundImage: "url('/icons/bg.png')",
                            backgroundSize: "cover",
                            backgroundRepeat: "repeat-y",
                            backgroundPosition: "center center",
                        }}
                    >
                        {/* Your components here  */}
                        {user && <Box>
                            <ChooseYourLanguage isVertical={true} />
                        </Box>}

                        {!user && <Box>
                            <Center><Text fontWeight='bold' mb={2} color='white'>{t('header.languages_title')}</Text></Center>
                            <Box mb={2} display="flex" justifyContent="flex-end">
                                <ChooseYourLanguage title={t("header.your_language_short")} />
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <ChooseLearningLanguage title={t("header.learning_language_short")} />
                            </Box>
                        </Box>}

                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

export default ChooseLanguageHeaderWidget;

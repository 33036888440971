import { atom } from "recoil";

export const selectedStoryMappingState = atom({
    key: 'selectedStoryMappingState',
    default: { mapping: null, story: null },
  });

export const selectedStoryState = atom({
    key: 'selectedStoryState',
    default: null,
  });